.content table{
	width: auto;
	margin: 10px 0;

	.etable__mobile-responsive-table-only {
		display: none;
	}

	th{
		font-weight: bold;
		padding: 4px 5px;
	}

	td,
	th{
		&:first-child{
			padding-left: 0;
		}

		&:last-child{
			padding-right: 0;
		}
	}
	
	td{
		padding: 2px 5px;
		
		.js-responsible-table__headline {
			margin: 0;
			padding: 0 0 5px 0;
			display: none;
			font-weight: bold;
		}
	}

	th:last-child{
		text-align: left;
	}
	
	.js-responsible-table__icon-accordion {
		display: none;
		cursor: pointer;
	}
}

@media screen and (max-width: 680px) {
	.content{
		table.js-responsive-table{
			margin-bottom: 0.5em;
			color: #666;
			width: 100%;
		
			&,
			tr,
			td,
			th,
			thead,
			tbody,
			tfooter{
				display: block;
				width: 100%;
			}
	
			th{
				background: @GRAY-LIGHTER;
			}
			
			th,
			td{
				&,
				&:first-child,
				&:last-child{
					padding: 10px 5px;
				}
			}
			
			th,
			td:first-child {
				font-weight: bold;
			}
			
			td:first-child{
				cursor: pointer;
			}
	
			tr {
				max-height: 40px;
				overflow: hidden;
				position: relative;
				
				border-bottom: 1px solid @GRAY-LIGHTER;
			}
	
			.js-responsible-table__icon-accordion {
				display: block;
				text-align: center;
				width: 16px;
				height: 16px;
				position: absolute;
				right: 10px;
				top: 1px;
				font-style: normal;
				font-size: 1.6em;
			}
	
			td .js-responsible-table__headline {
				display: block;
			}

			.etable__mobile-responsive-table-only {
				display: block;
			}

			.etable__mobile-responsive-table-only--type-inline {
				display: inline;
			}
		}
	}
}

@media(min-width: 680px) {

	.content table {
		th {
			&.etable__th--right-aligned {
				text-align: right;
			}
		}

		td {
			&.etable__td--right-aligned {
				text-align: right;
			}
		}
	}
}