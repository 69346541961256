.overlay__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;

  .overlay__slider-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-top: 1px solid #e3e3e3;
    border-top: 0;
    border-right: 1px solid #e3e3e3;
    width: 150px;
    padding: 0 15px 10px 5px;

    .overlay__slider {
      position: relative;
      padding: 35px 0;
      height: 100%;
      overflow: hidden;

      .overlay__item {
        text-align: center;

        &.active {
          .overlay__slider-button {
            border: 1px solid @PRIMARY;
          }
        }

        .overlay__slider-button {
          border: 1px solid transparent;
          background-color: transparent;
          cursor: pointer;
          height: 127px;
          width: 127px;
          padding: 10px;

          .overlay__slider-image {
            margin: 0 auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }

  .overlay__content-column {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    vertical-align: middle;
    line-height: 0;
    padding: 0 25px;
    text-align: center;
    cursor: crosshair;
    width: 100%;
    height: 100%;

    &:before {
      content: " ";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    .overlay__image-content {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }
}

@media (max-width: 700px) {
  .overlay__content {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: space-between;



    .overlay__slider-column {
      flex: none;
      width: 100%;
      border-right: 0;
      border-left: 0;
      border-top: 1px solid @GRAY-LIGHT;
      padding: 0;

      .overlay__slider {
        padding: 15px 50px;
      }

      .slick-down,
      .slick-up {
        width:25px;
        height:50px;
        margin:0;
        transform:translateY(-50%);
        top:50%;

        &:before {
          content: "";
          width: 35px;
          height: 35px;
          position: absolute;
          transform: rotate(45deg);
          border: 1px solid @FONT-COLOR;
          left: 100%;
          top: 0;
          box-sizing: border-box;
          transform-origin: 0 0;
        }
      }
      .slick-up {
        left:0;
        right:unset;
        &:before {
          border-right: 0;
          border-top: 0;
        }
      }

      .slick-down {
        right:0;
        left:unset;
        &:before {
          border-left: 0;
          border-bottom: 0;
          left:0;

        }
      }
    }
  }
}