.tos__section {
	max-width: 580px;
	margin: 0 auto;
	padding-top: 60px;
	text-align: center;
	min-height: 300px;
	
	
	.tos__headline {
		font-weight: 700;
		padding-bottom: 15px;
		font-size: 16px;
	}
	
	.tos__content {
		
		font-size: 15px;
		
		p {
			padding-bottom: 10px;
		}
		
		.tos-form {
			.tos-form__button{
				margin-top: 25px;
			}
		}
	}
}
@media(min-width: @screen-sm) {
	.tos__section {
		.tos__headline {
			padding-bottom: 25px;
		}
		
		.tos__content {
			
			p {
				padding-bottom: 25px;
			}
		}
	}	
}
