.banner-component {
	position:relative;
	margin-bottom: 18px;

	.banner-component-video-container {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		display: none;
		
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&.has-video {
		&.api-ready {
			&:hover {
				.banner-image-wrapper {
					&:after {
						opacity: 1;
					}
				}
			}
		
			.banner-image {
				cursor: pointer;
			}
			
			.banner-image-wrapper {
				&:after {
					&:extend(.far);
					content: @fa-var-play-circle;
				}
			}
			
			.banner-component-video-wrapper {
				&:after {
					display: none;
				}
			}
		}
		
		&.video-loaded {
			.banner-component-video-container {
				display: block;
			}
				
			.banner-image-wrapper {
				display: none;
			}
			
			.banner-content{
				display: none;
			}
			
		}

		.banner-image-wrapper {
			position: relative;
			overflow: hidden;
			
			&:after {
				position: absolute;
				top: -99999px;
				bottom: -99999px;
				left: -99999px;
				right: -99999px;
				opacity: 0.8;
				content: '';
				background: rgba(0,0,0, 0.0) url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtcGxheS1jaXJjbGUgZmEtdy0xNiIgYXJpYS1oaWRkZW49InRydWUiIGRhdGEtaWNvbj0icGxheS1jaXJjbGUiIGRhdGEtcHJlZml4PSJmYXIiIGZvY3VzYWJsZT0iZmFsc2UiIHJvbGU9ImltZyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+CjxtZXRhZGF0YT4KPHJkZjpSREY+CjxjYzpXb3JrIHJkZjphYm91dD0iIj4KPGRjOmZvcm1hdD5pbWFnZS9zdmcreG1sPC9kYzpmb3JtYXQ+CjxkYzp0eXBlIHJkZjpyZXNvdXJjZT0iaHR0cDovL3B1cmwub3JnL2RjL2RjbWl0eXBlL1N0aWxsSW1hZ2UiLz4KPC9jYzpXb3JrPgo8L3JkZjpSREY+CjwvbWV0YWRhdGE+CjxwYXRoIGQ9Ik0zNzEuNyAyMzhsLTE3Ni0xMDdjLTE1LjgtOC44LTM1LjcgMi41LTM1LjcgMjF2MjA4YzAgMTguNCAxOS44IDI5LjggMzUuNyAyMWwxNzYtMTAxYzE2LjQtOS4xIDE2LjQtMzIuOCAwLTQyek01MDQgMjU2QzUwNCAxMTkgMzkzIDggMjU2IDhTOCAxMTkgOCAyNTZzMTExIDI0OCAyNDggMjQ4IDI0OC0xMTEgMjQ4LTI0OHptLTQ0OCAwYzAtMTEwLjUgODkuNS0yMDAgMjAwLTIwMHMyMDAgODkuNSAyMDAgMjAwLTg5LjUgMjAwLTIwMCAyMDBTNTYgMzY2LjUgNTYgMjU2eiIgZmlsbD0iI2UzZTNlZiIgZmlsbC1vcGFjaXR5PSIuOTQxMTgiLz4KPC9zdmc+Cg==") no-repeat center;
				background-size: 100px;
				animation-name: banner-component-loading;
				animation-duration: 2500ms;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
			}
		}
	}
}

.banner-link {
	display: block;
	line-height: 0;
	transform-style: preserve-3d;
	overflow: hidden;
	
	&:hover{
		.banner-image{
			transform: scale(1.05);
		}
	}
}
	
.banner-image {
	display: block;
	width: 100%;
	height: auto;
	transition: transform 150ms ease-out;
}

.banner-content.content {
	margin-top: 5px;
	font-size: 0.9em;

	>*{
		&:first-child {
			margin-top: 0;
		}

		&:last-child{
			margin-bottom: 0;
		}
	}
}

@media(min-width: 700px) {
	.banner-component{
		&.render-type-OVERLAY{
			.banner-content{
				position: absolute;
				bottom: 15px;
				left: 20px;
				right: 20px;
				background-color: rgba(255, 255, 255, 0.83);
				padding: 15px 30px;
			}
		}
	}
}