#registerForm .form_field_error {
  width:auto;
  float: none;
  padding-right:0;
  clear:both;
}

.js-recaptcha-captchaaddon {
  margin: 20px 0;
}

#g-recaptcha_incorrect {
  color: red;
  display: none;
}