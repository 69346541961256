.slider_component  {
  &.sticky {
    margin-top: 189px;
  }
}

.teaser__slider {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease;
  border-bottom: 1px solid @slider-border-color;
    .slick-list{
        height: auto;
    }
}

.slider-video-item {
    height: 100%;
    position: fixed;
	&.api-ready {
        .slider__image-wrapper {
            cursor: pointer;
            
            &:hover {
				&:after {
				opacity: 1;
				}
			}
            
            &:after {
				position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
				opacity: 0.8;
				content: '';
				background: rgba(0,0,0, 0.0) url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtcGxheS1jaXJjbGUgZmEtdy0xNiIgYXJpYS1oaWRkZW49InRydWUiIGRhdGEtaWNvbj0icGxheS1jaXJjbGUiIGRhdGEtcHJlZml4PSJmYXIiIGZvY3VzYWJsZT0iZmFsc2UiIHJvbGU9ImltZyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+CjxtZXRhZGF0YT4KPHJkZjpSREY+CjxjYzpXb3JrIHJkZjphYm91dD0iIj4KPGRjOmZvcm1hdD5pbWFnZS9zdmcreG1sPC9kYzpmb3JtYXQ+CjxkYzp0eXBlIHJkZjpyZXNvdXJjZT0iaHR0cDovL3B1cmwub3JnL2RjL2RjbWl0eXBlL1N0aWxsSW1hZ2UiLz4KPC9jYzpXb3JrPgo8L3JkZjpSREY+CjwvbWV0YWRhdGE+CjxwYXRoIGQ9Ik0zNzEuNyAyMzhsLTE3Ni0xMDdjLTE1LjgtOC44LTM1LjcgMi41LTM1LjcgMjF2MjA4YzAgMTguNCAxOS44IDI5LjggMzUuNyAyMWwxNzYtMTAxYzE2LjQtOS4xIDE2LjQtMzIuOCAwLTQyek01MDQgMjU2QzUwNCAxMTkgMzkzIDggMjU2IDhTOCAxMTkgOCAyNTZzMTExIDI0OCAyNDggMjQ4IDI0OC0xMTEgMjQ4LTI0OHptLTQ0OCAwYzAtMTEwLjUgODkuNS0yMDAgMjAwLTIwMHMyMDAgODkuNSAyMDAgMjAwLTg5LjUgMjAwLTIwMCAyMDBTNTYgMzY2LjUgNTYgMjU2eiIgZmlsbD0iI2UzZTNlZiIgZmlsbC1vcGFjaXR5PSIuOTQxMTgiLz4KPC9zdmc+Cg==") no-repeat center;
				background-size: 100px;
				animation-name: banner-component-loading;
				animation-duration: 2500ms;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
			}
        }
        .slider-video-wrapper {
            display: none;
        }
    }
    &.video-ready {
        right: 0;
        left: auto;
        opacity: 1;
        background-size: auto;
        background: #727476;
        background: linear-gradient(90deg, white 0%, #727476 4%, #727476 96%, white 100%);
        .slider-video-wrapper {
            display: block;
            width: 96%;
            max-width: 1180px;
            margin: 0 2% 0 2%;
        }
				
        .slider__image-wrapper {
            display: none;
        }
    }
}

.slider__link-wrapper {
	position: relative;
}

.slider__image-wrapper {

  img {
    height: auto;
    width: 100%;
  }

  &:before,
  &:after {
    display: block;
    content: ' ';
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    max-width: 210px;
    width: 280px;
  }

  &:before {
    left: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+1,0+100 */
    background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 1%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,1)), color-stop(1%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 1%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 1%,rgba(255,255,255,0) 100%); /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 1%,rgba(255,255,255,0) 100%); /* IE10 preview */
    background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 1%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
  }

  &:after {
    right: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* IE10 preview */
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  }
}

.teaser__slider-thumb-container {
  max-width: 1180px;
  margin: 15px auto 0 auto;

  .teaser__slider-thumb {
    visibility: hidden;
    opacity: 0;

    &.slick-initialized {
      .slick-next{
        right: -40px;
        position: absolute;
        top: 50%;
        margin: -25px 0 0 0;
        width: 25px;
        height: 50px;
        text-indent: -99999px;
        cursor: pointer;
        padding: 0;
        border: 0;
        background-color: transparent;
        outline: 0;
        z-index: 50;
          &:before {
            transform: rotate(-45deg);
            left: 12.5px;
            top: -5px;
            display: block;
            position: absolute;
            content: '';
            text-indent: 0;
            background-color: #717375;
            width: 1px;
            height: 35px;
            }
          &:after {
            transform: rotate(45deg);
            left: 12.5px;
            bottom: -5px;
            display: block;
            position: absolute;
            content: '';
            text-indent: 0;
            background-color: #717375;
            width: 1px;
            height: 35px;
          }
        }
      .slick-prev{
          left: -40px;
          position: absolute;
          top: 50%;
          margin: -25px 0 0 0;
          width: 25px;
          height: 50px;
          right: 0;
          text-indent: -99999px;
          cursor: pointer;
          padding: 0;
          border: 0;
          background-color: transparent;
          outline: 0;
          z-index: 50;
            &:before {
              transform: rotate(45deg);
              right: 12.5px;
              top: -5px;
              display: block;
              position: absolute;
              content: '';
              text-indent: 0;
              background-color: #717375;
              width: 1px;
              height: 35px;
            }
            &:after {
              transform: rotate(-45deg);
              right: 12.5px;
              bottom: -5px;
              display: block;
              position: absolute;
              content: '';
              text-indent: 0;
              background-color: #717375;
              width: 1px;
              height: 35px;
            }
      }
      visibility: visible;
      opacity: 1;
      
    }
  }

  img {
    width: 156px;
    height: 66px;
  }

  .thumb-slider__item {
    position: relative;
    display: block;
    overflow: hidden;
  }
}

.slick-initialized {
  .slick-current {
    .thumb__wrapper {
      border: 1px solid @image-slider-thumb-border;
    }
  }
}

.product__image {
  .slick-list {
    &.draggable {
      padding: 0 !important;
    }
  }

  .image__slide {

    .image__outer {
      width: 100%;
      display: inline-block;
      margin: 0 auto;
      
      .image__inner{
        .overlayed-text{
          position: relative;
          width: 100%;
          color: @missing-product-image-text;
          text-align: center;
         }
      }

      .image__extra {
        text-align: center;

        .image__extra-text {
          border: 1px solid @GRAY;
          background: @WHITE;
          padding: 5px;
        }
      }

    }
    &.is-loaded {
      img {
        width: 300px;
        height: 300px;
        margin: 0 auto;
        object-fit:scale-down;
      }
    }

    &.image__slide--portrait {
      margin-top: 27px;
    }
  }

  .image__slider {
    &.image__slider--single {
      margin-top: 50px;
    }
  }

  .thumb__slider {
    margin: 30px 50px 0 50px;

    .thumb__wrapper {
      height: 122px;
      width: 122px;
      max-width: 100%;
      background-color: transparent;
      margin: 0 auto;
      padding: 10px;
      cursor: pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      .thumb__zoom {
        position: relative;
        display: block;
        max-height: 100px;
        max-width: 100px;
        cursor: pointer;
        margin: 0 auto;

        .thumb__image {
          display: block;
          width: auto;
          max-height: 100px;
          max-width: 100%;
          margin: 0 auto;
          cursor:pointer;
        }
      }
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      margin: -25px 0 0;
      width: 25px;
      height: 50px;
      right: 0;
      text-indent: -99999px;
      cursor: pointer;
      padding: 0;
      border: 0;
      background-color: transparent;
      outline: 0;
      z-index: 50;

      &.slick-prev,
      &.slick-next {

        &:before {
          content: "";
          width: 35px;
          height: 35px;
          position: absolute;
          transform: rotate(-45deg);
          border: 1px solid @FONT-COLOR;
          left: 0;
          top: 50%;
          box-sizing: border-box;
          transform-origin: 0 0;

        }
      }

      &.slick-prev {
        left:-50px;

        &:before {
          left:0;
          border-right:0;
          border-bottom:0;
        }
      }

      &.slick-next {
        right: -50px;

        &:before {
          left:-100%;
          border-left:0;
          border-top:0;
        }
      }

      &.slick-disabled {
        display: none;
      }

    }
  }
}

@media (max-width: @screen-md) {
  .product__image {
    .image__slider {

      .image__outer {
        max-width: 100%;
        outline: 0;
        margin: 0 auto;
        display: block;
        padding: 10px 15px;
        border: 0;
        background: transparent;
        cursor: default;

        .image__inner {
          position: relative;
          display: block;
          cursor: pointer;
          max-width: 550px;
          width: 100%;
          margin: 0 auto;

          .image {
            display: block;
            width: auto;
            max-width: 100%;
            max-height: 350px;
          }
         .overlayed-text{
           position: relative;
           width: 100%;
           color: @missing-product-image-text;
           text-align: center;
          }


        }
      }


      .slick-arrow {
        position: absolute;
        top: 50%;
        margin: -25px 0 0;
        width: 25px;
        height: 50px;
        right: 0;
        text-indent: -99999px;
        cursor: pointer;
        padding: 0;
        border: 0;
        background-color: transparent;
        outline: 0;
        z-index: 50;


        &.slick-prev,
        &.slick-next {
          &::before {
            content:"";
            width: 35px;
            height: 35px;
            position: absolute;
            transform: rotate(-45deg);
            border: 1px solid @FONT-COLOR;
            left: 0;
            top: 50%;
            box-sizing: border-box;
            transform-origin: 0 0;
          }

        }

        &.slick-prev {
          left: -15px;
          &:before {
            left:0;
            border-right:0;
            border-bottom:0;
          }
        }
        &.slick-next {
          right: -15px;
          &:before {
            left:-100%;
            border-left:0;
            border-top:0;
          }
        }

      }
    }
  }

  .thumb__slider {
    display: none !important;
  }
}

@media (min-width: 1200px) {
    .slider-video-item {
        &.video-ready {
            .slider-video-wrapper {
                display: block;
                width: 100%;
                max-width: 1180px;
                margin: 0 auto;
            }
        }
    }
}
@media (min-width: 1260px) {
    .slider-video-item {
        &.video-ready {
            background: linear-gradient(90deg, white 0%, #727476 12%, #727476 88%, white 100%);
        }
    }
}

