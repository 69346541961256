.cookielayer-open{
	overflow-y: hidden;
}

.cookies-overlay-widget{
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9998;

	.cookies-overlay-underlay{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: rgba(0,0,0,.95);
		opacity: 0.9;
		cursor: not-allowed;
		z-index: 9998;
	}

	.cookies-overlay-dialog{
		display: flex;
		flex-direction: column;
		position: fixed;
		margin: 0 auto;
		top: 5%;
		left: 50%;
		background-color: #FFF;
		padding: 10px 15px;
		width: 90%;
		max-height: 90%;
		max-width: 700px;
		transform: translate(-50%, 0);
		z-index: 9998;

		@media(max-width: 959px){
			padding: 10px;
		}
	}

	.cookies-overlay-dialog__header {
		flex: 0 0 auto;
	}

	.cookies-overlay-dialog__header-title{
		line-height: 30px;
		color: #004c07;
		font-size: 17px;
	}

	.control-group{
		margin-top: 5px;
		font-size: 18px;
		line-height: 0;

		.input{
			display: block;
			height: 37px;
			line-height: 32px;
			padding: 0 10px;
			border: 1px solid #717375;
			font-weight: lighter;

			&.text,
			&.select,
			&.password{
				width: 100%;
			}

			&[type=file]{
				border: none;
				padding: 0;
			}

			&:disabled{
				background-color: #EEE;
				cursor: not-allowed;
			}
		}

		.control-label,
		.controls,
		.help-inline{
			line-height: 1.4em;
		}

		.help-inline{
			padding-top: 1px;
			font-size: 0.75em;
			color: #c90400;
		}

		select.input{
			padding: 0;
		}

		.mandatory{
			&:after{
				content: '*';
				display: inline-block;
				margin-left: 3px;
			}
		}

		.controls.checkbox{
			&.disabled{
				.control-label{
					opacity: 0.75;
				}
			}

			.control-label{
				display: block;
				padding-left: 40px;
				text-indent: -40px;

				.mandatory{
					text-indent: 0;
				}
			}

			input[type=checkbox]{
				display: none;

				& + input[type=hidden] + label.control-label{
					&:before{
						display: inline-block;
						font: normal normal normal @fa-font-size-base/@fa-line-height-base FontAwesome; // shortening font declaration
						font-size: inherit; // can't have font-size inherit on line above, so need to override
						text-rendering: auto; // optimizelegibility throws things off #1094
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
						content: "\f0c8";
						display: inline-block;
						text-indent: 0px;
						width: 40px;
						text-align: center;
						font-family: 'Font Awesome 5 Free';
					}
				}

				&:checked + input[type=hidden] + .control-label{
					&:before{
						content: "\f14a";
					}
				}
			}
		}

		textarea{
			max-width: 100%;
			min-width: 100%;
			min-height: 100px;
		}

		&:first-child{
			margin-top: 0;
		}
	}

	.cookies-overlay-dialog__body{
		position: relative;
		overflow: hidden;
		flex: 0 1 auto;
		padding-right: 15px;
	}

	.cookies-overlay-dialog__body-description.content{
		margin: 0;
		
		p{
			margin: 0;
			padding: 5px 0 10px 0;
		}
	}

	.cookies-overlay-dialog__checkbox-description{
		font-size: 12px;
		line-height: 1;
	}

	.cookies-overlay-dialog__section{
		margin-top: 5px;

		&:first-child{
			margin-top: 0;
		}

		&:last-child{
			.cookies-overlay-dialog__section-cookies{
				padding-bottom: 10px;
			}
		}
	}

	.cookies-overlay-dialog__section-cookies-link{
		font-size: 0.9em;
		margin-top: 3px;
		display: inline-block;
		color: #004c07;
		will-change: color;
		transition: color 0.05s;
		text-decoration: none;

		&:hover{
			text-decoration: underline;
			color: darken(#004c07, 5%);
		}

		&:before{
			display: inline-block;
			font: normal normal normal @fa-font-size-base/@fa-line-height-base FontAwesome; // shortening font declaration
			font-size: inherit; // can't have font-size inherit on line above, so need to override
			text-rendering: auto; // optimizelegibility throws things off #1094
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\f105";
			padding-right: 3px;
			font-size: 80%;
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
		}
	}

	.cookies-overlay-dialog__section-cookies-fewerdetails-link{
		display: none;
	}

	.cookies-overlay-dialog__section-cookies{
		padding: 0 15px 10px 40px;
		font-size: 0.9em;

		&.show-cookie-details{
			.cookies-overlay-dialog__section-cookies-moredetails-link{
				display: none;
			}

			.cookies-overlay-dialog__section-cookies-fewerdetails-link{
				display: inline-block;
			}

			.cookies-overlay-dialog__section-cookie{
				display: block;
			}
		}
	}

	.cookies-overlay-dialog__section-cookie{
		display: none;
		margin: 10px 0 0 0;
		padding-left: 10px;
		border-left: 1px solid #004c07;
	}

	.cookies-overlay-dialog__section-cookiedesc{
		font-size: 0.9em;
		margin-top: 2px;
	}

	.cookies-overlay-dialog__section-cookieurls{
		&:after{
			content: "";
			display: table;
			clear: both;
		}
	}

	.cookies-overlay-dialog__section-cookieurl{
		margin-top: 1px;
	}

	.cookies-overlay-dialog__section-cookieurl{
		display: block;
		font-size: 0.9em;
		color: #004c07;
		text-decoration: underline;
		will-change: color;
		transition: color 0.05s;

		&:hover{
			text-decoration: none;
			color: darken(#004c07, 5%);
		}
	}

	.cookies-overlay-dialog__footer {
		flex: 0 0 auto;
	}

	.cookies-overlay-dialog__footer-buttons{
		padding: 20px 0 10px;
	}

	.cookies-overlay-dialog__accept-all-btn{
		margin-bottom: 5px;
		background-color: #07962f;
		will-change: background-color;
		transition: background-color 0.3s;
		cursor: pointer;
		display: inline-block;
		border: 0;
		padding: 0 10px;
		color: #FFF;

		&:disabled{
			opacity: 0.6;

			&:hover,
			&:active{
				background-color: #07962f;
				cursor: default;
			}
		}
		
		&:hover,
		&:active,
		&:focus{
			background-color: lighten(#07962f, 5%);
		}

		&,
		&:hover,
		&:active,
		&:focus{
			color: #FFF;
			text-decoration: none;
		}
	}

	.cookies-overlay-dialog__save-btn{
		background-color: #717375;
		will-change: background-color;
		transition: background-color 0.3s;
		cursor: pointer;
		display: inline-block;
		border: 0;
		padding: 0 10px;
		color: #FFF;

		&:disabled{
			opacity: 0.6;

			&:hover,
			&:active{
				background-color: #717375;
				cursor: default;
			}
		}

		&:hover,
		&:active,
		&:focus{
			background-color: lighten(#717375, 5%);
		}

		&,
		&:hover,
		&:active,
		&:focus{
			color: #FFF;
			text-decoration: none;
		}
	}

	.cookies-overlay-dialog__accept-all-btn,
	.cookies-overlay-dialog__save-btn{
		display: block;
		width: 100%;
		padding: 10px;
	}

	.cookies-overlay-dialog__details-btn{
		margin-bottom: 5px;
		border: 0;
		padding: 0 10px;
		cursor: pointer;
		background-color: transparent;
		display: block;
		width: 100%;
		padding: 10px;
		color: #717375;
		text-decoration: none;
		will-change: color;
		transition: color 0.05s;

		&:hover{
			text-decoration: underline;
			color: darken(#717375, 5%);
		}
	}

	.cookies-overlay-dialog__footer-links{
		display: flex;
		justify-content: space-between;
	}

	.cookie-overlay-dialog__footer-link{
		margin-left: 25px;
		color: #004c07;
		text-decoration: none;
		will-change: color;
		transition: color 0.05s;

		&:hover{
			text-decoration: underline;
			color: darken(#004c07, 5%);
		}

		&:first-child{
			margin-left: 0;
		}
	}
}

@media(min-width: 450px){
	.cookies-overlay-widget{
		.cookies-overlay-dialog__section-cookieurl{
			float: left;
			margin-left: 5px;
			margin-top: 2px;

			&:first-child{
				margin-left: 0;
			}
		}
	}
}