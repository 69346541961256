.simpleimagecomponent{
	a{
		display: block;
		img{
			width: 100%;
			height: auto;
		}
	}
}
 

.banner-component{
	width: 100%;
	a{
		.clearfix;
		display: block;
		color: @text-color;
		text-decoration: none;
	}

	.title{
		font-weight: bold;
	}
	.thumb{
		padding: (@grid-gutter-width/2) 0;
		img{
			width: 100%;
			height: auto;
		}
	}

}


.simple-banner-component,
.banner__component {

	a{
		display: block;
	}

	img{
		width: 100%;
		height: auto;
		margin: 1px;
	}
}
 
.image-map-component{
	img{
		width: 100%;
		height: auto;
	}
}


.cms-image-component{
	img{
		width: 100%;
		height: auto;
	}
}

 
.tabs{
	.tabs-list {
		display: table;
		margin-bottom: 0;
		padding: 0;

		li{
			padding: 10px;
			border: 1px solid @border-color-3;
			background: @tab-bg;
			list-style: none;
			display: inline-block;

			a{
				color: @tab-color;
				padding: 0 15px;

				&:focus,
				&:hover {
					cursor: pointer;
					background-color: transparent;
					text-decoration: none;
				}

				.current-info {
					display: none;
				}
			}
		}

		li.active{
			background: @tab-active-bg;

			a {
				color: @tab-active-color;
			}
		}
	}
	.tabhead{
		.hidden-xs;
		.hidden-sm;
		height: 75px;
		display: table;
		
		& + .tabbody{
			display: none !important;
		}


		&.active{
			font-weight: bold;

			& + .tabbody{
				display: block !important;
				height: auto !important;
			}
		}

		a {
			padding: 0 (@grid-gutter-width/2);
			color: inherit;
			line-height: 1;
			text-decoration: none;
			display: table-cell;
			width: 100%;
			vertical-align: middle;
		}

	}
	.details__column {
		padding-top: 15px;
		-moz-column-count: 2;
		-webkit-column-count: 2;
		column-count: 2;

		.detail__block--wrapper {
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid-column;
		}

	}
	.tabbody{
		border:1px solid @border-color-3;
		padding: 20px 0;
	  	.full-width-padding();

	  @media (min-width: @screen-md-min) {
		  border-top: 1px solid @border-color-3;
	  }
	}


	&.tabs-responsive{
		.tabs-list{
			.hidden-xs;
			.hidden-sm;
		
		}

		.tabhead{
			text-transform: uppercase;
			display: none !important;
			border-bottom-color: @border-color-3;
			
			@media (max-width: @screen-sm-max) { 
				display: table !important;
			}
		}


	}


	&.tabs-accordion{
		.tabs-list{
			display: none !important;
		
		}

		.tabhead{
			display: table !important;
			margin-bottom: 20px;
		}


	}
}

@media (max-width: @screen-md) {
	.tabs {
		.details__column {
			display: grid;
		}
	}
}




.languagecurrencycomponent{
	.pull-right;

	form{
		display: inline-block;

		+ form{
			margin-left:(@grid-gutter-width/2) ;
		}
	}
}


.ui-helper-hidden-accessible{
	.sr-only;
}

















// badges

.thumb{
	position: relative;
	.badges{
		position: absolute;
		top: (@grid-gutter-width/2);
		left: (@grid-gutter-width/2);
		right: (@grid-gutter-width/2);
		bottom: (@grid-gutter-width/2);
		overflow: hidden;
		.badge{
			float: left;
   			clear: left;
   			margin-bottom: (@grid-gutter-width/2);
			
		}
	}
}










.com-space{
	& > *{
		padding: (@grid-gutter-width/2) 0;
	}
}








.flex-column{
	display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: start;
    -moz-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;

    & > *{
    	-webkit-box-flex: 1;
		-moz-box-flex: 1;
		-webkit-flex: 1 0 auto;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start;
    }
}









.product-classifications{

	.headline{
		font-weight: bold;
    padding: @grid-gutter-width/2;
	}
	
	table.table{
		td.attrib{
			width: 50%;
		}
	}
}


.no-space{

  	padding: 0 !important;

	.yCmsComponent{
		padding: 0 !important;
	}
}


.simple-responsive-banner-component,
.banner__component--responsive {

	a {
		display: block;
	}

	img {
		width: 100%;
		height: auto;
	}
}

.error-page{
	text-align: center;
}

// back link

.back-link {
  padding: @grid-gutter-width 0px;
  .full-width-padding();

  &.border {
    border-bottom: 1px solid @border-color-3;
  }

  button {
    padding: 0;
  }

  .glyphicon {
    border-right: 1px solid @border-color-3;
    border-radius: 0;
    padding-right: 10px;
    line-height: 16px;
    top: -2px;
  }

  .label {
    .page-headline();
    padding-left: 10px;
    white-space: normal;
  }

  &.product-details .label {
    font-weight: 600;
  }
}