footer {
  margin-top: 30px;
  background: @footer-background;
  color: @footer-text-color;
  font-weight: 300;
  position: relative;
  width: 100%;

  .footer__wrapper {

    display: -ms-grid;

    display: grid;
    -ms-grid-columns: 20% 70% 125px;
    grid-template-columns: 20% 70% 125px;
    grid-template-areas: "footer-left footer-middle footer-right";
    height: 70px;
    padding: 0 15px;

    .footer__left {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 1;
      grid-column-start: 1;
      grid-area: footer-left;
      display: flex;
      align-items: center;

      .footer__copyright {
        float: left;
        margin-right: 15px;
        margin-bottom: 5px;
        font-size: 16px;

        &:after {
          &:extend(.far);
          content: @fa-var-copyright;
        }
      }

      .footer__logo {
        float: left;
        width: 60%;
      }
    }

    .footer__middle {
                      -ms-grid-row: 1;
                          grid-row-start: 1;
                      -ms-grid-column: 2;
                          grid-column-start: 2;
      grid-area: footer-middle;
      display: flex;
      align-items: center;
      justify-content: center;

      .footer__list {
        text-align: center;
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          padding-right: 30px;

          &:before {
            &:extend(.fa);
            content: @fa-var-chevron-right;
            color: @footer-link-color;
          }
          a {
            text-decoration: underline;
            color: @LINK;
            &:hover {
              text-decoration: none;
              color: @LINK;
            }
          }
        }
      }
    }

    .footer__right {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 3;
      grid-column-start: 3;
      grid-area: footer-right;
      .footer__toTop {
        text-align: center;
        position: relative;
        color: @SECONDARY;

        &:hover {
          color: @HOVER;
        }

        &:before {
          &:extend(.fa);
          content: @fa-var-caret-up;
          font-size: 43px;
          display: block !important;
        }

        &:hover {
          cursor: pointer;
        }

        span {
          position: absolute;
          top: 35px;
          left: 26px;
        }

      }
    }

    @media (max-width: @screen-md) {
  .footer__left {
        -ms-grid-row: 2;
        grid-row-start: 2;
        -ms-grid-column: 1;
        grid-column-start: 1
    }
    .footer__middle {
        -ms-grid-row: 1;
        grid-row-start: 1;
        -ms-grid-column: 1;
        grid-column-start: 1
    }
    .footer__right {
        -ms-grid-row: 3;
        grid-row-start: 3;
        -ms-grid-column: 1;
        grid-column-start: 1
    }
    }
  }
}

@media (max-width: @screen-md) {
  footer {
    .footer__wrapper {
      grid-template-areas: "footer-middle" "footer-left"  "footer-right";
      -ms-grid-columns: 100%;
      grid-template-columns: 100%;
      height: auto;

      .footer__middle {
        .footer__list {
          margin: 15px 0;

          .footer__link {
            height: 30px;
          }
        }
      }

      .footer__left {
        margin: 0 auto;

        .footer__copyright {
          float: none;
          position: absolute;
          margin: 0;
        }

        .footer__logo {
          float: none;
          width: 87%;
          margin-left: 33px;
        }
      }

      .footer__right  {
        margin-bottom: 20px;
        .footer__toTop {
          &:before {
            margin-bottom: -15px;
          }
          span {
            position: inherit;
            top: 5px;
            left: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  footer {
    position: relative;
    display: flex;
    bottom: 0px;
  }

  .main-content {
    padding-bottom: 150px; //footer content height for mobile
  }
}

@media (min-width: 961px) {
  .main-content {
    padding-bottom: 100px; //footer height for desktop
  }
  footer {
    position: absolute;
    bottom: 0px;
  }
}