
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.@{noUi-css-prefix}-target,
.@{noUi-css-prefix}-target * {
-webkit-touch-callout: none;
-webkit-tap-highlight-color: rgba(0,0,0,0);
-webkit-user-select: none;
-ms-touch-action: none;
	touch-action: none;
-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
-moz-box-sizing: border-box;
  box-sizing: border-box;
}
.@{noUi-css-prefix}-target {
	position: relative;
	direction: ltr;
}
.@{noUi-css-prefix}-base,
.@{noUi-css-prefix}-connects {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}
/* Wrapper for all connect elements.
 */
.@{noUi-css-prefix}-connects {
	overflow: hidden;
	z-index: 0;
}
.@{noUi-css-prefix}-connect,
.@{noUi-css-prefix}-origin {
	will-change: transform;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	-ms-transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
}
/* Offset direction
 */
html:not([dir="rtl"]) .@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-origin {
	left: auto;
	right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.@{noUi-css-prefix}-vertical .@{noUi-css-prefix}-origin {
	width: 0;
}
.@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-origin {
	height: 0;
}
.@{noUi-css-prefix}-handle {
	position: absolute;
}
.@{noUi-css-prefix}-state-tap .@{noUi-css-prefix}-connect,
.@{noUi-css-prefix}-state-tap .@{noUi-css-prefix}-origin {
-webkit-transition: transform 0.3s;
	transition: transform 0.3s;
}
.@{noUi-css-prefix}-state-drag * {
	cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.@{noUi-css-prefix}-horizontal {
	height: 9px;
}
.@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-handle {
	width: 15px;
	height: 15px;
	left: -17px;
	top: -4px;
	background: @GRAY-LIGHTER;
	border: 1px solid @GRAY;
}
.@{noUi-css-prefix}-vertical {
	width: 18px;
}
.@{noUi-css-prefix}-vertical .@{noUi-css-prefix}-handle {
	width: 28px;
	height: 34px;
	left: -4px;
	top: -17px;
}
html:not([dir="rtl"]) .@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-handle {
	right: -9px;
	left: auto;
}

@media (max-width: @screen-sm-max) {
	.@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-handle {
		width: 25px;
		height: 25px;
		top: -9px;
		border-radius: 50%;
	}

	html:not([dir="rtl"]) .@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-handle {
		right: -16px;
		left: auto;
	}
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.@{noUi-css-prefix}-target {
	background: @GRAY-LIGHTER;
	border: 1px solid @GRAY;
}

.@{noUi-css-prefix}-connect {
	background: @WHITE;
}

/* Handles and cursors;
 */
.@{noUi-css-prefix}-draggable {
	cursor: ew-resize;
}
.@{noUi-css-prefix}-vertical .@{noUi-css-prefix}-draggable {
	cursor: ns-resize;
}
.@{noUi-css-prefix}-handle {
	border: 1px solid #D9D9D9;
	border-radius: 3px;
	background: #FFF;
	cursor: default;
	box-shadow: inset 0 0 1px #FFF,
				inset 0 1px 7px #EBEBEB,
				0 3px 6px -3px #BBB;
}
.@{noUi-css-prefix}-active {
	box-shadow: inset 0 0 1px #FFF,
				inset 0 1px 7px #DDD,
				0 3px 6px -3px #BBB;
}

/* Disabled state;
 */

[disabled] .@{noUi-css-prefix}-connect {
	background: #B8B8B8;
}
[disabled].@{noUi-css-prefix}-target,
[disabled].@{noUi-css-prefix}-handle,
[disabled] .@{noUi-css-prefix}-handle {
	cursor: not-allowed;
}
