.account-section {
  .account__headline {
    .page-headline();
    text-align: center;
    padding: 0;
    margin-bottom: 50px;
    margin-top: 20px;
    width: 70%;
    display: inline-block;

    &.headline--centered {
      text-align: center;
      width: 100%;
    }
  }

  .account__actions {
    .actions__btn {
      max-width: 300px;

      &.actions__btn--left {
        float: left;
      }

      &.actions__btn--right {
        float: right;
      }
    }
  }

  .accordion__content-title {
    color: @login-label !important;
    text-transform: uppercase !important;
  }

  .accordion__link {
    display: block;
    cursor: pointer;
    position: relative;
    margin: 2px 0 10px 0;
    padding: .5em .5em .5em 25px;
    background: #f2f2f2;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    border: none;
    color: #717375;

    &:after {
      &:extend(.fa);
      content: @fa-var-angle-down;
      font-size: 25px;
      position: absolute;
      right: 25px;
      top: 5px;

      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }

  .account__profile {
    position: relative;
    white-space: nowrap;
    .profile__edit {
      height: 0;

      &:hover {
        cursor: pointer;
        &:after {
          color: @HOVER;
        }
      }

      &:after {
        &:extend(.fas);
        content: @fa-var-pencil-alt;
        color: @SECONDARY;
        font-size: 14px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .profile__data {
      width: 70%;
      display: inline-block;

      .profile__element {
        margin-bottom: 15px;

        .profile__data-label {
          width: 200px;
          display: inline-block;
          font-weight: 700;
          vertical-align: top;
        }

        .profile__data-text {
          display: inline-block;
          font-weight: 400;
        }
      }
    }
  }

  .account__password {
    width: 30%;
    display: inline-block;
    position: absolute;

    .password__headline {
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 10px;
    }

    .link {
      margin-bottom: 10px;
    }
  }

  .account__role {
    min-height: 220px;

    .role__wrapper {
      margin-bottom: 15px;
    }

    .role__title {
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 10px;
    }

    .role__header--wrapper {
      display: table;
      table-layout: fixed;
      width: 100%;

      .role__header {
        font-weight: 600;
        padding-bottom: 10px;
        display: table-cell;
      }
    }


    .role__text--wrapper {
      display: table;
      table-layout: fixed;
      width: 100%;

      .role__text {
        font-weight: 400;
        display: table-cell;
      }

      .link__eye {
        display: table-cell;
        text-align: right;

        &:after {
          &:extend(.far);
          content: @fa-var-eye;
        }

        &.is-active {
          &:after {
            &:extend(.far);
            content: @fa-var-eye-slash;
          }
        }
      }
    }


    .role__option {
      margin-bottom: 20px;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 20px;
      
      &.is-hidden {
        display: none;
      }

      .role__text {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }

    .option__checbox--wrapper {
      column-count: 3;
      font-weight: normal;
    }

    .feedback {
      margin-top: 50px;

      .feedback__headline {
        display: block;
        font-size: 16px;
        color: @feedback-headline-color;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .feedback__form {
        max-width: 500px;
        margin: 0 auto;

        .feedback__text {
          display: block;
          margin-bottom: 10px;
        }

        button {
          margin-bottom: 10px;
        }

        .write-feedback {
          .control-label {
            color: @FONT-COLOR;
          }

          .help-block {
            span {
              font-size: 14px;
            }
          }

          .btn-tetiary {
            width: 48%;
            float: left;
            margin-right: 2%;
          }

          .btn-default {
            width: 50%;
          }
        }
      }
    }
  }
  .item__list {
    .item__list--item {
      .product__checkbox {
        padding-bottom: 16px;
      }
      .item__quantity {
        padding-top: 18px;
      }
    }
  }

  .account__finances {
    .account__finances-link {
      display: block;
      margin-bottom: 15px;
      color: @account-link-color;
      text-decoration: underline;
      font-weight: 400;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .finances__listing {
    .finances__list {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
  }

  .account__customer_settings {
    margin-top: 15px;
  }

  .customer-settings__description {
    padding-left: 15px;
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 12px;
  }

  .customer-settings__description ul {
    padding-left: 30px;
  }

  .customer-settings__input-checkbox-form-entry {
    display: flex;
    align-items: flex-start;
    min-width: 50%;
  }

  .customer-settings__input-checkbox-form-entry label {
    margin-left: 2px;
  }

  .customer-settings__input-text-wrapper {
    display: inline-block;
    position: relative;
    width: 100%
  }

  .customer-settings__input-text-wrapper input {
    color: @GRAY;
    font-weight: 400;
    width: 100%;
    padding-right: 15px;

    &::-ms-clear {
      display: none;
    }
  }

  .customer-settings__input-text-wrapper span {
    position: absolute;
    top: 3px;
    right: 4px;

    &.is-hidden {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }

    &:after {
      &:extend(.fa);
      content: @fa-var-times;
    }
  }

  .customer-settings__button-group {
    margin-top: 10px;
  }

  .customer-settings__button-group--button {
    margin-top: 5px;
  }

  .has-thematic-break {
    border-bottom: 1px solid black;
}

  @media (min-width: 645px) {
    .customer-settings__input-text-wrapper {
      width: auto;
    }

    .customer-settings__input-text-wrapper input {
      width: 450px;
    }

    .customer-settings__form-group {
      display: flex;
      align-items: center;
    }

    .customer-settings__button-group--button {
      width: auto;
      margin-top: 0;
      margin-left: 5px;
      float: right;
    }
  }

  @media (max-width: @screen-xs) {
    .account__profile {
      .profile__edit {
        &:after {
          font-size: 20px;
          right: 0;
          top: 0;
        }
      }
    }

    .account__role {
      .role__text {
        font-weight: 400;
        &--change {
          &:after {
            font-size: 15px;
            right: 0;
          }
        }
      }
    }
  }
}

.account__password-forgotten {
  max-width: 650px;
  margin: 0 auto;
}

@media (max-width: @screen-md) {
  .account-section {
    .account__password {
      width: auto;
      position: relative;
      display: block;
      margin-top: 20px;
    }
  }
}
@media (max-width: 613px) {
  .account-section {
    .account__headline {
      width: 100%;
    }
  }
}

@media (max-width: 648px) {

  .account-section {
    .account__headline {
      width: 100%;
    }

    .account__actions {
      .actions__btn {
          max-width: 300px;
          margin: 0 auto;
          text-align: center;
          float: none !important;
          margin-bottom: 15px;
      }
    }
  }
}
