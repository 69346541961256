.delivery__addresses {

  .addresses__radio {
    .clearfix;

    background: @accordion-header-bg;
    padding: 10px 15px;
    margin-bottom: 10px;

    input[type="radio"] {
      height: 20px;
      width: 20px;
      margin: 0;
      float: left;
      vertical-align: top;
    }

    label {
      width: calc(100% - 20px); //20px is input[type="radio"] width
      display: block;
      margin: 0;
      padding-left: 5px;
      float: left;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .differentAddress {
    display: none;
    width: 650px;
    padding: 0 20px;
    font-weight: 700;

    .address__input {
      height: 42px;
      width: 100%;
      margin-bottom: 10px;
      padding-left: 10px;
    }

    .address__select {
      display: inline-block;
      width: 100%;
      padding-left: 8px;
      font-weight: normal;
      color: #717375;
      height: 42px;
      font-family: Arial,Helvetica,sans-serif;

    }
  }

  &.delivery__addresses--new {
    width: 70%;
    margin: 0 auto;

    .differentAddress {
      display: block;
      width: 100%;
    }

    .address__buttons {
      display: flex;
      margin: 20px 20px;

      .address__button {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}

.delivery-address-no-address-hint {
  display:none;

  &.is-active {
    display: block;
  }
}

.delivery-address-alert,
.differnt-address__warning {
  display: none;
}

.delivery-addresses {
  .address__card {
    position: relative;
    display: inline-block;
    width: calc(~"33% - 10px");
    border: 2px solid @GRAY;
    padding: 35px 15px 15px 15px;
    vertical-align: top;
    margin: 10px 5px;
    min-height: 230px;
    position: relative;

    &.is-active {
      .address__form {
        .address_container {
          .form-group {
            display: inline-block;
            width: 100%;
          }
        }
      }

      &.is-edit {
        .address_container {
          .address__value,
          .address__key {
            display: none;
          }

          input {
            display: block;
          }

          .address__value--country {
            display: none;
          }

          .address__select {
            display: block;
            height: 32px;
            width: 100%;
          }
        }
      }
    }

    .address__form {

      .address_container {
        input {
          display: none;
        }

        .address__key {
          display: inline-block;
          width: 100px;
          padding-bottom: 5px;

          &.address__key--empty {
            display: none;

            &.is-active {
              display: inline-block;
            }
          }
        }

        .address__select {
          display: none;
        }

        .form-group {
          display: none;
        }
      }
    }

    &.address__card--new-button {
      display: none;

      &.is-active {
        display: inline-block;
      }
      &:hover {
        cursor: pointer;
      }
    }

    &.address__card--new {
      display: none;

      &.is-active {
        display: inline-block;
        vertical-align: top;


        .link__close,
        .link__submit {
          display: inline;
        }

        .address_container {
          input {
            display: inline-block;
          }

          .address__select {
            display: inline-block;
            width: 100%;
            height: 32px;
            padding-left: 8px;
            font-weight: normal;
            color: #717375;
          }
        }
      }
    }

    .address__new--button {

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &:hover {
        cursor: pointer;
      }

      &:after{
        &:extend(.fa);
        content: @fa-var-plus;
        font-size: 45px;
      }
    }

    .address__action {
      position: absolute;
      top: 10px;
      right: 10px;

      .link__close,
      .link__submit {
        display:none;
      }
    }
  }
}

@media (max-width: @screen-md) {
  .delivery__addresses {
    .differentAddress {
      width: 100%;
          }
        }
}

@media (max-width: 1180px) {
  .delivery-addresses {
    .address__card {
      width: calc(50% - 15px)
    }
  }
}

@media (max-width: 851px) {
  .delivery-addresses {
    .address__card {
      width: 100%;
    }
  }
}