.productcarousel__wrapper{
	.productcarousel__component:not(:first-child){
		border-top: 1px solid @product-carousel-border-color
	}
}

.teaser__slider-arrow {
	display: none !important;
}

.productcarousel__component{
	&.layout-Extended{
		.productcarousel__item--body{
			.product__info.partial{
				height: 120px;
			}
		}
	
		.product__delivery.partial{
			.clearfix;
			padding: 5px 0;
			
			.delivery__container{
				.clearfix;
				float: right;
				margin-right: 10px;
				width: auto;
				
				&:last-child{
					margin-right: 0;
				}
				
				.delivery__info{
					position: static;
				}
			}
		}
		
		.product__info-link{
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		.product__info-code {
			display: block;
			font-size: .93em;
			font-weight: 200;
		}
		
		.product__info-brand,
		.product__info-attribute,
		.product__info-name{
			flex: 0 1 auto;
		}
		
		.product__info-description-wrapper{
			position: relative;
			flex: 1 1 auto;
			overflow: hidden;
		}
		
		.product__info-description{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	
	.energy{
		right: 10px;
	}
}

.productcarousel__component--title{
	font-weight: bold;
	font-size: 18px;
	text-transform: uppercase;
	margin: 0;
	padding: 8px 0;
}

.productcarousel__component--carousel{
	.slick-track{
		margin-left: 0;
		margin-right: 0;
	}
}

.productcarousel__component--carousel__arrow{
	position: absolute;
	top: 50%;
	margin: -25px 0 0 0;
	width: 25px;
	height: 50px;
	right: 0;
	text-indent: -99999px;
	cursor: pointer;
	padding: 0;
	border: 0;
	background-color: transparent;
	outline: 0;
	z-index: 50;
	
	&.slick-disabled{
		display: none !important;
	}

	&:before,
	&:after{
		display: block;
		position: absolute;
		content: '';
		text-indent: 0;
		background-color: @product-carousel-arrows-color;
		width: 1px;
		height: 35px;
	}
		
	&:before{
		top: -5px;
	}	
	
	&:after{
		bottom: -5px;
	}
		
	&.productcarousel__component--carousel__prev{
		left: -40px;
		
		&:before,
		&:after{
			right: 12.5px;
		}
		
		&:before{
			transform: rotate(45deg);
		}
			
		&:after{
			transform: rotate(-45deg);
		}
	}
			
	&.productcarousel__component--carousel__next{
		right: -40px;
	
		&:before,
		&:after{
			left: 12.5px;
		}
			
		&:before{
			transform: rotate(-45deg);
		}
			
		&:after{
			transform: rotate(45deg);
		}
	}

	&:hover{
		&:before,
		&:after{
			color: darken(@product-carousel-arrows-color, 10%);
		}
	}
}

.productcarousel__item--wrapper {
	display: flex;
	flex-direction: column;
	padding: 10px;
	min-height: 360px;
	width: 100%;
}

.productcarousel__item--thumb{
	display: flex;
	align-items: center;
	height: 120px;
	text-align: center;
	flex-direction: column;
	justify-content: center;

	.image{
		max-width: 100%;
		display: inline-block;
		max-height: 120px;
		margin-left: auto;
		margin-right: auto;
		flex: 0 0 auto;
	}
}

.productcarousel__item--thumb-link{
	width: 100%;
}

.productcarousel__component--carousel-items{
	position: relative;
	opacity: 0;
	visibility: hidden;
	transition: opacity 1s ease;
	
	&:before,
	&:after{
		display: block;
		position: absolute;
		content: '';
		top: 0;
		bottom: 0;
		width: 1px;
		left: 0;
		background-color: #FFF;
		z-index: 2;
	}
	
	&:after{
		left: auto;
		right: 0;
	}
}

.productcarousel__item{
	position: relative;
	
	&:before,
	&:after{
		position: absolute;
		display: block;
		content: '';
		background-color: @product-carousel-border-color;
		width: 1px;
		top: 0;
		bottom: 50px;
	}
	
	&:before{
		left: 0;
	}
	
	&:after{
		right: 0;
	}
	
	&:last-child:after{
		display: none;
	}
}

.productcarousel__item--header{
	flex: 0 0 auto;
}

.productcarousel__item--header,
.productcarousel__item--body{
	display: block;
}

.productcarousel__item--body{
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	padding: 20px 0 10px 0;
	
	.product__info.partial{
		font-size: 11px;
		flex: 0 0 auto;
		height: 70px;
		
		.product__info-name{
			height: 20px;
			display: block;
		}

		.product__info-color {
			display: block;
		}
	}
	
	.product__prices.partial{
		margin: 14px 0 0 0;
	}
	
	.product__prices.partial{
		flex: 0 0 auto;
		min-height: 20px;

		.price__wrapper{
			display: flex;
		}
		
		.price__label,
		.price{
			display: block;
			flex: 1 0 auto;
		}

		.price{
			text-align: right;
		}
	}
	
	.product__delivery.partial{
		.delivery__container{
			.delivery__text{
				float: none;
				width: auto;
			}
			.delivery_info {
				right: 0;
			}
		}
		
		.delivery__price{
			line-height: 22px;
			
			.price.price--new{
				font-size: 22px;
			}
		}
	}

	.product__info-color {
		display: block;
	}
}

.productcarousel__item--footer{
	flex: 0 0 auto;
	display: flex;
	align-items: flex-end;
	&:extend(.clearfix all);
	
	.product__icons.partial{
		flex: 1 0 auto;
		margin-right: 15px;
		
	    .icon{
	    	line-height: 35px;
	    }
	}

	.addtocart__wrapper {
		&.is-disabled {
			.button__add-to-cart {
				cursor: @cursor-disabled;
				.opacity(.65);
				.box-shadow(none);
			}
		}

		.button__add-to-cart {
			float: right;
			display: inline-block;
			background: @product-add-to-cart-bg;
			color: @add2cart-color;
			padding: 5px 14px;

			&:hover {
				cursor: pointer;
				background: @product-add-to-cart-hover-bg;
			}

			.add2Cart__text {
				display: none;
			}
			&,
			.fa-shopping-cart,
			.spinner__wrapper{
				min-width: 30px;
				font-size: 23px;
				color: @product-add-to-cart-icon-color;
			}

			.fa-spinner {
				margin-left: 3px;
			}
		}
		.button__add-to-cart-asm {
			.button__add-to-cart;
			background: #8c8c8c;
			&:hover {
				background: #8c8c8c;
			}
		}
	}
}

.productcarousel__component--detail {
	scroll-margin-top: 140px;

	.productcarousel__component--carousel {

		.productcarousel__item {
			&:after,
			&:before {
				bottom: 0;
			}
		}

		.productcarousel__item--wrapper {
			height: 270px;
		}

		.productcarousel__item--body {
			flex: 0 1 auto;
		}

		.productcarousel__item--footer {
			position: relative;

			.price__wrapper {
				position: absolute;
				right: 0;
				bottom: -35px;
			}
		}
	}
}

@media (max-width: 1300px) {
	.productcarousel__component--carousel {
		padding: 0 50px;
	}
}

@media (min-width: 320px) and (max-width: 400px) {
	.productcarousel__component--carousel {

		.productcarousel__item--wrapper {
			padding: 0;

			.productcarousel__item--header {
				padding: 0 10px;
			}

			.productcarousel__item--body {
				.product__delivery,
				.product__prices,
				.product__info {
					padding: 0 5px;
				}
			}

			.productcarousel__item--footer {
				.addtocart__wrapper {
					padding-right: 5px;
				}
			}


		}
	}
}

@media (max-width: @screen-md) {
	.productcarousel__component--detail {
		scroll-margin-top: 70px;
	}

	.teaser__slider-thumb-container {
		display: none;
	}

	.teaser__slider-arrow {
		display: block !important;
		position: absolute;
		overflow: visible;
		height: 35px;
		width: 35px;
		top: 50%;
		z-index: 9996;
		border: 0;
		padding: 0;
		cursor: pointer;
		margin-top: -17px;
		background: @GRAY-LIGHT;

		i {
			font-size: 20px;
			color: white;
		}

		&:hover {
			cursor: pointer;
		}


		&.teaser__slider-arrow--prev {
			left: 0;
		}

		&.teaser__slider-arrow--next {
			right: 0;
		}
	}
}