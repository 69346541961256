.meta-nav-wrapper.sticky .meta-nav{
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
}

.meta-nav {
  background: @meta-nav-bg-color;
  height: 35px;

  .meta-nav__content {
    background: @meta-nav-bg-color;
    height: 35px;
    font-size: @FONT-SIZE;
    display: -ms-flex;
    display: flex;

    &__left {
      width: 40%;
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: start;
    }

    &__right {
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: end;
    }
  }

  .logged_in {
    display: flex;
    align-self: center;
  }

  .meta-nav__list {
    float: right;
    margin: 0;
    align-self: center;
  }

  .meta-nav__list-item {
    float: left;
    padding-right: 25px;
    list-style: none;
    font-weight: 500;
    align-self: center;

    .meta-nav__link {
      &:hover {
        text-decoration: none;
        color: @LINK;
      }
    }

    &:last-of-type {
      padding-right: 5px;
    }
  }
}

.mobile__nav {
  display: none;
}

.header__container {
  border-bottom: 1px solid @navigation-bar-border;

  .header__content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 60% 40%;
    grid-template-columns: 60% 40%;
    -ms-grid-rows: 145px;
    grid-template-rows: 145px;
    grid-template-areas: "headerLeft headerRight" "navigation navigation";
    width: 100%;
    position: relative;

    .header__navigation {
      display: none;
      -ms-grid-row: 2;
      grid-row-start: 2;
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      grid-area: navigation;
    }

    .header__left {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 1;
      grid-column-start: 1;
      grid-area: headerLeft;


      .banner__component {
        width: 330px;

        img {
          width: 330px;
        }
      }
    }

    .header__right > .header__left {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 1;
      grid-column-start: 1
    }

    .header__right {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 2;
      grid-column-start: 2;
      grid-area: headerRight;

      .header__list {
        display: flex;
        align-content: center;
        justify-content: end;
        gap: 24px;
        padding: 0;
        margin-bottom: 15px;

        &-item {
          .se-storefront-component {
            min-height: 0;
          }
        }
      }

      .header__search {
        position: relative;

        .search__input {
          height: 40px;
          line-height: 38px;
        }

        .search__button,
        .delete__button {
          position: absolute;
          cursor: pointer;
          color: @header-search-icon-color;
          outline: 0;
          padding: 0;
          margin: 0;
          border: 0;
          height: 27px;
          width: 30px;
          background-color: @header-search-bg-color;
        }

        .search__button {
          top: 5px;
          right: 5px;
        }

        .delete__button {
          top: 10px;
          right: 35px;

          &.is-hidden {
            display: none;
          }
        }
      }
    }

    .header__right > .header__right {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 2;
      grid-column-start: 2
  }
  }

  .header__mobile {
    display: none;
  }
}


.header__list-item {
  list-style: none;
  padding: 5px 8px;

  &:hover {
    text-decoration: none;
    background-color: @header-right-bg-color-hover;
  }

  .header__link {
    display: inherit;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: @header-right-link-color;
    text-transform: uppercase;
    position: relative;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:before {
      font-size: 28px;
      height: auto;
      margin-bottom: 3px;
      display: block !important;
    }

    .header__numberCarts {
      display: inline;
    }

    &.link__orders {
      &:before {
        &:extend(.fas);
        content: @fa-var-file-invoice-dollar;
      }
    }

    &.link__inbox {
      &:before {
        &:extend(.fas);
        content: @fa-var-inbox;
      }
    }

    &.link__account {
      &:before {
        &:extend(.fas);
        content: @fa-var-user;
      }
    }

    &.link__saved-cart {
      &:before {
        &:extend(.fas);
        content: @fa-var-star;
      }
    }

    &.link__cart {
      &:before {
        &:extend(.fas);
        content: @fa-var-shopping-cart;
      }
    }
  }
}

.header__cart-link {

  &.buzz-out {
    -webkit-animation-name: buzz-out;
    animation-name: buzz-out;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }

  &:hover {
    text-decoration: none;
  }

  .header__cart {
    position: relative;
  }

  .header__cart-count {
    position: absolute;
    top: -32px;
    right: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: @WHITE;
    line-height: 24px;
    text-align: center;
    background: @PRIMARY;

    @media (max-width: @screen-xs-max) {
      padding-left: 1px;
      padding-top: 1px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      right: 0;
      top: -10px;
    }

  }
}


.header__navigation {
  display: -ms-grid;
  display: none;
  -ms-grid-columns: 17.5% 83.5%;
  grid-template-columns: 17.5% 83.5%;
  -ms-grid-rows: 50px;
  grid-template-rows: 50px;
  grid-template-areas: "navigationBurger navigationBar" "breadcrumb breadcrumb";


  .navigation__burger {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column: 1;
    grid-column-start: 1;
    grid-area: navigationBurger;
    display: flex;
    align-items: center;
    height: 100%;
    width: 210px;
    position: relative;

    &:after {
      content: "";
      width: 1px;
      height: 18px;
      position: absolute;
      right: 0;
      top: 16px;
      background: @navigation-bar-border;
    }

    &:hover {
      background: @navigation-burger-bg-hover;
      cursor: pointer;
    }


    .nav__headline-text {
      font-size: 19px;
      font-weight: 700;
      color: @navigation-headline-color;
      text-transform: uppercase;
      text-decoration: none;
      padding-left: 15px;

      &:after {
        &:extend(.fas);
        content: @fa-var-bars;
        color: @navigation-headline-color;
        font-size: 18px;
        margin-left: 10px;
      }


      &.is-loading::after {
        content: @fa-var-spinner;
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
      }
    }


    &.is-open .nav__headline-text {
      color: @navigation-headline-color-hover;

      &:after {
        color: @navigation-headline-color-hover;
      }
    }

    .navigation__list {
      display: none;
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 1000;
      width: 210px;
      margin: 0;
      padding: 0;
      background: @navigation-list-bg;
      border: 1px solid @navigation-list-border;

      &.is-open {
        display: block;
      }

      .navigation__list-item {
        list-style: none;

        .list-item__link {

          a {
            position: relative;

            display: block;
            text-decoration: none;

            padding: 12px 15px;

            color: @navigation-link-color;
            background-color: @navigation-link-bg;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;

            transition: color .25s ease-in-out, background-color .25s ease-in-out;
          }

          &.list-item__link-node a:after {
            content: "";
            position: absolute;
            top: 0;
            opacity: 0;
            right: -12px;
            height: 100%;
            width: 12px;
            z-index: 1000;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='100%25' viewBox='0 0 12 25' preserveAspectRatio='none' shape-rendering='geometricPrecision'%3E%3Cg transform='translate(0 -1027.5)'%3E%3Cpath fill='%23004c07' transform='matrix(-.69667 -.7257 .40222 -1.2569 -407.61 2351.4)' d='m17.316 1043.4h-8.6124-8.6124l4.3062-7.4586 4.3062-7.4585 4.3062 7.4585z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
            -webkit-transition: opacity .25s ease-in-out;
            transition: opacity .25s ease-in-out;
            background-size: 100% 100%;
          }
        }


        .list-item__link.is-open a {
          background: @navigation-link-bg-hover;
          color: @navigation-link-color-hover;

          &:after {
            opacity: 1;
          }
        }

        .navigation__card {
          display: none;
          position: absolute;
          left: 208px;
          width: calc(~"100vw - 250px");
          max-width: 950px;
          top: -1px;
          background-color: @navigation-card-bg;
          padding: 9px;
          z-index: 700;
          border: 1px solid @navigation-card-border;

          &.is-open {
            display: block;
          }

          .navigation__card__items {
            display: inline-block;
            width: 100%;
          }

          .card__list-wrapper {
            display: flex;
            flex-direction: column;
            position: relative;
            list-style: none;
            width: 25%;
            float: left;
            min-height: 175px;

            &.card__list-bottom {
              position: absolute;
              flex-direction: row;
              top: -52px;
              left: -1px;
              right: -1px;
              width: auto;
              min-height: auto;
              background-color: @SECONDARY-GREEN;;

              &:before,
              &:after {
                display: none;
              }

              .card__list-news {
                border-right: 2px solid @navigation-news-border;
              }

              .card__list-news,
              .card__list-offers {
                display: flex;
                width:50%;
                a {
                  display: flex;
                  width: 100%;
                  position: relative;
                  text-decoration: none;
                  padding: 10px 15px;
                  background-color: @navigation-link-color;
                  color: @navigation-link-bg;
                  font-size: 16px;
                  font-weight: bold;
                  text-transform: uppercase;
                  transition: color .25s ease-in-out, background-color .25s ease-in-out;
                  text-align: center;
                  align-items: center;
                  justify-content: center;

                  i {
                    font-size: 30px;
                    margin-right: 10px;
                  }
                }
              }
            }

            &:nth-child(4n + 4) {
              &:after {
                display: none
              }
            }

            &:before {
              display: block;
              position: absolute;
              bottom: 0;
              right: 9px;
              left: 9px;
              height: 2px;
              border-top: 2px solid @navigation-bar-border;
              content: "";
            }

            &:after {
              display: block;
              position: absolute;
              top: 9px;
              right: 0;
              bottom: 9px;
              width: 2px;
              border-right: 2px solid @navigation-bar-border;
              content: "";
            }

            .card__list-title {
              font-size: 13px;
              font-weight: 700;
              color: @navigation-color;
              padding-left: 9px;
              padding-top: 9px;

              a {
                font-size: 13px;
                font-weight: 700;
                color: @navigation-color;
              }
            }

            .card__content {
              max-height: 142px;
              overflow: hidden;
              .card__list {
                list-style: none;
                padding: 0 9px 9px 9px;
                margin: 0;
              
                .card__list-item,
                .card__list-more {
                  a {
                    font-size: 13px;
                  }
                }

                .card__list-item a {
                  color: @navigation-color;
                  word-break: break-word;
                }

                .card__list-more {
                  display: none;
                  &.has-more {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.navigation__bar {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column: 2;
  grid-column-start: 2;
  grid-area: navigationBar;
  height: 50px;
}

.bar__list {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  flex-direction: row;

  .bar__list-item {
    flex: 1 auto;
    text-align: center;
    padding-top: 15px;

    &.bar__list-item--icon {
      flex: 1;
      margin: 0 10px 0 0;
      padding-top: 8px;
      text-align: right;
      align-self: center;
    }

    a {
      font-weight: 700;
      color: @list-item-color;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
        color: @navigation-headline-color-hover;
      }

      .list__item--posToggle {
        color: @list-item-color;

        &:hover {
          text-decoration: none;
          color: @navigation-headline-color-hover;
        }

        &:before {
          &:extend(.fas);
          content: @fa-var-key;
          font-size: 26px;
          color:inherit;
        }

        &.active {

          color: @LINK;
          &:hover {
            text-decoration: none;
            color: @navigation-headline-color-hover;
          }
        }
      }

      .list__item--fairToggle {
        display: block;
        height: 30px;
        width: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/icon-fair-inactive-de.png');

        &.active {
          background-image: url('../images/icon-fair-active-de.png');
        }
      }

      .list__item--fairToggle-nl {
        background-image: url('../images/icon-fair-inactive-nl.png');

        &.active {
          background-image: url('../images/icon-fair-active-nl.png');
        }
      }

      .list__item--fairToggleAnniv-de {
        background-image: url('../images/icon-fair-inactive-anniv-de.png');

        &.active {
          background-image: url('../images/icon-fair-active-anniv-de.png');
        }
      }
      .list__item--fairToggleAnniv-fr {
        background-image: url('../images/icon-fair-inactive-anniv-fr.png');

        &.active {
          background-image: url('../images/icon-fair-active-anniv-fr.png');
        }
      }
      .list__item--fairToggleAnniv-it {
        background-image: url('../images/icon-fair-inactive-anniv-it.png');

        &.active {
          background-image: url('../images/icon-fair-active-anniv-it.png');
        }
      }

    }
  }
  &.bar__list-viewSwitches {
    width: auto;
  }
}

.meta-nav__viewSwitches {
  display: none;

  &.is-shown {
    display: inherit;
  }
  .bar__list-item.bar__list-item--icon {
    align-self: flex-start;
    a {
      .list__item--fairToggle {
        height: 22px;
        width: 22px;
      }
      .list__item--posToggle:before {
        vertical-align: top;
        font-size: 22px;
      }
    }
  }
}

@media (max-width: @screen-md) {

  .header__container {
    transition: all .75s ease;

    .header__mobile-search {
      position: relative;
      width:100%;
      margin-right: 5px;
    }

    &.is-active {
      .header__content {
        -ms-grid-rows: 36px;
        grid-template-rows: 36px;

        .header__left {
          display: none;
        }

        .header__mobile {
          &.is-active {
             padding-top: 15px;

            .language-selector {
              display: none;
            }

            .container-fluid {
              margin-top: 0;
            }



            .header__search-back {
              display: block;
              font-size: 25px;
            }

            .header__nav-mobile,
            .header__list-wrapper {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1003px) and (min-width: 971px) {
  .header__navigation {
    .navigation__burger {
      .nav__headline-text {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 970px) and (min-width: @screen-md) {
  .header__navigation {
    .navigation__burger {
      .nav__headline-text {
        font-size: 17px;
      }
    }
  }
}

// mobile stying //
@media (max-width: @screen-md) {

  .meta-nav {
    display: none;
  }

  .header__saved-cart-count {
    position: absolute;
    top: 7px;
    left: 8px;
    color: #fff;

    &.header__saved-cart-count--below-10 {
      left: 12px;
    }
  }

  .header__container {
    .header__content {

      -ms-grid-rows: 90px;

      grid-template-rows: 90px;

      .header__desktop:not(.header__left) {
        display: none;
      }

      .header__left {
        margin-top: 40px;
        margin-left: 0;

        .banner__component {
          img {
            width: 200px;
          }
        }
      }

      .header__mobile {
        -ms-grid-row: 2;
        grid-row-start: 2;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        display: block;
        grid-area: navigation;
        margin-bottom: 15px;

        &__toolbar {
          display: -ms-flexbox;
          display: grid;
          -ms-grid-columns: 32px calc(~"100% - 197px") 174px;
          grid-template-columns: 32px auto 174px;

          .header {
            &__burger-menu {
              align-self: flex-start;
              -ms-grid-column-align: start;
              justify-self: start;

              color: @header-search-icon-color;

              &:hover {
                color: @header-search-icon-hover-color;
              }

              &__mobile-search {
                position: relative;
                max-width: calc(~"100% - 197px");
              }

              &__list-wrapper {
                align-self: flex-end;
                -ms-grid-column-align: end;
                justify-self: end;
              }
            }
          }
        }


        &.is-active {
          .header__mobile__toolbar {
            -ms-grid-columns: 32px auto 0;
            grid-template-columns: 32px auto 0;

          }
        }

        .mobile__meta-nav {
          position: absolute;
          top: 0;
          width: 100%;

          .meta-nav__link {
            float: right;
            margin-right: 10px;

            &:hover {
              text-decoration: none;
            }
          }
        }

        .header__search-back {
          display: none;
        }

        .header__list {
          display: flex;
          height: 35px;
          margin: 0;
          padding: 0;

          .header__cart-link {
            .header__cart-count {
              top: -40px;
              right: -10px;
            }
          }

          .nav-cart {
            .link__cart {
              max-width: 70px;
              margin: 0 auto
            }
          }

          .link__content {
            display: none;
          }
        }


        .search__input {
          height: 35px;
          min-width: 85px;
        }

        .search__button,
        .delete__button {
          color: @header-search-icon-color;
          background-color: @header-search-bg-color;
          position: absolute;
          top: 10px;

          .fas {
            font-size: 18px;
          }
        }

        .search__button {
          right: 8px;
        }

        .delete__button {
          right: 25px;
          width: 30px;

          &.is-hidden {
            display: none;
          }
        }

        .header__nav-mobile {
          display: block;
          padding-top: 3px;
          width: 28px;
          color: @SECONDARY;
          cursor: pointer;
          position: absolute;
          left: 12px;

          &:hover {
            color: @HOVER
          }


          i {
            font-size: 30px;
          }
        }

        .header__list-wrapper {


          .header__list {
            .header__list-item {
              float: left;
              width: 40px;
              margin-right: 5px;

              i {
                font-size: 30px;
              }

              .header__link {

                .header__numberCarts {
                  position: absolute;
                  top: 7px;
                  left: 13px;
                  color: #fff;
                }
              }
            }
            .header__ep-shopping-cart {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .header-mobile__logged-in {
    font-size: 14px;
    background: @meta-nav-bg-color;
    margin-left: -25px;
    padding: 0 25px;
  }

  .mobile__nav {
    display: block;
    position: fixed;
    left: -330px;
    top: 0;
    bottom: 0;
    width: 330px;
    background-color: @mobile-nav-bg;
    will-change: left;
    transition: left .3s ease-in-out;
    z-index: 100000;
    overflow-x: hidden;
    overflow-y: auto;


    .navigation__bar {
      transition: left .3s ease-in-out;

      .bar__list {
        display: block;
        margin-left: 5px;

        .bar__list-item {
          display: inline-block;
          margin-left: 15px;
        }
      }

      &.is-transition {
        left: 380px;
      }
    }

    .nav__headline-text {
      font-size: 20px;
      font-weight: 700;
      color: @navigation-headline-color;
      text-transform: uppercase;
      text-decoration: none;
      padding: 10px 10px 0 0;
    }

    &.is-offcanvas {
      left: 0;
    }

    .nav__headline{
      display: flex;
      justify-content: space-between;
    }

    .nav__close-icon {
      float: right;
      margin: 5px 12px;
    }

    .nav__list {

      transition: -webkit-transform .3s ease-in-out;
      transition: transform .3s ease-in-out;
      transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;

      &.is-active {
        webkit-transform: translate(380px);
        transform: translate(380px);
      }

      &.is-transition {
        left: 380px;
      }

      &.nav__list--extra {
        position: relative;
        left: 0;
        top: 0;
      }

      .nav__list-item {
        border-bottom: 1px solid @mobile-nav-list-border;
        background: @mobile-nav-list-bg;

        .nav__link {
          display: inline-block;
          width: 88%;
          vertical-align: middle;

          div,
          a {
            display: inline-block;
            padding: 20px 5% 20px 20px;
            margin-top: 0;
            font-size: 17px;
            color: @mobile-nav-color;
            text-transform: capitalize;
            text-decoration: none;
            width: 100%;
          }

          &:hover {
            cursor: pointer;
          }

          .list-item__link {
            color: @mobile-nav-color;
          }
        }

        i {
          display: inline-block;
          width: 10%;
          font-size: 25px;
          vertical-align: middle;
          color: @mobile-nav-color;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .nav__submenu {
      display: block;
      background: @mobile-subnav-bg;

      position: absolute;
      top: 0;
      width: 100%;
      left: -380px;
      transition: -webkit-transform .3s ease-in-out;
      transition: all .3s ease-in-out;
      transition: all .3s ease-in-out, -webkit-transform .3s ease-in-out;

      &.is-active {
        left: 0;
      }

      &.is-transition {
        left: 380px;
      }
    }

    .nav__submenu-item {
      border-bottom: 1px solid @mobile-nav-submenu-color;

      &.nav__submenu-item--alt {
        background: @mobile-subnav-back-bg;

        a {
          color: @mobile-nav-color;

          &:hover {
            text-decoration: none;
          }

          i {
            color: @mobile-nav-color;
            margin-right: 10px;
          }
        }
      }

      span {
        display: inline-block;
        padding: 20px 5% 20px 20px;
        margin-top: 0;
        font-size: 17px;
        color: @mobile-nav-submenu-color;
        text-transform: capitalize;
        width: 88%;
        vertical-align: middle;

        &:hover {
          cursor: pointer;
        }

        a {
          &.no-text-transform {
            text-transform: none;
          }
        }
      }

      i {
        display: inline-block;
        width: 10%;
        font-size: 25px;
        vertical-align: middle;
        color: @mobile-nav-submenu-color;

        &:hover {
          cursor: pointer;
        }
      }

      &.nav__submenu-item--back {
        background: @mobile-subnav-back-bg;
        text-align: center;

        span {
          color: @mobile-nav-submenu-back-color;

          &:hover {
            cursor: pointer;
          }
        }

        i {
          color: @mobile-nav-submenu-back-color;
          font-size: 25px;
          margin-top: -5px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .submenu__list {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      left: -380px;
      transition: -webkit-transform .3s ease-in-out;
      transition: all .3s ease-in-out;
      transition: all .3s ease-in-out, -webkit-transform .3s ease-in-out;

      &.is-active {
        left: 0;
      }

      .submenu__list-item {
        display: block;
        margin-top: 0;
        font-size: 17px;
        color: @mobile-nav-submenu-list-color;
        text-transform: capitalize;
        border-bottom: 1px solid @mobile-nav-submenu-list-border;

        a {
          padding: 20px 5% 20px 20px;
          display: block;

          &:hover {
            cursor:pointer;
          }

          &.no-text-transform {
            text-transform: none;
          }
        }
      }
    }

    .nav__headline {
      padding: 20px;
      font-size: 18px;
      font-weight: 700;

      &.is-transition {
        left: 380px;
      }

      &.nav__headline--move {
        position: relative;
        top: 0;
        left: 0;

        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;

        &.is-active {
          webkit-transform: translate(380px);
          transform: translate(380px);
        }

        &.is-transition {
          left: 380px;
        }

      }
    }
  }

  .mobile__overlay {
    &.is-offcanvas {
      position: fixed;
      top: 0;
      left: 330px;
      right: 0;
      bottom: 0;
      z-index: 99900;
      background-color: #000;
      opacity: .6;
      cursor: pointer;
    }
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

@media (max-width: 450px) {
  .mobile__meta-nav {
    .header-mobile__logged-in {
      font-size: 12px;
    }

    .meta-nav__link {
      display: block;
      padding-left: 10px;
      margin-top: 5px;
    }
  }
}

.nav__left {
  padding-top: 20px;
}

ul.nav__links {

  display: table;
  width: 100%;

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  li {
    display: table-cell;
    list-style-type: none;
  }

  &::after {
    clear: both;
  }

  @media (max-width: @screen-sm-max) {
    &:before {
      content: " ";
      display: table;
    }
  }
}

// sticky header
.fake__header {
  display: none;
}

.header__wrapper.sticky {
  .header__container {
    position: fixed;
    z-index: 9997;
    width: 100%;

    border-bottom: 1px solid @navigation-bar-border;

    .navigation__burger {
      display: block;
      padding-top: 10px;
    }

    .header__navigation {
      -ms-grid-row: 1;
    }

    .header__content {
      display: -ms-grid;
      display: block;
      -ms-grid-columns: 210px 1fr;
      grid-template-columns: 210px 1fr;
      -ms-grid-rows: 70px;
      grid-template-rows: 70px;
      grid-template-areas: "nav navRight";
      margin-top: 15px;
      margin-bottom: 15px;
      width: 100%;

      .header__mobile .mobile__meta-nav {
        display: none;
      }
    }

    .header__navigation {
      grid-area: unset;
      max-width: 210px;
    }


    .header__right {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 2;
      grid-column-start: 2;
      grid-area: navRight;
      margin: 0;
    }

    .navigation__burger {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 1;
      grid-column-start: 1;
      grid-area: nav;
    }

    .header__right {
      display: none;
    }

    .header__list {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 2;
      grid-column-start: 2;
      grid-area: headerRight;
    }

    .header__right > .header__list {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 2;
      grid-column-start: 2;
    }

    .header__search {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 1;
      grid-column-start: 1;
      grid-area: headerLeft;
      margin: 5px 50px;
    }

    .header__right > .header__search {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column: 1;
      grid-column-start: 1;
    }

    .navigation__overflow,
    .header__left,
    .navigation__bar,
    .header__user,
    .nav__left {
      display: none;
    }
  }
}

@-webkit-keyframes buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}

@keyframes buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}

main {
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;

  &.is-offcanvas {
    webkit-transform: translate(330px);
    transform: translate(330px);
  }
}

@media (max-width: @screen-sm-max) {
  html.is-offcanvas {
    overflow-x: hidden;
  }
}

@media (min-width: 960px) {
  .header__wrapper{
    padding-top: 25px;
  }

  .header__wrapper.sticky {
    .header__container {
      .header__right {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 50% 50%;
        grid-template-columns: 50% 50%;
        -ms-grid-rows: 70px;
        grid-template-rows: 70px;
        grid-template-areas: "headerLeft headerRight";

        float: none;
        width: 100%;
      }
    }
  }

  .header__wrapper.sticky .header__container{
    .header__content{
      display: -ms-grid;
      display: grid;
    }

    .header__content .header__navigation {
      display: block;
    }
  }

  .header__container .header__content .header__navigation {
    display: -ms-grid;
    display: grid;
  }
}
