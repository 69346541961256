.energy {
	position: absolute;
	top: 40px;
	right: 5px;
	text-decoration: none;
	color: @energy-label-color;
	text-align: left;
	padding: 0 2px;

	&::before {
		display: block;
		position: absolute;
		background-color: transparent;
		top: 0;
		bottom: 0;
		content: "";
	}

	&.energy__second-row {
		top: 65px;
	}

	&.small-size{
		height: 12px;
		min-width: 25px;
		line-height: 12px;
		font-size: 10px;
		
		&::before {
			width: 6px;
			left: -6px;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-right: 6px solid #009036;
		}
	}

	&.big-size{
		height: 30px;
		min-width: 65px;
		line-height: 30px;
		font-size: 24px;
		
		&::before {
			width: 15px;
			left: -15px;
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-right: 15px solid #009036;
		}
	}
	&.eek-21-big-size{
		height: 30px;
		min-width: 65px;
		line-height: 30px;
		font-size: 24px;
	
		img {
			height: 34px;
		}
	}

	&:hover {
		color: @energy-label-color;
		text-decoration: none;
	}

	&.level1 {
		.energy-label-color(#009036);
	}

	&.level2 {
		.energy-label-color(#57ab27);
	}

	&.level3 {
		.energy-label-color(#c9d200);
	}

	&.level4 {
		.energy-label-color(#ffec00);
	}

	&.level5 {
		.energy-label-color(#fabb00);
	}

	&.level6 {
		.energy-label-color(#eb690a);
	}

	&.level7 {
		.energy-label-color(#e2001a);
	}
}

.ernegylabel__modal {
  display: none;
}

#cboxContent {
  .ernegylabel__modal {
    display: block;
  }
}