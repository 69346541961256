.product__icons.partial {
    text-align: right;

  .go-green__icon {}

  .icon__address--text {
    text-align: left;
  }

  &:empty {
    display: none;
  }

  .icon {
      margin-left: 5px;
      color: @delivery-icon-color;
      font-size: 20px;
      font-weight: 700;
      line-height: 35px;

      &.icon-status {
        &:after {
          font-size: 20px;
          font-weight: 700;
        }
        &.icon__listing {
          position: relative;
          &:before {
            &:extend(.fa);
            content: @fa-var-industry;
            color: @listing-icon-not-listed;
          }
	
          &:after {
            &:extend(.fa);
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            text-align: center;
            content: @fa-var-exclamation-triangle;
            font-size: 9px;
            color: @listing-icon-not-listed-triangle;
          }
        }
        &.icon__listing--listed {
          &:before {
            color: @listing-icon-listed;
          }
          &:after {
            content: "";
          }
        }
      }

    &.icon__distributionType {
      &:after {
        content: attr(data-icon);
      }
    }

    &.icon__assortmentType {
      &:after {
        content: attr(data-icon);
      }
    }

      &.icon__assortmentType_onlineSelection {
        &:after {
          content: "O";
        }
      }

      &.icon__accountAssignmentGroupType_excibition {
        &:after {
          content: "M";
        }
      }

    &.icon__address {
      &:after {
        &:extend(.fa);
        content: @fa-var-address-card;
      }
    }

      &.icon__positionTypesGroup_virtualStock {
        &:after {
          &:extend(.fa);
          content: @fa-var-cloud;
        }
      }

      &.icon__exclusiveFlag {
        &:after {
          &:extend(.fa);
          content: @fa-var-certificate;
        }
      }

      &.icon__nolisting {
        &:after {
          &:extend(.fa);
          content: @fa-var-industry;
          color: #ccc;
        }
      }
      &.icon__positionTypesGroup_orderProduct {
        &:after {
          &:extend(.fa);
          content: @fa-var-envelope;
        }
      }
      &.icon__journalReviews {
        &:after {
          &:extend(.fa);
          content: @fa-var-thumbs-up;
        }
      }
      &.icon__price {
        &:after {
          &:extend(.fa);
          content: @fa-var-tag;
        }
      }
      &.icon__ban {
        &:after {
          &:extend(.fa);
          color: @ban-icon-color;
          content: @fa-var-ban;
        }
      }
      &.icon__clock {
        &:after {
          &:extend(.far);
          content: @fa-var-clock;
        }
      }
      &.icon__forcingProgram {
        &:after {
          &:extend(.fa);
          color: @CAMPAIGN-RED;
          content: @fa-var-percentage;
        }
      }

      &.icon__fair {
        display: inline-block;
        vertical-align: bottom;
        height: 30px;
        width: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/icon-fair-active-de.png');

        &.icon__fair-nl {
          background-image: url('../images/icon-fair-active-nl.png');
        }

        &.icon__fairAnniv-de {
          background-image: url('../images/icon-fair-active-anniv-de.png');
        }
        &.icon__fairAnniv-fr {
          background-image: url('../images/icon-fair-active-anniv-fr.png');
        }
        &.icon__fairAnniv-it {
          background-image: url('../images/icon-fair-active-anniv-it.png');
        }
      }
    }
  }
