.accordion {
  font-weight: 700;
  text-transform: initial;

  &.ui-accordion {
    .ui-accordion-header {
      &.ui-accordion-header-active {
        &:after {
          -webkit-transform: rotate(18deg);
          -moz-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }

      &.ui-state-hover,
      &.ui-state-active {
        background: @accordion-header-bg;
        color: @accordion-font-color;

        a {
          background: @accordion-header-bg;
          color: @accordion-font-color;
        }
      }
    }

    .ui-accordion-content {
      display: none !important;
      border: none;
      height: auto !important;

      &.ui-accordion-content-active {
        display: block !important;
      }
    }
  }

  .accordion__content {
    display: none;
  }

  .accordion__header {
    background: @accordion-header-bg;
    text-align: left;
    font-weight: 700;
    border: none;
    display: block;
    cursor: pointer;
    position: relative;
    margin: 0 0 10px 0;
    padding: 10px 50px 10px 25px;
    font-size: 18px;
    word-break: break-word;
    
    .ui-accordion-header-icon{
      display: none;
    }
    
    &.ui-icon.ui-state-default.ui-state-focus{
      color: inherit;
    }

    &:after {
      &:extend(.fa);
      content: @fa-var-angle-down;
      font-size: 25px;
      position: absolute;
      right: 25px;
      top: 5px;

      -webkit-transition-duration: 0.5s;
      -moz-transition-duration: 0.5s;
      -o-transition-duration: 0.5s;
      transition-duration: 0.5s;

      -webkit-transition-property: -webkit-transform;
      -moz-transition-property: -moz-transform;
      -o-transition-property: -o-transform;
      transition-property: transform;
    }
  }

  .ui-widget-content a {
    color: inherit;
  }
  .ui-widget-content a.link {
  	color: @link-color;
  }
}