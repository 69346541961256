.sticky {
  .autocomplete {
    left:0;
    right: auto;
  }
}

.is-active {
  .autocomplete {
    left:0;
    right: 0;
  }
}
.autocomplete {
  position: absolute;
  right: 0;
  display: -ms-grid;
  display: grid;
  overflow-x: hidden;
  justify-items: stretch;
  align-items: stretch;
  z-index: 10000;
  background: @autocomplete-background;
  color: @autocomplete-color;
  border: 1px solid @autocomplete-border;
  -ms-grid-columns: 200px 400px;
  grid-template-columns: 200px 400px;
  -ms-grid-rows: 155px 155px 150px 55px;
  grid-template-rows: 155px 155px 150px 55px;
  grid-template-areas:
          "autocomplete__suggestion  autocomplete_product"
          "autocomplete__category  autocomplete_product"
          "autocomplete__brand  autocomplete_product"
          "autocomplete_pages autocomplete_pages";

  .autocomplete__suggestion {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column: 1;
    grid-column-start: 1;
    grid-area: autocomplete__suggestion;
    overflow: hidden;
  }

  .autocomplete__brand {
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    grid-area: autocomplete__brand;
    overflow: hidden;
  }

  .autocomplete__category {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    grid-area: autocomplete__category;
    overflow: hidden;
  }

  .autocomplete__pages {
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-column-span: 2;
    grid-column-start: 1;
    grid-area: autocomplete_pages;
    display: flex;

    a {
      width: 100%;
      position: relative;
      color: @WHITE;
      text-decoration: none;
      padding: 18px 15px;
      background-color: @SECONDARY;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      align-items: center;
      justify-content: center;

      &:nth-child(2) {
        border-left: 1px solid @WHITE;
      }

      .count__badge {
        height: 25px;
        min-width: 25px;
        background-color: @GRAY-LIGHTER;
        color: @LINK;
        border-radius: .75em;
        display: inline-block;
        padding: 0px 5px 0px 5px;
        margin-left: 3px;
      }
    }
  }

  .autocomplete__product {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 3;
    grid-row-end: span 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    grid-area: autocomplete_product;
  }

  .autocomplete__suggestion,
  .autocomplete__brand,
  .autocomplete__category,
  .autocomplete__product {
    display: block;
    position: relative;
    overflow: hidden;
  }

  .ui-widget-content {
    .ui-state-active{
      background: @WHITE;
    }
  }


  .ui-menu-item {
    &:hover {
      cursor: inherit;
    }
  }

  .search-results-highlight {
    font-weight: bold;
    font-style: inherit
  }


  .autocomplete__product {
    display: block;
    margin-top: 10px;

    .product-search {
      display: block;
      margin: 0 5px 5px 5px;
      height: 85px;
      overflow: hidden;

      &.is-selected {
        background: @autocomplete-hover
      }

      form,
      a {
        display: flex;
        width: 100%;
        height: 100%;
        color: @autocomplete-color;

        .product-thumb {
          margin: 0 auto;
          padding-left: 5px;
          width: 25%;
          height: 75px;
          position: relative;


          img {
            max-height: 60px;
            max-width: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            padding-left: 5px;
          }
        }

        .product-info {
          width: 75%;
          margin-left: 15px;
          color: #707173;
          border-bottom: 0;
          margin-bottom: 0;

          .product-name {
            margin-top: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .product__info-color::before {
            content: " - ";
          }

          .product-desc {
            height: 20px;
            width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .product-price {
            color: @autocomplete-price-color;
          }
        }

      }
    }
  }

  .autocomplete__category,
  .autocomplete__brand,
  .autocomplete__suggestion {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 15px;
      right: 0;
      width: 2px;
      height: 150px;
      background-color: @autocomplete-border;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 10px;
      bottom: 0;
      width: 165px;
      height: 2px;
      background-color: @autocomplete-border;
    }

    .autocomplete__headline {
      padding-left: 15px;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 5px;
      padding-top: 10px;
      display: block;
    }

    .product-search {
      padding-left: 15px;
      line-height: 22px;

      a {
        color: @autocomplete-color;
      }
    }
  }

  .autocomplete__suggestion {
    .product-search {

      a {
        color: @autocomplete-color;
      }
      &:first-of-type {
        padding-top: 10px;
      }
    }
  }

  .autocomplete__category {
    &:after {
      top: 0;
    }
  }

  .autocomplete__brand {
    &:before {
      width: 0;
    }
    &:after {
      top: 0;
      height: 135px;
    }
  }

}

.cart__empty {
  .autocomplete__add-to-cart {
    .autocomplete {
      width: 100%;
    }
  }
}

.autocomplete__add-to-cart {
  .autocomplete {
    -ms-grid-columns: 400px;
    grid-template-columns: 400px;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    grid-template-areas: "autocomplete__product";
    top: 33px;
    min-width: 400px;

    .autocomplete__product {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-row-span: 3;
      grid-row-end: span 3;
      -ms-grid-column: 1;
      grid-column-start: 1;
    }
  }

  .autocomplete__suggestion,
  .autocomplete__category,
  .autocomplete__brand {
    display: none;
  }
}


@media (max-width: @screen-md) {
  .autocomplete {
    height: calc(~'100vh - 65px') !important;
    top: 50px !important;
    left:0;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    -ms-grid-rows: calc(~'100% - 58px') 55px;
    grid-template-rows: calc(~'100% - 58px') 55px;
    grid-template-areas: "autocomplete_product" "autocomplete_suggestion" "autocomplete_pages";

    .autocomplete__brand,
    .autocomplete__category,
    .autocomplete__suggestion {
      display: none !important;
    }

    .autocomplete__product {
      .product-search {
        width: 95%;
        border-bottom: 1px solid @autocomplete-border;

        a {
          color: @autocomplete-color;

          .product-thumb {
            display: none;
          }

          .product-info {
            .product-name {
              margin-top: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .autocomplete__pages {
      -ms-grid-row: 2;
      grid-row-start: 2;
      -ms-grid-column-span: 2;
      grid-column-start: 1;
    }

    .autocomplete__product {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-column: 1;
      grid-column-start: 1
    }
  }
}
