/**
CSS fixes for smartedit
 */

[data-smartedit-component-id="NavigationBarSlot"],
[data-smartedit-component-id="epCHCategoryNavComponent"],
[data-smartedit-component-id="epATCategoryNavComponent"],
[data-smartedit-component-id="epNLCategoryNavComponent"],
[data-smartedit-component-id="epDECategoryNavComponent"] {
  height: 100%;
}

[data-smartedit-component-id="AssistedServiceComponent"] {
  display: none;
}

