.text-banner-component{
	&:extend(.clearfix all);
	margin-top: 18px;
	
	&.Top,
	&.Left,
	&.Right,
	&.Left_Fixed,
	&.Right_Fixed,
	&.Left_Float,
	&.Right_Float,
	&.Left_Float_Fixed,
	&.Right_Float_Fixed{
		.text-banner-banner{
			margin-top: 15px;
		}
	}
	
	&.Bottom{
		.text-banner-banner{
			margin-bottom: 15px;
		}
	}
			
	&.Left_Fixed,
	&.Right_Fixed{
		display: flex;
		.text-banner-text{
			margin-top: 0;
			flex: 3 1 0;

			a,
			u,
			em,
			s,
			strong {
				display: contents;
			}
		}
			
		.text-banner-banner{
			margin-top: 0;
			flex: 0 0 auto;
		}
	
		&.tPlain{
			.text-banner-text{
				display: flex;
				flex-direction: column;
				justify-content: center;

				a,
				u,
				em,
				s,
				strong {
					display: contents;
				}
			}
		}
	}
			
	&.Left_Fixed{
		.text-banner-banner {
			padding-left: 10px;
			text-align: right;
		}
	}
	
	&.Left_Float,
	&.Right_Float,
	&.Left_Fixed_Float,
	&.Right_Fixed_Float {
		.text-banner-banner{
			display: block;
			margin-bottom: 15px;
		}
	}
	
	&.Left_Fixed_Float {
		.text-banner-banner {
			display: inline-block;
			margin-left: 15px;
			float: right;
		}
	}
			
	&.Right_Fixed_Float {
		.text-banner-banner{
			display: inline-block;
			margin-right: 15px;
			float: left;
		}
	}
			
	&.Right_Fixed {
		.text-banner-banner {
			padding-right: 10px;
			text-align: left;
		}
	}
	
	&.Left_Float,
	&.Right_Float{
		.text-banner-banner{
			display: none;
		}
	}

	&.has-video {
		&.Left,
		&.Right,
		&.Top,
		&.Bottom {
			&.video-ready {
				.video-container {
					display: block;
				}
					
				.text-banner-banner-wrapper {
					display: none;
				}
			}
			.video-container {
				display: none;
			}
		}
				
		&.api-ready {
			.text-banner-banner-wrapper {
				overflow: hidden;
				&:after {
					&:extend(.far);
					content: @fa-var-play-circle;
				}
				&:hover {
					&:after {
						opacity: 1;
					}
				}

				.text-banner-img {
					cursor: pointer;
				}
			}
		}
		.text-banner-banner-wrapper {
			position: relative;
			display: inline-block;
			&:after {
				position: absolute;
				top: -99999px;
				bottom: -99999px;
				left: -99999px;
				right: -99999px;
				opacity: 0.8;
				content: '';
				background: rgba(0,0,0, 0.0) url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtcGxheS1jaXJjbGUgZmEtdy0xNiIgYXJpYS1oaWRkZW49InRydWUiIGRhdGEtaWNvbj0icGxheS1jaXJjbGUiIGRhdGEtcHJlZml4PSJmYXIiIGZvY3VzYWJsZT0iZmFsc2UiIHJvbGU9ImltZyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+CjxtZXRhZGF0YT4KPHJkZjpSREY+CjxjYzpXb3JrIHJkZjphYm91dD0iIj4KPGRjOmZvcm1hdD5pbWFnZS9zdmcreG1sPC9kYzpmb3JtYXQ+CjxkYzp0eXBlIHJkZjpyZXNvdXJjZT0iaHR0cDovL3B1cmwub3JnL2RjL2RjbWl0eXBlL1N0aWxsSW1hZ2UiLz4KPC9jYzpXb3JrPgo8L3JkZjpSREY+CjwvbWV0YWRhdGE+CjxwYXRoIGQ9Ik0zNzEuNyAyMzhsLTE3Ni0xMDdjLTE1LjgtOC44LTM1LjcgMi41LTM1LjcgMjF2MjA4YzAgMTguNCAxOS44IDI5LjggMzUuNyAyMWwxNzYtMTAxYzE2LjQtOS4xIDE2LjQtMzIuOCAwLTQyek01MDQgMjU2QzUwNCAxMTkgMzkzIDggMjU2IDhTOCAxMTkgOCAyNTZzMTExIDI0OCAyNDggMjQ4IDI0OC0xMTEgMjQ4LTI0OHptLTQ0OCAwYzAtMTEwLjUgODkuNS0yMDAgMjAwLTIwMHMyMDAgODkuNSAyMDAgMjAwLTg5LjUgMjAwLTIwMCAyMDBTNTYgMzY2LjUgNTYgMjU2eiIgZmlsbD0iI2UzZTNlZiIgZmlsbC1vcGFjaXR5PSIuOTQxMTgiLz4KPC9zdmc+Cg==") no-repeat center;
				background-size: 100px;
				animation-name: text-banner-component-loading;
				animation-duration: 2500ms;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
			}
		}
	}

	.mobile-banner{
		.text-banner-banner{
			display: block;
		}
	}
	
	&.tBox{
		.text-banner-text{
			background-color: @textBannerBoxBgColor;
			padding: 18px;
			color: @textBannerBoxColor;

			a,
			u,
			em,
			s,
			strong {
				display: contents;
			}
		}
	}
	
	.text-banner-text{
		>*{
			&:first-child{
				margin-top: 0 !important;
				padding-top: 0!important;
			}
				
			&:last-child{
				margin-bottom: 0 !important;
				padding-bottom: 0!important;
			}
		}
	}
				
	.text-banner-banner{
		position: relative;
	}
}

.text-banner-link{
	display: inline-block;
	transform-style: preserve-3d;
	overflow: hidden;
	
	&:hover{
		.text-banner-img{
			transform: scale(1.05);
		}
	}
}

.text-banner-banner{
	line-height: 0;
}

.text-banner-img{
	display: inline-block;
	width: 100%;
	height: auto;
	transition: transform 150ms ease-out;
}

@media(min-width: 700px){
	.text-banner-component{
		&.Left,
		&.Right,
		&.Left_Fixed,
		&.Right_Fixed{
			display: flex;
			.text-banner-text{
				margin-top: 0;
				flex: 3 1 auto;

				a,
				u,
				em,
				s,
				strong {
					display: contents;
				}
			}
				
			.text-banner-banner{
				margin-top: 0;
				flex: 0 3 auto;
			}
		
			&.tPlain{
				.text-banner-text{
					display: flex;
					flex-direction: column;
					justify-content: center;

					a,
					u,
					em,
					s,
					strong {
						display: contents;
					}
				}
			}
		}
		
		&.Left,
		&.Right{
			.text-banner-text{
				max-width: 50%;
			}
				
			.text-banner-banner{
				flex: 1 3 auto;
			}
		}
		
		&.Left,
		&.Left_Fixed{
			.text-banner-banner{
				padding-left: 40px;
				text-align: right;
			}
		}
				
		&.Right,
		&.Right_Fixed{
			.text-banner-banner{
				padding-right: 40px;
				text-align: left;
			}
		}
		
		&.Left_Fixed,
		&.Right_Fixed{
			.text-banner-text{
				flex: 3 1 0;

				a,
				u,
				em,
				s,
				strong {
					display: contents;
				}
			}
		}
		
		&.Left_Fixed{
			.text-banner-banner{
				padding-left: 20px;
			}
		}
		
		&.Right_Fixed{
			.text-banner-banner{
				padding-right: 20px;
			}
		}
		
		&.Left_Float,
		&.Right_Float,
		&.Left_Fixed_Float,
		&.Right_Fixed_Float{
			.text-banner-banner{
				display: inline-block;
				margin-bottom: 10px;
				margin-top: 0.25em;
			}
		}
		
		&.Left_Float{
			.text-banner-banner{
				margin-left: 15px;
				float: right;
				max-width: 50%;
			}
		}
			
		&.Right_Float{
			.text-banner-banner{
				margin-right: 15px;
				float: left;
				max-width: 50%;
			}
		}
				
		&.Right{
			.text-banner-text{
				order: 2;

				a,
				u,
				em,
				s,
				strong {
					display: contents;
				}
			}
				
			.text-banner-banner{
				order: 1;
			}
		}
				
		&.Left_Float,
		&.Right_Float{
			.text-banner-banner{
				display: block;
			}
				
			.mobile-banner{
				.text-banner-banner{
					display: none;
				}
			}
		}
	}
}