@import "_navigation.less";

header {
  background: @header-background-color;

  .page-cartPage &,
  .page-homepage &,
  .page-productGrid &,
  .page-searchGrid &,
  .page-search &,
  .page-cartPage &,
  .pageType-CategoryPage & {
	margin-bottom: 0;
  }
}

.header-alerts-section-wrapper{
  &.sticky .header-alerts-section{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
}

.header-alerts-section{
  .alert {
    margin-bottom: 0;
  }
}


.cookie-alert--top {
  margin-bottom: 0;
}

#consent-management-alert {

  .consent-management-list {
    max-width: 100%;
    line-height: 1.6;
    padding: 0;
    margin: 0;
  }
  .consent-management-list__item {
    position: relative;
    margin-top: 0;
    margin-bottom: 3px;
    list-style-type: none;

    @media (max-width: @screen-sm-max) {
      border-left: 0 none;
      border-right: 0 none;
      margin-left: -20px;
      margin-right: -20px;
    }

  }
  .consent-management-list__title {
    padding: 0 15px 0 62px;
    display: block;
    position: relative;
    font-weight: 400;
    text-transform: uppercase;
    font-weight: bold;
  }
  .consent-management-list__title:before {
    font-family: 'Glyphicons Halflings';
    content: '\e114';
    font-size: 16px;
    position: absolute;
    left: 20px;
    top: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .consent-management-list__title:hover,
  .consent-management-list__title:focus {
    cursor: pointer;
    outline: none;
  }
  .is-expanded .consent-management-list__title:before {
    content: "\e113";
  }

  .consent-management-list__label {
    width: 85%;
    font-size: 15px;

    @media (max-width: @screen-sm-max) {
      width: 60%;
      font-size: 14px;
    }

  }

  .consent-management-list__content-inner {
    padding: 10px 40px 0 65px;
    font-size: 16px;

    @media (max-width: @screen-sm-max) {
      font-size: 14px;
    }

  }
  .consent-management-list__content {
    transition: height 0.3s ease-out;
    height: 0;
    overflow: hidden;
  }

  .consent-buttons-group {
    position: absolute;
    top: 10px;
    right: 20px;
    @media (max-width: @screen-xs-max) {
      right: 30px;
    }

    .btn {
      padding: 4px 38px;
      font-size: 13px;
      margin-left: 10px;

      @media (max-width: @screen-xs-max) {
        padding: 4px;
        font-size: 12px;
        margin-left: 0;
      }

    }
  }
}

