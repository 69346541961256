//////////////////////////////////
//          general             //
//////////////////////////////////
@max-item-image-width: 96px;
.item__list {

  .list-unstyled();

  .item__list--row.csv-import-status {
    display: none;
  }

  &.is-loading {
    .item__list--row.csv-import-status {
      display: flex;
    }
  }


  .item__list--row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid @border-color-2;



    & >div:not(.item__list-mobile-label) {

      flex-grow: 1;
      // font-size: 15px;
      text-align: left;
      padding: 7px 10px;
      word-break: break-all;

      &:hover {
        cursor: pointer;
      }
    }



    & > div:nth-of-type(2) div {
      word-break: break-word;
    }
  }

  .item__list--item {
    display: table;
    table-layout: fixed;
    width: 100%;
    border-bottom: 1px solid @border-color-2;
    padding: 15px 0;
    .product__checkbox{
      display: table-cell;
      width: 32px;
      padding-bottom: @input-height-base;
      vertical-align: middle;

      .fake__checkbox {
        margin-left: 10px;
      }
    }

    .item__product,
    .item__stock,
    .item__acquisition,
    .item__price,
    .item__quantity,
    .item__total {
      display: table-cell;
      padding: 10px 0;
      vertical-align: top;
      text-align: center;

      .preferredSupplierName,
      .acquisition__text {
        padding-top: 6px;
        display: block;
        white-space:nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
      }
    }

    .item__stock,
    .item__price,
    .item__total {
      padding-top: 16px;
    }

    .acquisition__text {
      padding-top: 5px;
    }


    .item__total {
      text-align: right;
    }

    .item__price {
      font-weight: bold;
      padding-left: 2px;

      .price__label {
        display: none;
      }
    }

    .item__acquisition {
      .acquisition__label {
        display: none;
      }
    }

    .item__quantity {
      .quantity__label {
        display: none;
      }

    .item__quantity--warning {
      color: @input-error;
      }
    }

    .item__price,
    .item__stock {
      position: relative;

      .item__edit {
        display: none;
        position: absolute;
        bottom: 25px;
        left: 0;
        width: 250px;

        .item__edit-icon {
          display: inline-block;
          &:after {
            &:extend(.fas);
            content: @fa-var-pencil-alt;
            font-size: 18px;
          }
        }
      }

      .stock__label {
        display: none;
      }
    }

    .item__stock {
      &.item__stock--mobile {
        display: none;
      }
    }

    .item__product {
      width: 50%;
      position: relative;

        .item__image-wrapper {
          display: flex;
          align-items: center;
          height: 130px;
          min-width: 125px;

          .product__image-link {
            margin: 0 auto;

            img {
              width: 100%;
              max-width: 125px;
            }
          }
        }

        .product__info-color::before {
          content: " - ";
        }


      .item__name-link {
        display: block;
        margin-top: 10px;
        color: @cart-color;
        text-align: left;

        &:hover {
          color: @LINK;
          text-decoration: underline;
        }

        .product__info-product-line,
        .product__info-brand {
          font-size: 1.14em;
          font-weight: 200;
        }

        .product__info-attribute {
          display: block;
          font-size: 0.93em;
          font-weight: 700;
        }

        .product__info-color,
        .product__info-name {
          display: inline;
          font-size: 1.28em;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .item__code {
        margin-bottom: 20px;
        font-size: 12px;
      }

      .product__freetext {
        width: 220px;
        height: 32px;
        padding-left: 5px;
        font-size: 12px;
      }

      .product__checkbox {
        float: left;
        position: absolute;
        top: 30%;
      }

      .product__image {
        float: left;
        margin: 0 15px 0 35px;
      }

      .product__name {
        float: left;
        width: 60%;
      }
    }
  }

  .item__list--header {
    .list-unstyled;
    display: table;
    table-layout: fixed;
    background: @cart-list-header-bg;
    width: 100%;

    li {
      display: table-cell;
      height: 35px;
      color: @table-head-color;
      font-size: 15px;
      text-align: center;
      vertical-align: middle;

      &.item__empty {
        width: 32px;
      }

      &.item__total--column {
        text-align: right;
      }

      &:first-of-type {
        text-align: left;
        padding-left: 10px;
      }


       &.item__product {
        text-transform: uppercase;
        width: 50%;
      }
    }
  }

  .item__toggle {
    font-size: @font-size-base + 1;
    width: (@font-size-base + 1 + @grid-gutter-width/2);
  }

  .item__image {
    width: (@max-item-image-width + (@grid-gutter-width * 2));
    padding: 0 @grid-gutter-width;

    img {
      max-width: @max-item-image-width;
    }
  }

  .item_brand {
    display: block;
  }

  .item__name {
    font-size: @font-size-large-1;
    font-weight: 700;
  }

  .form__quantity {
    display: inline-block;
  }

  .item__quantity {
    text-align: center;

    .form-control {
      display: inline-block;
      padding: 0 0 0 5px;
      width: 30px;
      text-align: left;
    }
  }

  .item__remove {
    display: inline-block;
    width: 20px;
    height: 22px;
    position: relative;
    top: -5px;

    &.item__remove--mobile{
      display: none;
    }

      &:after {
        &:extend(.fas);
        content: @fa-var-trash-alt;
        font-size: 18px;
        position: absolute;
        left: 7px;
        top: 2px;
      }

      &:hover {
        cursor: pointer;
      }
  }

  .item__delivery {

    &.item__delivery--mobile {
      display: none;
    }

    .item__delivery--label {
      text-transform: uppercase;
      color: @text-color-secondary;
      font-weight: 700;
    }

    .form-element-icon {
      .control-label {
        display: none;
      }
    }

    .entryInformation {
        width: 100%;
    }
  }

  .item__total--column {
    text-align: right;
    padding-right: 10px;
  }

  .item__total {
    font-size: 15px;
    font-weight: 700;
    text-align: right;
    padding-left: 0;

    .total__label {
      display: none;
    }
  }

  #cartOrderGridForm {
    .product-grid-container {
      table {
        background-color: @table-2n-line-bg;
        th {
          background-color: @cart-product-table-head-color;
          color: @text-color;
        }
      }
    }
  }
  .out-of-stock {
    color: @item-sale-color;
  }
}

.saved-cart {
  .item__list--header {
    background: @saved-cart-headline-bg;
  }

  .item__list--row {
    border: none;

    &.item__list--head {
      background: @saved-cart-headline-bg !important;
    }

    &:nth-child(even) {
      background:  @saved-cart-list-odd-bg;
    }
  }

  .saved-cart__restore,
  .saved-cart__edit {
    color: @saved-cart-link;
  }
}


.remove-item-link,
.restore-item-link {
  &:hover {
    text-decoration: none;
  }
}


.saved-cart {
  &__header {

    .cart__headline {
      .page-headline();
      text-align: center;
      padding: 0;
      margin-bottom: 20px;
      margin-top: 20px;
      display: block;
    }
  }
  .item__list-column-name,
  .item__list-column-date,
  .item__list-column-count,
  .item__list-column-total,
  .item__list-column-details {
    width: 15%;
  }


  .restore-item-column,
  .remove-item-column {
    width: 10%;
    flex-shrink: 1;
  }
  .item__list-mobile-label {
    display: none;
  }

  .item__list--loading-row {
    display: flex;
  }
  .item__list-column-loading {
    position: relative;

    .item__list-column-loading-message {
      display: flex;
      align-items: center;
    }

    &-message {
      flex-grow: 2;
      &:before {
        &:extend(.fa);
        content: @fa-var-spinner;
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
        position: absolute;
        left:-12px;
      }
    }
  }
  .item__list-column-empty {
    flex-grow: 1;

    &:after {
      content: '';
      display: block;
      width: 60%;
      height: 6px;
      background-color: @saved-cart-headline-bg;
      border-radius: 3px;
      margin: 0;
      position: relative;
      top:calc(~'50% - 3px');
    }
  }


  .item__list-column-tocart {
    display: inline-block;
    padding-left: 10px;
    color:@PRIMARY-BUTTON;
    &:after {
      &:extend(.fas);
      color: inherit;
      content: @fa-var-shopping-cart;
      font-size: 24px;
    }
    &:hover {
    color: @PRIMARY-BUTTON-HOVER;
    }
  }
}

@media (max-width: @screen-md) {
  .saved-cart {



    .item__list-mobile-label {
      display: block;
      font-weight: bold;
      padding-right: 10px;
    }
    .item__list {
      .item__list--row.csv-import-status {
        display: none;
      }

      &.is-loading {
        .item__list--row.csv-import-status {
          display: flex;
        }
      }
      .item__list--row {
        display: flex;
        flex-wrap: wrap;
        table-layout: unset;
        margin-bottom: 20px;
        &.item__list--head {
          display: none;
        }
        .item__list-column-name,
        .item__list-column-date,
        .item__list-column-count,
        .item__list-column-total {
          display: flex;
          flex-direction: column;
          padding: 3px 0 3px 10px;
          width: 50%;
          div {
            padding: 0;
          }
        }

        .item__list-column-tocart,
        .item__list-column-details,
        .restore-item-column,
        .remove-item-column {
          padding: 3px 0 3px 10px;
          width:50%;

          div {
            padding: 0;
          }

        }

        .restore-item-column,
        .remove-item-column {
          padding: 3px 0 3px 10px;
          width: auto;
          text-align: right;
          div {
            padding: 0;
          }

        }


      }
      .item__list-column-empty {
        &:after {
          content: unset;
        }
      }
/*

*/


    }

  }
}
@media (max-width: @screen-sm) {
  .saved-cart {
    .item__list {

      .item__list--row {

        .item__list-column-tocart,
        .item__list-column-details,
        .restore-item-column,
        .remove-item-column {
          display: inline-block;
        }
      }
    }

  }
}

@media (min-width: 961px) and (max-width: 1070px) {
  .item__list {
    .item__list--header {
      li {
        font-size: 12px;
      }
    }
    .item__list--item {
      font-size: 12px;
    }
  }
}

