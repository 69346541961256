.newsticker {
  max-width: 1180px;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.newsticker__scrolling {
  display: inline-block;
  padding-left: 100%;
  -webkit-animation: marquee 30s linear infinite;
  animation: marquee 30s linear infinite;
}
@-webkit-keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}
@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}