.ui-effects-transfer { border: 2px dotted gray; }

.print-price-poster__custom-btn{
    text-transform: uppercase;
    font-weight: 600;
    padding: 5px 15px;
    font-size: 16px;
    line-height: 1.375;
    display: block;
    border: 3px solid;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    background-image: none;
    white-space: nowrap;
    user-select: none;
}

.print-price-poster__content-separation-line{
    margin-top: 50px;
    margin-bottom: 50px;
    color: black;
    border-top: 1px solid black;
}

.print-price-poster__custom-icon{
    font-size: 28px;
    color: black;
}

.print-price-poster {
    .print-price-poster__submit-buttons{
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
     }

  .global-alerts {
    width: 95%;
  }

  &.is-hidden {
    display: none;
  }
    
  &__lightbulb-icon{
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 50px;
    .print-price-poster__custom-icon{
        font-size: 20px;
    }
  }
    
  &__overlay {
    display: none;
    position: absolute;
    top: 0;
    left:0;
    background:rgba(255,255,255,0.5);
    width: 100%;
    height: 100%;

    &.is-visible {
      display: block;
    }

    .spinner__wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .fa-spinner {
        font-size: 50px;
      }
    }
  }

  &__container {
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 50px;
    background: @WHITE;
  }

  &__layouts {
    height: 32px;
    margin-right: 20px;
    width: 240px;
  }

  &__sizes {
    height: 32px;
    width: 100px;
    &.is-hidden {
      display: none;
    }
  }

  &__select {
    width: 90%;
    height: 32px;

    .select__title {
      font-weight: 700;
    }
  }

  &__form {
    display: inline-block;
    width: 50%;
    margin-bottom: 10px;
    vertical-align: top;
    position: relative;

    &.is-hidden {
      display: none;
    }
  }

  &__input {
    width: 90%;
    height: 32px;
    padding-right: 30px;

    &--texarea {
      height: 90px;
      min-height: 50px;
      padding-right: 40px;
      overflow-y: scroll;
      resize: vertical;

      &--merkmale {
        border-color: @PRIMARY;
        cursor: pointer;
        resize: none;
      }
    }

    &::-ms-clear {
      display: none;
    }
  }

  &__reset {
    padding-left: 5px;
    position: absolute;
    right: 12%;
    top: 30px;

    &:hover {
      cursor: pointer;
    }

    &:after {
      &:extend(.fa);
      content: @fa-var-times;
    }
  }

  &__headline {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
    padding-top: 70px;

    .is-hidden {
      display: none;
    }
  }

  &__button {
    display: inline-block;
    max-width: 300px;
    margin-right: 15px;
    position: absolute;
    right: 51px;
  }
  &__button--centered{
    display: inline-block;
    max-width: 140px;
    margin-left: 15px;
    padding: 3px;
  }
    
  &__archive-button-and-lightbulb{
      display: flex;
      justify-content: space-between;
      .print-price-poster__archive-button-container{
        width: 150px;
        color: white;
        margin-bottom: 10px;

      }
      .print-price-poster__lightbulb-icon{
        position: inherit;
        .print-price-poster__custom-icon{
            font-size: 20px;
        }
      }  
  }
  
    &__button-icon{
        margin-left: 8px;
    }
    
  &__features {
    display: none;

    &.is-visible {
      display: block;
    }
    
    &__form-group {
      &.is-hidden {
        display: none;
      }
    }

    &__form-entry {
      display: flex;
      padding-left: 30px;

      &.is-hidden {
        display: none;
      }
    }

    &__form-entry > label {
      padding-left: 5px;
    }

    &__form-textarea {
      width: 100%;
      height: 110px;
    }

    &__button-group {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0;
    }

    &__button-group--button {
      width: auto;
      margin-left: 5px;
    }
  }
}

@media (max-width: @screen-md) {
  .print-price-poster {

    .print-price-poster__layouts {
      width: 175px;
    }
    .print-price-poster__container {
      height: 90px;

      .print-price-poster__button {
        position: inherit;
        left: 0px;
        top: 40px;
      }
    }
    .print-price-poster__headline {
      padding-top: 100px;
    }

    .print-price-poster__form {
      width: 100%;
    }

    .print-price-poster__features__button-group {
      flex-direction: column;
    }

    .print-price-poster__features__button-group--button {
      margin-bottom: 5px;
      margin-left: 0;
    }
  }
}

.account__print-price-series {
  min-height: 340px;
  width: 100%;
  position: relative;

  .print-price-poster__info {
    margin-bottom: 15px;
  }

  .print-price-series__loading {
    display: none;
    position: absolute;
    width: 100%;
    height: 180px;
    bottom: 40px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;

    &.is-active {
      display: block;
    }

    .spinner__wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform:  translate(-50%, -50%);
      -ms-transform:  translate(-50%, -50%);
      -moz-transform:  translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);

      .fa-spinner {
        font-size: 50px;
      }

      &.is-visible {
        display: block;
      }
    }
  }

  .print-price-poster__container {
    margin: 0 auto;
    position: relative;
    margin-bottom: 30px;
    width: 400px;
    height: auto;
  }
  
  .print-price-poster-size__container {
    display: inline-block;
  }
  
  .print-price-poster-fields__container {
  	.print-price-poster__form{
  	  margin-top: 10px;
    
      input{
        margin-left: 4px;
      }
    }
  }
}



.price-poster-component {
    margin-top: 20px;
    font-family: Arial,Helvetica,sans-serif;
    &__head-and-lightbulb{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .price-poster-component__lightbulb-icon{
            .print-price-poster__custom-icon{
                font-size: 20px;
            }
        }
    }
    
    &__headline-text{
        font-size: 19px;
        font-weight: 700;
        color: @navigation-headline-color;
        text-transform: uppercase;
        text-decoration: none;
        padding-left: 15px;
    }
    &__custom-headline{
        margin-top: 0px;
    }
    &__main-content{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 15px;
        border: solid 1px @price-poster-border-color;
        padding: 5px;
    }
    
    &__fields{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    
    &__main-content .add-to-selection__btn {
        background-color: @price-poster-primary-button-bg-color;
        border-color: @price-poster-primary-button-border-color;
        margin: 9px;
        width: auto;
    }
    
    &__field{
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
        vertical-align: top;
        position: relative;
        .autocomplete{
            top: auto!important;
            right: auto;
            left: auto;
            height: auto!important;
        }
    }
    
    .print-price-poster-fields__container{
        width: 100%;
        .print-price-poster__form {
            width: 100%;
        }
    }
    
    &__images{
        margin-top: 25px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
    
    &__image-container{
        margin: 15px 30px 5px 0;
        border-radius: 2px;
        &:hover {
            .price-poster-component__select-quantity-image-button, .price-poster-component__delete-image-button{
                cursor: pointer;
                display: block;
            }
        }
    }
    
    &__image{
        border: 1px solid @price-poster-image-border;
        height: 180px;
        width: 150px;
    }
    
    &__delete-image-button{
        position: absolute;
        margin-top: -40px;
        margin-left: 60px;
        display: block;
    }
    
    &__select-quantity-image-button{
        position: absolute;
        margin-top: -40px;
        margin-left: 15px;
        display: block;
    }
    
    &__image-description{
        margin: 0 2px 2px 2px;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    &__image-stuck-label{
        margin: 10px 0 5px 0;
    }
    
    &__image-stuck-input{
        width: 30px;
        text-align: right;
    }
    
    &__delete-and-create-buttons{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .price-poster-component__delete-all-button{
            background-color:@price-poster-tertiary-button-bg-color;
            border-color: @price-poster-tertiary-button-border-color;
            width: auto;
        }
    }
    
    &__totalArchives-text{
        margin-right: 7px;
    }
    
    &__create-price-tags {
        position: fixed;
        top: 30%;
        z-index: 2;
        right: 23px;
        width: 288px;
    }
    
    &__create-price-tags .price-poster-component__create-tags__button{
        background-color: @price-poster-primary-button-bg-color;
        border-color: @price-poster-primary-button-border-color;
        width: auto;
    }
    
    &__create-with-crop-marks-checkbox{
        margin-right: 5px;
    }
    
    &__create-mit-schnittmarken-label{
        margin: 0 96px 5px 0;
        display: unset;
    }
    .print-price-poster__overlay{
        z-index: 5;
        .spinner__wrapper {
          &.is-visible {
            position: fixed;
            
          }
        }
    }
}



.archive-search-component{
    font-family: Arial,Helvetica,sans-serif;
    padding: 5px;
    .archive-search-component{
        &__headline-text{
            font-size: 19px;
            font-weight: 700;
            color: @navigation-headline-color;
            text-transform: uppercase;
            text-decoration: none;
            padding-left: 15px;
        }
        &__custom-headline{
            margin-top: 0px;
        }
        &__fields-and-search-button{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: space-between;
            border: solid 1px @price-poster-archive-border-color;
            padding: 5px;
        }

        &__fields-and-search-button .print-price-poster-search__button {
            background-color: @price-poster-archive-primary-button-bg-color;
            border-color: @price-poster-archive-primary-button-border-color;
            margin: 9px;
            width: auto;
        }

        &__fields{
            display: flex;
            flex-wrap: wrap;
            .print-price-poster-search__button{
                margin-top: 53px;
            }
        }

        &__field{
            margin: 0px 35px 9px 0px;
        }
    }
    &__archives-found{
       display: flex;
       flex-wrap: wrap;
       margin-top: 5px;
       margin-bottom: 5px;
       .archives-found__text{
           margin: 0 2px 0 2px;
       }
       .is-hidden {
           display: none;
       }
       .pagination{
            margin: 0;
       }
    }
    &__images{
       margin-top: 15px;
       display: flex;
       flex-wrap: wrap;
       margin-bottom: 20px;
       .selected {
           .archive-search-component__image{
               border: 2px solid @price-poster-archive-selected-border-color;
           }
       } 
   }
   &__image-container{
       margin: 15px 30px 5px 0;
       border-radius: 2px;
       width: 150px;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
       &:hover {
           .archive-search-component__add-image-button, .archive-search-component__delete-image-button, .archive-search-component__select-image-button{
               cursor: pointer;
               display: block;
           }
       } 
   }
   &__image-description{
       margin: 0 2px 2px 2px;
       width: 150px;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
   }
   &__image{
       border: 1px solid @price-poster-archive-image-border;
       height: 180px;
       width: 150px;
   }
   &__delete-image-button{
       position: absolute;
       margin-top: -40px;
       margin-left: 56px;
       display: block;
   }
   &__add-image-button{
       position: absolute;
       margin-top: -40px;
       margin-left: 12px;
       display: block;
   }
   &__select-image-button{
       position: absolute;
       margin-top: -40px;
       margin-left: 100px;
       display: block;  
   }
   &__delete-archives{
       display: flex;
       flex-wrap: wrap;
       margin-top: 5px;
       margin-bottom: 5px;
       .print-price-poster-delete-selected__button{
           margin-top: 5px;
           margin-right: 15px;
           background-color: @price-poster-archive-tertiary-button-bg-color;
           border-color: @price-poster-archive-tertiary-button-border-color;
           width: auto;
       }
       .print-price-poster-delete-all__button{
           margin-top: 5px;
           background-color: @price-poster-archive-tertiary-button-bg-color;
           border-color: @price-poster-archive-tertiary-button-border-color;
           width: auto;
           display: inline-block;
       }
       .price-poster-archive-delete-until-container{
           position: absolute;
           margin-top: -290px;
           top: auto!important;
           width: 380px;
           border: solid 2px;
           padding: 10px;
           background-color: white;
       }
   }
}

@media(min-width: 366px){
    .price-poster-component__create-mit-schnittmarken-label {
        margin: 0 140px 5px 0;
        
    }
    .price-poster-component__create-price-tags{
        right: 30px;
    }
}

@media(min-width: 480px){
    .price-poster-component__select-quantity-image-button, .price-poster-component__delete-image-button{
        cursor: pointer;
        display: none;
    }
    
    .archive-search-component__add-image-button, .archive-search-component__delete-image-button, .archive-search-component__select-image-button{
        cursor: pointer;
        display: none;
    }
}


@media(min-width: 755px){
    .price-poster-component {
        &__field {
            margin-bottom: 10px;
            width: 30%;
        }
        .print-price-poster-fields__container{
            .print-price-poster__form {
                width: 30%;
            }
        }
        &__create-price-tags {
            top: 82%;
        }
    }
    .archive-search-component__fields{
        width: 70%;
    }
}

@media(min-width: 910px){
    .archive-search-component__fields{
        .print-price-poster-search__button{
            margin-top: 0;
        }
    }
}

@media(min-width: 960px){
    .price-poster-component__fields{
        width: 70%;
    }
}

@media(min-width: 1260px){
    .price-poster-component__create-price-tags {
        right: 0;
        left: 72%;
    }
}
@media(min-width: 1346px){
    .price-poster-component__create-price-tags {
        right: 0;
        left: 70%;
    }
}
@media(min-width: 1490px){
    .price-poster-component__create-price-tags {
        right: 0;
        left: 69%;
    }
}
@media(min-width: 1540px){
    .price-poster-component__create-price-tags {
        right: 0;
        left: 68%;
    }
}
@media(min-width: 1625px){
    .price-poster-component__create-price-tags {
        right: 0;
        left: 67%;
    }
}
@media(min-width: 1725px){
    .price-poster-component__create-price-tags {
        right: 0;
        left: 65%;
    }
}
@media(min-width: 1825px){
    .price-poster-component__create-price-tags {
        right: 0;
        left: 65%;
    }
}
@media(min-width: 1925px){
    .price-poster-component__create-price-tags {
        right: 0;
        left: 64%;
    }
}