@maxElements: 20;

.productCompareComponentPointerCell(@i, @j) when(@j > 0){
	.productCompareComponentPointerCell(@i, @j - 1);

	@idx: @i + @j - 1;
	&when(@idx <= @maxElements){
		&:nth-child(@{idx}){
			display: table-cell;
		}
	}
}

.productCompareComponentPointer(@elementsToShow, @i) when (@i > 0){
	.productCompareComponentPointer(@elementsToShow, @i - 1);
	
	&.pointer-@{i} // added line break for lessjs compiler not to error
	{
		.productcompare-element-name-cell,
		.productcompare-element-class-cell,
		.productcompare-element-feature-value-cell{
			.productCompareComponentPointerCell(@i, @elementsToShow);
		}
	}
}

.productcompare-component{
	position: relative;
	margin: 18px 0 0 0;
	font-size: 14px;
	
	&.count-1{
		.productcompare-table-wrapper{
			max-width: 600px;
		}
	}
			
	&.scrollable-next .next-btn,
	&.scrollable-prev .prev-btn{
		display: block;
	}
	
	.product-compare-buttons{
		.clearfix;
		margin: 2px 0 20px 0;

		.btn-compare {
			max-width: 125px;
			float: right;
		}
	}

	.product-compare-collapse-btn,
	.product-compare-back-btn{
		.btn-default();
		padding: 10px 20px;
	}

	.productcompare-table-collapse{
		overflow: hidden;
		position: relative;
		max-height: 100%;

		&.collapsed{
			max-height: 500px;

			&:after{
				display: block;
				position: absolute;
				content: "";
				bottom: 0;
				left: 0;
				height: 40px;
				width: 100%;
				background: linear-gradient(@productCompareComponent-textFadeOutFrom, @productCompareComponent-textFadeOutTo);
			}
		}
	}
	
	.productcompare-more-link{
		margin-top: 15px;
		text-align: center;

		.product-compare-back-btn{
			float: none;
		}
	}
	
	.productcompare-table-wrapper{
		position: relative;
		
		&.sticky-arrows{
			.arrows-wrapper{
				position: fixed;
				left: 20px;
				right: 20px;
				
				@media(min-width: 1240px){
					width: 1180px;
					margin: 0 auto;
				}
			}
		}
					
		&.sticky-arrows-passed{
			.arrows-wrapper{
				position: absolute;
				bottom: 300px;
				top: auto;
				left: 0;
				right: 0;
			}
		}
	}
	
	.arrows-wrapper{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
	}
			
	.arrows-wrapper-footer-waypoint{
		position: absolute;
		width: 100%;
		bottom: 300px;
		height: 0;
	}
	
	.productcompare-element{
		position: relative;
		display: flex;
		flex-direction: column;
		padding-top: 10px;

		.product__info.partial {
			.product__info-code {
				display: block;
				font-weight: 700;
			}
		}
	}
		
	.productcompare-remove-button{
		position: absolute;
		top: -10px;
		right: -20px;
		background-color: transparent;
		border: 0;
		font-size: 25px;
		padding: 10px 20px 10px 10px;
		cursor: pointer;
		line-height: 0;
		color: @SECONDARY;

		&:hover {
			color: @HOVER;
		}
	}
		
	.productcompare-element-image{
		order: 1;
		position: relative;
		display: flex;
		align-items: center;
		order: 1;
		height: 250px;
		flex: 0 0 auto;
		margin-top: 10px;
		
		.primary-image{
			max-width: 100%;
			display: inline-block;
			max-height: 250px;
		}
	}
	
	.productcompare-element-image-link{
		display: inline-block;
		margin: 0 auto;

		img {
			max-width: 100%;
			max-height: 250px;
			display: inline-block;
		}
	}
		
	.product-badges{
		top: -5px;
		
		&.bottom{
			top: auto;
			bottom: -5px;
		}
	}
		
	.productcompare-element-brand{
		order: 2;
		margin: 20px 0 0 0;
		font-weight: lighter;
		font-size: 14px;
		line-height: 1.4em;
	}
	
	.productcompare-element-name{
		order: 3;
		margin: 0;
		font-size: 1.12em;
	}

	.productcompare-element-name-link{
		display: block;
		max-height: 42px;
		overflow: hidden;
	}
		
	.productcompare-element-name-cell{
		border-top: 1px solid @productCompareComponent-border-color;
	}
	
	.productcompare-table{
		border-spacing: 0;
		border-bottom: 1px solid @productCompareComponent-border-color;
		border-right: 1px solid @productCompareComponent-border-color;
		table-layout: fixed;
		width: 100%;

		th {
			color: @FONT-COLOR;
			background-color: @GRAY-LIGHT;
			font-weight: 700;
		}
	}
		
	.productcompare-element-value-table{
		border-spacing: 0;
		table-layout: fixed;
		width: 100%;
	}
	
	.productcompare-element-name-cell,
	.productcompare-element-feature-value-cell,
	.productcompare-element-class-cell{
		padding: 10px 20px;
		border-left: 1px solid @productCompareComponent-border-color;
	}
		
	.productcompare-element-class-cell{
		text-align: left;
		background-color: @productCompareComponent-border-color;
		border-left: 1px solid darken(@productCompareComponent-border-color, 5%);
		
		&:first-child{
			border-left-color: @productCompareComponent-border-color;
		}
	}
		
	.productcompare-element-image{
		position: relative;
	}
	
	.productcompare-element-table-value-cell{
		padding-left: 20px;
	}
		
	.productcompare-element-table-value-name-cell,
	.productcompare-element-table-value-cell{
		vertical-align: top;
	}
	
	.productcompare-element-feature-value-cell{
		padding-top: 0;
		padding-bottom: 5px;
		
		&.first{
			padding-top: 10px;
		}

		&.last{
			padding-bottom: 10px;
		}
	}
	
	.productcompare-element-name-cell,
	.productcompare-element-class-cell,
	.productcompare-element-feature-value-cell{
		width: 100%;
		display: none;
	}
	
	.next-btn,
	.prev-btn{
		display: none;
		position: absolute;
		top: 150px;
		margin: -25px 0 0 0;
		width: 25px;
		height: 50px;
		right: 0;
		text-indent: -99999px;
		cursor: pointer;
		padding: 0;
		border: 0;
		background-color: transparent;
		outline: 0;
		z-index: 50;
		
		&:before,
		&:after{
			display: block;
			position: absolute;
			content: '';
			text-indent: 0;
			background-color: @productCompareComponent-arrows-color;
			width: 1px;
			height: 35px;
		}
			
		&:before{
			top: -5px;
		}
			
		&:after{
			bottom: -5px;
		}
	
		&:hover{
			&:before,
			&:after{
				color: darken(@productCompareComponent-arrows-color, 10%);
			}
		}
	}
	
	.next-btn{
		right: -15px;
	
		&:before,
		&:after{
			left: 12.5px;
		}
			
		&:before{
			transform: rotate(-45deg);
		}
			
		&:after{
			transform: rotate(45deg);
		}
	}
	
	.prev-btn{
		left: -15px;
		
		&:before,
		&:after{
			right: 12.5px;
		}
		
		&:before{
			transform: rotate(45deg);
		}
			
		&:after{
			transform: rotate(-45deg);
		}
	}

	.simple-banner-printer {
		display: none; // this banner shown only in printer. See printer.less
	}

	@media(min-width: 510px){
		.product-compare-back-btn{
			float: right;
		}
	}
	
	@media(max-width: 799px){
		.productCompareComponentPointer(1, @maxElements);
	}

	@media(min-width: 800px) and (max-width: 1239px){
		.productCompareComponentPointer(2, @maxElements);
	}

	@media(min-width: 1240px){
		.productCompareComponentPointer(3, @maxElements);
	}
	
	@media(min-width: 1300px){
		.next-btn{
			right: -35px;
		}
	
		.prev-btn{
			left: -35px;
		}
	}
}