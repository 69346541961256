.teaserComponent{
	margin-top: 18px;
	font-size: 14px;
	
	&.linkLayout-WIDE{
		.teaserComponentLink{
			border-bottom: 1px solid @teaserLinkBorder;

			&:last-of-type{
				border: none;
			}

			a{
				display: block;
				padding: 8px 2px;
				color: @teaserLinkColor;

				&:hover{
					background: @teaserLinkHoverBackground;
					color: @teaserLinkHoverColor;
				}
			}
		}
	}
	
	.teaserComponentHeaderLink,
	.teaserComponentBodyLink{
		text-decoration: none;
		color: inherit;
		
		&:hover{
			text-decoration: underline;
		}
	}
	
	.teaserComponentMediaLink{
		display: block;
		line-height: 0;
		transform-style: preserve-3d;
		overflow: hidden;
		
		&:hover{
			.teaserComponentMedia{
				transform: scale(1.05);
			}
		}
	}
	
	.teaserComponentMedia{
		display: block;
		width: 100%;
		height: auto;
		transition: transform 150ms ease-out;
	}
	
	.teaserComponentTitle{
		font-size: 1.266em;
	}
		
	.teaserComponentBody{
		font-weight: lighter;
	}
	
	.teaserComponentTitle,
	.teaserComponentBody,
	.teaserComponentLinks{
		margin: 15px 0 0 0;
		padding: 0 15px;
	}
		
	.teaserComponentLink{
		&:first-child{
			margin-top: 0;
		}
	}
	
	.teaserComponentLinks{
		list-style-type: none;
	}
}