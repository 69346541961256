.product-compare-dialog {
	position: relative;
	margin-top: 18px;
	border-bottom: 2px solid @product-list-border;
	font-size: 14px;
	
	&,
	.product-compare-footer {
		.clearfix();
	}
	
	&.is-empty{
		display: none;
	}
	
	&.only-one{
		.product-compare-footer {
			display: none;
		}
	}
	
	.product-compare-headline {
		font-weight: bold;
		font-size: 18px;
		color: @productCompareDialog-headline-color;
		text-transform: uppercase;
		margin: 0;
		padding: 8px 0;
	}
	
	.product-compare-items {
		display: flex;
		justify-content: flex-start;
		width: calc(~"100% + 40px");
		position: relative;
		left: -20px;
	}
		
	.product-compare-item {
		position: relative;
		flex: 0 0 auto;
		width: 33.33333%;
		padding: 20px;
		display: flex;
		flex-direction: column;
		
		&:before {
			display: block;
			content: '';
			position: absolute;
			left: 0;
			top: 20px;
			bottom: 20px;
			width: 1px;
			background-color: @productCompareDialog-border-color;
		}
		
		&:after {
			display: block;
			content: '';
			position: absolute;
			right: 0;
			top: 20px;
			bottom: 20px;
			width: 1px;
			background-color: @productCompareDialog-border-color;
		}
			
		&:first-child {
			&:before {
				display: none;
			}
		}
					
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
	
	.product-compare-image-link {
		display: block;
		width: 100%;

		img {
			max-width: 100%;
			display: inline-block;
			max-height: 50px;

		}
	}
	
	.productcompare-remove-button {
		position: absolute;
		top: 10px;
		right: 10px;
		background-color: transparent;
		border: 0;
		font-size: 20px;
		cursor: pointer;
		color: @SECONDARY;

		&:hover {
			color: @HOVER;
		}
	}
	
	.product-compare-image {
		text-align: center;
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		justify-content: space-around;

		img {
			max-width: 100%;
			display: inline-block;
			max-height: 50px;
		}
	}
	
	.product-compare-footer {
		margin-top: 10px;
		padding-bottom: 20px;
		text-align: center;
	}
}

.compare__checkbox {
	display: inline;

	.product-compare {
		display: inline;

		.controls {
			&.checkbox {
				display: inline;
			}
		}
	}
}

.isProductCompareEnabledCheckbox {
	display: none;
}

.control-label {
	&.checkbox {
		display: none;
	}
}



@media(min-width: 450px) {
	.product-compare-dialog-component {
		.product-compare-body {
			display: flex;
			align-items: flex-end;
			margin: 8px 0 8px 0;
		}
			
		.product-compare-text {
			flex: 0 0 auto;
		}
			
		.product-compare-items {
			flex: 0 1 auto;
			width: 210px;
			left: 0;
			margin-left: 10px;
		}
			
		.product-compare-item {
			padding: 0 8px;
			
			&:before,
			&:after{
				top: 0;
				bottom: 0;
			}
		}
				
		.productcompare-remove-button {
			top: -14px;
			right: -2px;
		}
			
		.product-compare-footer {
			margin-top: 18px;
		}
	}
}
			
@media(min-width: 600px) {
	.product-compare-dialog-component {
		.product-compare-body-footer-wrapper {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
		}
		
		.product-compare-body {
			flex: 1 1 auto;
			margin: 0;
		}
		
		.product-compare-footer {
			display: flex;
			align-items: flex-end;
			flex: 0 0 auto;
			margin: 0 0 0 20px;
			padding: 0;
		}
	}
}

@media(max-width: 600px)  {
	.productcompare-component {
		.product-compare-buttons {
			margin: 20px auto;
			text-align: center;
			width: 285px;
		}
	}

	.productcompare-element {
		&:before,
		&:after {
			width: 0;
		}

	}
}