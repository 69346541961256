.container-component{
	margin-top: 18px;
	
	&.layoutFormat-Invisible{
		margin-top: 0;
	}
	
	.banner-component{
		margin-top: 18px;
		img{
			width: 100%;
			height: auto
		}
		
		.banner-link{
			display: block;
			line-height: 0;
			transform-style: preserve-3d;
			overflow: hidden;
			
			&:hover{
				.banner-image{
					transform: scale(1.05);
				}
			}
		}
			
		.banner-image{
			display: block;
			width: 100%;
			height: auto;
			transition: transform 150ms ease-out;
		}
		
		.banner-title{
			display: none;
		}
		
		.banner-content.content{
			margin-top: 5px;
			font-size: 0.9em;
		
			>*{
				&:first-child{
					margin-top: 0;
				}
			
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
}

.containerComponentChildren{
	&:extend(.clearfix all);
}

@cellDist: 20px;

@row2sd: @cellDist / 2;
@row2Width: 100% / 2;


.details.container-component{
	&,
	& > .summary{
		display: block;
	}
}


.container-component{
	&.layoutFormat-Compact,
	&.layoutFormat-Compact4:not(.hasTitle){
		.containerComponentChild{
			padding-top: 10px;
		}
	}

	&.layoutFormat-Default,
	&.layoutFormat-Dynamic,
	&.layoutFormat-Compact4,
	&.layoutFormat-Compact,
	&.layoutFormat-Collapsible,
	&.layoutFormat-Collapsible_Open,
	&.layoutFormat-Collapsible_Left,
	&.layoutFormat-Collapsible_Left_Open{
		&.hasTitle{
			border-top: 1px solid @container-component-border-color;
		
			.containerComponentHeader{
				padding: 3px 0;
			}
		}
	
		.containerComponentTitle{
			margin: 0;
			font-weight: bold;
			font-size: 18px;
			text-transform: uppercase;
			padding: 5px 0;
		}
	}

	&.layoutFormat-Default{
		.containerComponentChild:first-child{
			>.component:first-child{
				margin-top: 0;
			}
		}
	}
	
	&.layoutFormat-Dynamic{
		.containerComponentChild{
			display: none;
			
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8),
			&:nth-child(9),
			&:nth-child(10){
				display: block;
			}
		}
	}
	
	&.layoutFormat-Compact4{
		.containerComponentChild{
			float: left;
			width: 50%;
				
			&:first-child,
			&:nth-child(2){
				>.component:first-child{
					margin-top: 0;
				}
			}
		}
	}
	
	&.layoutFormat-Collapsible,
	&.layoutFormat-Collapsible_Open,
	&.layoutFormat-Collapsible_Left,
	&.layoutFormat-Collapsible_Left_Open{
		display: block;
		>.containerComponentHeader{
			>.containerComponentTitle{
				text-transform: inherit;
			}
		}
	
		> .containerComponentBody{
			display: none;
		}
		
		&.open{
			&.hasTitle{
				>.containerComponentHeader{
					background-color: lighten(@container-component-border-color, 4%);
						
					&:after{
						transform: rotate(-90deg);
					}
				}
					
				> .containerComponentBody{
					display: block;
				}
			}
		}
				
		>.summary{
			display: block;
			cursor: pointer;
			outline: none;
		}
		
		&.hasTitle{
			>.containerComponentHeader{
				position: relative;
				background-color: @container-component-border-color;
				padding-left: 10px;
				padding-right: 30px;
				
				&:before{
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 1px;
					background-color: @container-component-border-color;
					display: block;
					content: '';
				}
				
				&:after{
					&:extend(.fas);
					position: absolute;
					right: 0;
					top: 0;
					content: @fa-var-caret-left;
					width: 30px;
					line-height: 37px !important;
					text-align: center;
					transition: transform 0.3s ease-in-out;
				}
			}
		}
	}
	
					
	&.layoutFormat-Collapsible_Left,
	&.layoutFormat-Collapsible_Left_Open{
		&.open{
			&.hasTitle{
				>.containerComponentHeader{
					&:after{
						transform: rotate(90deg);
					}
				}
			}
		}
	
		&.hasTitle{
			>.containerComponentHeader{
				padding-left: 30px;
				padding-right: 10px;
					
				&:after{
					right: auto;
					left: 0;
					content: @fa-var-caret-right;
				}
			}
		}
	}
					
	.containerComponentLinks{
		margin: 10px 0;
		&:extend(.clearfix all);
		font-size: 14px;
		
		a[href]{
			.btn;
			.btn-default;
			text-transform: uppercase;
			padding: 10px 10px;
			margin-top: 5px;
		}
	}
}

@media(max-width: 719px){
	.container-component{
		&.layoutFormat-Compact4{
			.containerComponentChild{
				&:nth-child(2n){
					padding-left: 10px;
				}	
				
				&:nth-child(2n + 1){
					padding-right: 10px;
					clear: both
				}
			}
		}
	}
}

@media(min-width: 500px){
	.container-component{
		&.layoutFormat-Compact{
			.containerComponentChildren{
				display: flex;
				flex-wrap: wrap;
				align-items: stretch;
			}
				
			.containerComponentChild{
				display: flex;
				align-items: stretch;
				
				>*{
					flex: 1 1 auto;
				}
			}
		}
	}
}


@media(min-width: 500px) and (max-width: 699px){
	.container-component{
		&.layoutFormat-Compact{
			.containerComponentChild{
				width: 50%;

				&:nth-child(2n + 1){
					padding-right: 5px;
				}
					
				&:nth-child(2n){
					padding-left: 5px;
				}
				
				&:first-child,
				&:nth-child(2){
					padding-top: 0;
				}
			}
		}

		&.layoutFormat-Dynamic{
			&.hasTitle{
				.containerComponentChild{
					&:first-child,
					&:nth-child(2){
						>.component:first-child{
							margin-top: 0;
						}
					}
				}
			}
						
			.containerComponentChild{
				float: left;
				width: @row2Width;
				
				&:nth-child(2n + 1){
					padding-right: @row2sd;
					clear: both;
				}
				
				&:nth-child(2n){
					padding-left: @row2sd;
				}
			}
		}
	}
}


@row3sd: @cellDist - (20px / 3);
@row3sm: @cellDist / 3;
@row3Width: 100% / 3;

.containerDynamicRow2ForFirstRow(@startIdx, @firstRow) when (@firstRow) {
	@nextIdx: @startIdx + 1;
	.containerComponentChild{
		&:nth-child(@{startIdx}),
		&:nth-child(@{nextIdx}){
			>.component:first-child{
				margin-top: 0;
			}
		}
	}
}

.containerDynamicRow2(@startIdx, @firstRow: false){
	@nextIdx: @startIdx + 1;
	.containerComponentChild{
		&:nth-child(@{startIdx}){
			padding-right: 10px;
			width: 50%;
			clear: both;
		}
				
		&:nth-child(@{nextIdx}){
			padding-left: 10px;
			width: 50%;
		}
	}
	
	.containerDynamicRow2ForFirstRow(@startIdx, @firstRow);
}

.containerDynamicRow3ForFirstRow(@startIdx, @firstRow) when (@firstRow) {
	@idxPlus1: @startIdx + 1;
	@idxPlus2: @startIdx + 2;
	.containerComponentChild{
		&:nth-child(@{startIdx}),
		&:nth-child(@{idxPlus1}),
		&:nth-child(@{idxPlus2}){
			>.component:first-child{
				margin-top: 0;
			}
		}
	}
}

.containerDynamicRow3(@startIdx, @firstRow: false){
	@idxPlus1: @startIdx + 1;
	@idxPlus2: @startIdx + 2;
	.containerComponentChild{
		&:nth-child(@{startIdx}){
			padding-right: @row3sd;
			width: @row3Width;
			clear: both;
		}
			
		&:nth-child(@{idxPlus1}){
			padding-left: @row3sm;
			padding-right: @row3sm;
			width: @row3Width;
		}
				
		&:nth-child(@{idxPlus2}){
			padding-left: @row3sd;
			width: @row3Width;
		}
	}
	
	.containerDynamicRow3ForFirstRow(@startIdx, @firstRow);
}

.container-component{
	&.layoutFormat-Compact{
		overflow: hidden;
		.containerComponentChild{
			padding-top: 0;
			.layout-format-BOX{
				display: flex;
				align-items: stretch;
				flex: 1 1 auto;
				border: 1px solid @GRAY-LIGHT;
				padding: 20px;
				margin: 0 0 10px;
				.banner-link{
					line-height: 0;
					transform-style: preserve-3d;
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					flex: 1 1 auto;
					width: 100%;
					max-height: 100%;
					vertical-align: middle;
					text-align: center;
					
					&:hover{
						.banner-image{
							transform: none;
						}
					}
				}
			}
		}
	}
}



@cellDist10: 10px;
@row3sd10: @cellDist10 - (10px/3);
@row3sm10: @cellDist10 / 3;



@media(min-width: 700px){
	.container-component{
		&.layoutFormat-Dynamic{
			.containerComponentChild{
				float: left;
			}
			
			&.container-children-count1,
			&.container-children-count2{
				.containerDynamicRow2(1, true);
			}
			
			&.container-children-count3{
				.containerDynamicRow3(1, true);
			}
			
			&.container-children-count4{
				.containerDynamicRow2(1, true);
				.containerDynamicRow2(3);
			}
			
			&.container-children-count5{
				.containerDynamicRow2(1, true);
				.containerDynamicRow3(3);
			}	
			
			&.container-children-count6{
				.containerDynamicRow3(1, true);
				.containerDynamicRow3(4);
			}
			
			&.container-children-count7{
				.containerDynamicRow2(1, true);
				.containerDynamicRow2(3);
				.containerDynamicRow3(5);
			}
				
			&.container-children-count8{
				.containerDynamicRow2(1, true);
				.containerDynamicRow3(3);
				.containerDynamicRow3(6);
			}
				
			&.container-children-count9{
				.containerDynamicRow3(1, true);
				.containerDynamicRow3(4);
				.containerDynamicRow3(7);
			}
				
			&:not(.container-children-count1):not(.container-children-count2):not(.container-children-count3):not(.container-children-count4):not(.container-children-count5):not(.container-children-count6):not(.container-children-count7):not(.container-children-count8):not(.container-children-count9){
				.containerDynamicRow2(1, true);
				.containerDynamicRow3(3);
				.containerDynamicRow2(6);
				.containerDynamicRow3(8);
			}
		}
	}				
}

@media(min-width: 720px){
	.container-component{
		&.layoutFormat-Compact4{
			overflow: hidden;
			.containerComponentChildren{
				width: ~'calc(100% + 20px)';
			}

			.containerComponentChild{
				position: relative;
				left: -10px;
				padding-left: 10px;
				padding-right: 10px;
				width: 25%;
				
				&:nth-child(3),
				&:nth-child(4){
					>.component:first-child{
						margin-top: 0;
					}
				}
				
				&:nth-child(4n + 1){
					clear: both
				}
			}
		}
						
		.containerComponentPrimLinks{
			float: left;
			padding-right: 15px;
			max-width: 50%;
		}
		
		.containerComponentSecLinks{
			float: right;
			padding-left: 15px;
			max-width: 50%;
		}
	}
}

@media(max-width: 500px){
	.container-component{
		&.layoutFormat-Compact{
			.containerComponentChild{
				max-width: 236px;
				margin: 0 auto;
			}
		}
	}
}

@media(min-width: 700px){
	.container-component{
		&.layoutFormat-Compact{
			.containerComponentChild{
				width: 33.33333%;
				&:nth-child(3n+1) {
					padding-right: 6.66667px;
				}
				&:nth-child(3n+2) {
					padding-left: 3.33333px;
					padding-right: 3.33333px;
				}
				&:nth-child(3n+3) {
					padding-left: 6.66667px;
				}
			}
		}
	}
}

@media(min-width: 1000px){
	.container-component{
		&.layoutFormat-Compact{
			.containerComponentChild{
				width: 25%;
				&:nth-child(4n+1) {
					padding-right: 5px;
					padding-left: 0;
				}
				&:nth-child(4n+2),
				&:nth-child(4n+3) {
					padding-left: 3.5px;
					padding-right: 3.5px;
				}
				&:nth-child(4n+4) {
					padding-left: 5px;
					padding-right: 0;
				}
			}
		}
	}
}
@media(min-width: 1240px){
	.container-component{
		&.layoutFormat-Compact{
			.containerComponentChild{
				width: 20%;

				&:nth-child(5n+1) {
					padding-right: 5px;
					padding-left: 0;
				}
				&:nth-child(5n+2),
				&:nth-child(5n+3),
				&:nth-child(5n+4) {
					padding-left: 3.5px;
					padding-right: 3.5px;
				}
				&:nth-child(5n+5) {
					padding-left: 5px;
					padding-right: 0;
				}
			}
		}
	}
}