﻿.colorbox {}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
#colorbox,
#cboxWrapper {
  position:absolute;
  top:0;
  left:0;
  z-index:9999;
  overflow:hidden;
}

#cboxOverlay {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: #000;
  overflow:hidden;
  z-index: 9999;
}

#cboxContent{
  background:#fff;
  overflow:visible;
  position:relative;
}

#cboxLoadedContent {
  box-sizing:content-box;
  -moz-box-sizing:content-box;
  -webkit-box-sizing:content-box;

  overflow:auto;
  -webkit-overflow-scrolling: touch;
  padding: 50px 15px 15px 15px;

  &.is-loading {
    background: gray;
  }
}


#cboxClose{
  position: absolute;
  right: 15px;
  top: 12px;
  background: transparent;
  border: 0 none;
  color: @SECONDARY;
  padding: 0;
  font-size: 25px;

  &:active,
  &:hover {
    color: @HOVER;
    cursor: pointer;
  }
}

#cboxTitle {
  position: absolute;
  top: 0;
  height: 50px;
  width: 100%;
  margin: 0;
  background: #fff;

  .headline {
    font-size: 18px;
    font-weight: 700;
    padding: 15px;
  }
}

.modal__top {
  padding-bottom: (@grid-gutter-width + 12);
  margin-bottom: (@grid-gutter-width + 10);
  border-bottom: 1px solid @border-color;
}

.modal__top .modal__top--label {
  color: @text-color;
  font-weight: bold;
  font-style: italic;
}

.modal__top .modal__top--text {
  font-style: italic;
}

.modal__text--bold {
  font-weight: bold;
}

.modal__bottom {
  margin-bottom: (@grid-gutter-width * 2);
  font-size: 15px;
}

.colorbox__message {
  margin-top: 5px;
  margin-bottom: 10px;
}

.ui-front{z-index:0 !important; }
.ui-selectmenu-open {z-index:9999 !important;}

.saved-cart__modal {
  .ui-selectmenu-icon {
    &.ui-icon {
      text-indent: 1px;
      position: initial;

      &.ui-icon-triangle-1-s {
        &:after {
          &:extend(.fa);
          content: @fa-var-angle-down;
          font-size: 17px;
          position: absolute;
          right: 8px;
          top: 5px;
        }
      }
    }
  }
  .ui-selectmenu-button {
    margin-bottom: 15px;

    &.ui-button {
      background: none;
      border: 1px solid @GRAY;
      border-radius: 0;

      &:active {
        color: @FONT-COLOR;
      }
    }
  }
}


.make-scrolling {
  overflow-y: scroll;
  height: 150px;
}
