// Load core variables and mixins
// --------------------------------------------------
@import "../../bootstrap-3.3.7/less/bootstrap.less";
@import "vendor.less";

@import "variables.less";

@import "global.less";
@import "footer.less";
@import "header.less";

@import "mixins.less";

@import "buttons";

@import "colorbox.less";

//// replace bootstrap defaults
@import "login.less";

@import "accordion.less";

@import "account-vacant-positions.less";

@import "overlay-image-slider.less";

@import "pagination.less";

@import "autocomplete.less";

@import "search-empty.less";

@import "compare.less";

@import "breadcrumb.less";

@import "product-campaign.less";

@import "pdp.less";

@import "image-gallery.less";

@import "addtocart-component.less";

@import "price-print.less";

@import "banner.less";

@import "tabs.less";

@import "carousel.less";

@import "components.less";

@import "product-list.less";

@import "product-grid.less";

@import "different-delivery-address-form.less";

@import "product-facet.less";

@import "pagination-bar";

@import "item-list.less";

@import "cart.less";

@import "cms-search.less";

@import "overlay-widget.less";

@import "seeking-feature.less";

@import "progressionBar.less";

@import "addressbook.less";

@import "checkout.less";

@import "coupon.less";

@import "newsticker.less";

@import "storefinder.less";

@import "inlineLayer.less";

@import "price.less";

@import "legalModal.less";

@import "account";

@import "retoure.less";

@import "modal.less";

@import "quick-view-popup.less";

@import "tables.less";

@import "form-elements.less";

@import "coupon.less";

@import "faq.less";

@import "landingLayout2page.less";

@import "teaser.less";

@import "listing.less";

@import "language-selector.less";

@import "order-administration.less";

@import "order-administration-new.less";

@import "energy-label.less";

@import "upload.less";

@import "ellipsis.less";

@import "termsOfUse.less";

//Cms
@import "cms/cms.less";

//Partials
@import "partials/_partials.less";

// multi-d
@import "order-form.less";

@import "quick-order.less";

@import "csv-import.less";

// fonts
@import "fonts/fonts.less";

// font awsome
@import "fontawsome/fontawesome.less";
@import "fontawsome/regular.less";
@import "fontawsome/solid.less";

// noUiSlider
@import "noUiSlider/nouislider.less";
@import "noUiSlider/nouislider.base.less";
@import "noUiSlider/nouislider.core.less";
@import "noUiSlider/nouislider.pips.less";
@import "noUiSlider/nouislider.tooltips.less";

//slick
@import "slick/slick-theme.less";

//Cms
@import "widgets/widgets.less";


.skip, .skiptocontent, .skiptonavigation{
	.sr-only;
}

//smartedit
@import "smartedit.less";


// print
// @import "print.less";
