.compare__checkbox {
    display: inline;

    .product-compare {
        display: inline;

        .controls {
            &.checkbox {
                display: inline;
            }
        }
    }
}

.isProductCompareEnabledCheckbox {
    display: none;
}

.control-label {
    &.checkbox {
        display: none;
    }
}

