@itemWidth: 1220px/3;
@item2Width: @itemWidth+@itemWidth;
@item3Width: @itemWidth+@item2Width;

@size2StartWidth: @item2Width + 60px;
@size1MaxWidth: @size2StartWidth - 1px;

@size3StartWidth: @item3Width + 60px;
@size2MaxWidth: @size3StartWidth - 1px;

.teaserlist-component {
  margin-top: 18px;

  .teaserlist-header,
  .teaserlist-footer {
    padding: 5px 0;
    font-size: 14px;

    .paginator {
      a[href] {
        color: #fff;
        will-change: color;
        transition: color 0.05s;
      }
    }

    .pagination-items {
      text-align: center;
      list-style-type: none;
      padding: 0;
      max-width: 150px;
      margin: 0 auto;
      display: flex;

      .pagination-item {
        display: inline-block;
        flex: 1 1 auto;
      }
    }

    .pagination-item-number-link,
    .pagination-item-link,
    .pagination-item-number-wrapper {
      display: block;
      padding: 5px 3px;
    }
  }


  .teaserlist-items {
    margin: 0 auto;
    border-top: 2px solid @teaser-list-border-color;
    display: flex;
    flex-wrap: wrap;
  }


  .teaserlist-item {
    position: relative;
    padding: 20px;

    >*:first-child {
      margin-top: 0;
    }

    &:before,
    &:after {
      position: absolute;
      top: 20px;
      bottom: 20px;
      width: 1px;
      background-color: @teaser-list-bg;
      display: block;
      content: '';
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    .teaserComponent {
      &:before {
        position: absolute;
        bottom: 0;
        height: 1px;
        left: 20px;
        right: 20px;
        background-color: @teaser-list-bg;
        display: block;
        content: '';
      }
    }
  }
}


@media (max-width: @size1MaxWidth) {
  .teaserlist-component {
    .teaserlist-items {
      max-width:  @itemWidth;
    }

    .teaserlist-item {
      &:last-child {
        .teaserComponent {
          &:after {
            position: absolute;
            bottom: 0;
            height: 1px;
            left: 20px;
            right: 20px;
            background-color: @teaser-list-bg;
            display: block;
            content: '';
          }
        }
      }
    }
  }
}




@media(max-width: 1279px) and (min-width: 873.33333px) {
  .teaserlist-component {
    .teaserlist-item {
      width: 50%;

      &:nth-child(2n + 1) {
        &:after {
          display: none;
        }
      }
    }
  }
}

@media(min-width: 1280px) {
  .teaserlist-component {
    overflow: hidden;
    width: 100%;

    .teaserlist-items {
      position: relative;
      width: 100%;
    }

    .teaserlist-item {
      width: 33.333333333%;

      &:nth-child(3n + 1),
      &:nth-child(3n + 2) {
        &:after {
          display: none
        }
      }
    }
  }
}