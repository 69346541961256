.product__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 35% 32% 33%;
  grid-template-columns: 35% 32% 33%;
  grid-template-areas: "product__image product__info product__icons" "product__image product__prices product__delivery" "product__image product__links product__cta";
  grid-auto-rows: minmax(50px, auto);
  grid-row-gap: 10px;
  min-height: 240px;
  padding: 15px 0;
  border-bottom: 2px solid @product-list-border;

  .product__image {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 5;
    grid-row-end: span 5;
    -ms-grid-column: 1;
    grid-column-start: 1;
    grid-area: product__image;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;

    .product__image-thumb {
      width: 100%;
      text-align: center;
      display: inline-block;
      margin: 0 auto;

      .image {
        max-height: 200px;
        max-width: 100%;
        display: inline-block;
        padding: 10px;
      }
    }
  }

  .product__delivery.partial {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 3;
    grid-column-start: 3;
    padding-right: 10px;
    grid-area: product__delivery;
  }

  .product__icons {
    padding-right: 10px;
  }

  .product__prices {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 2;
    grid-column-start: 2;
    grid-area: product__prices;

    .price__label {
      width: 100px;
      display: inline-block;
    }

    .price__wrapper {
      margin-bottom: 4px;

      .price {
        &.price--new {
          font-weight: 700;
          color: @price-new-color;
        }

        &.price--strike {
          text-decoration: line-through;
        }
      }
    }
  }

  .product__code-icon {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column: 3;
    grid-column-start: 3;
    grid-area: product__icons;

    .product__code {
      font-weight: 700;
      text-align: right;
    }
  }

  .product__info.partial .product__info-color::before {
    content: " - ";
  }

  .product__links {
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-column: 2;
    grid-column-start: 2;
    grid-area: product__links;
    display: -ms-flex;
    display: flex;
    align-items: flex-end;

    .product__link {
      display: -ms-flex;
      display: flex;
      align-items: flex-end;
      justify-content: space-evenly;
      width: 50%;

      .webcomponent-component {
        margin: 0;
      }
    }

    .product__salesUnit {
      margin-left: 10%;
      padding-bottom: 20px;
    }
  }

  .product__cta {
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-column: 3;
    grid-column-start: 3;
    grid-area: product__cta;
    display: -ms-flex;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    min-height: 60px;

    > .btn.btn-small {
      position: relative;
      align-self: flex-end;
      min-height: 56px;

      &,
      .add2Cart__text,
      .fa-shopping-cart,
      .spinner__wrapper {
        line-height: 28px;
      }

      .fa-shopping-cart,
      .spinner__wrapper {
        min-width: 30px;
        font-size: 22px;
        font-weight: 900;
      }
    }
  }

  .product__info.partial {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column: 2;
    grid-column-start: 2;
    grid-area: product__info;

    .product__info-code {
      display: none;
    }
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .product__list {
    -ms-grid-columns: 30% 35% 35%;
    grid-template-columns: 30% 35% 35%;
    position: relative;

    .product__image {
      .product__image-thumb {
        .image {
          max-width: 160px;
        }
      }
    }

    .product__prices {
      &.partial {
        .price__wrapper {
          .price,
          .price__label {
            font-size: 12px;
          }
        }
      }
    }

    .product__delivery {
      &.partial {
        .delivery__container {
          .delivery__label {
            width: 35%;
            font-size: 12px;
          }

          .delivery__info {
            font-size: 12px;
          }

          .delivery__price {
            font-size: 12px;
          }
        }
      }
    }

    .product__cta {
      .product__add2cart {
        .add2Cart__text {
          font-size: 11px;
        }

        .icon {
          font-size: 12px;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .product__list {
    .product__links {
      .product__salesUnit {
        padding-bottom: 5px;
      }
    }
  }
}

@media (min-width: 700px) {
  .product__list {
    .product__links {
      .product__salesUnit {
        margin-left: 15%;
      }
    }
  }
}

@media (min-width: 900px) {
  .product__list {
    .product__links {
      .product__salesUnit {
        margin-left: 20%;
      }
    }
  }
}

@media (min-width: 1200px) {
  .product__list {
    .product__links {
      .product__salesUnit {
        margin-left: 25%;
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .product__list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    grid-template-areas: "product__image " "product__info" "product__icons" "product__prices" "product__delivery" "product__links" "product__cta";
    grid-row-gap: 0;
    grid-auto-rows: auto;
    margin: 15px 0;
    border-bottom: none;
    position: relative;
    padding: 15px;

    &:after {
      content: "";
      width: 100%;
      border-bottom: 2px solid @product-list-border;
      position: relative;
      top: 15px;
    }

    .product__code-icon {
      .product__code {
        text-align: left;
      }
    }

    .product__delivery {
      &.partial {
        padding-right: 0;
      }
    }

    .product__info {
      &.partial {
        padding-top: 15px;
      }
    }


    .product__icons {
      margin: 5px 0;

      &.partial {
        text-align: left;

        .icon {
          margin-left: 0;
          margin-right: 5px;
        }

      }
    }

    .product__prices {
      .price__wrapper {
        .price {
          float: right;
        }
      }
    }

    .product__links {
      .product__link {
        position: relative;
        padding: 15px 0 15px 0;
      }
    }

    .product__cta {
      min-height: initial;

      > .btn.btn-small {
        max-width: 100%;
      }

      .product__add2cart {
        position: inherit;
        right: auto;
        margin: 0 auto;
        width: 90%;

        .add2Cart__text {
          font-size: 15px;
        }
      }

      &.webcomponent-component {
        display: block;
      }
    }
  }

  .product__cta {

    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 2px;
      height: calc(~"100% - 20px");
      background: @product-list-border;
    }

    &:after {
      left: unset;
      right: 0;
    }
  }

  .product__image {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: 1;
    grid-column-start: 1
  }

  .product__delivery.partial {
    -ms-grid-row: 9;
    grid-row-start: 9;
    -ms-grid-column: 1;
    grid-column-start: 1
  }

  .product__prices {
    -ms-grid-row: 7;
    grid-row-start: 7;
    -ms-grid-column: 1;
    grid-column-start: 1
  }

  .product__code-icon {
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-column: 1;
    grid-column-start: 1
  }

  .product__links {
    -ms-grid-row: 11;
    grid-row-start: 11;
    -ms-grid-column: 1;
    grid-column-start: 1
  }

  .product__cta {
    -ms-grid-row: 13;
    grid-row-start: 13;
    -ms-grid-column: 1;
    grid-column-start: 1
  }

  .product__info.partial {
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column: 1;
    grid-column-start: 1
  }
}

.spinner__wrapper {
  display: none;


  &.is-visible {
    display: block;
  }

  i {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}