@media (min-width: @screen-md) {
.order-administration {
	.orders {
		.advanced-search-message__noResults{
			margin-top: 14px;
		}
	}

  .order {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;

    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;

    font-size: 12px;
    padding: 15px 0;

    border-bottom: 1px solid @GRAY-LIGHT;

    .order__left {
      -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;

      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;

      width: 15%;
      padding-right: 10px;

      .product__icons.partial {
        text-align: center;
      }

      .order__image {
        display: inline-block;
        margin: 0 auto;
        width: 100%;
        text-align: center;

        img {
          max-width: 100%;
          display: inline-block;
          max-height: 120px;
        }
      }

      .order__code {
        text-align: center;
        display: block;
        font-weight: 700;
        margin-top: 10px;
        font-size: 14px;
      }

      .order__icons {
        margin-top: 10px;
        text-align: center;

        .link-print-price-modal-button{
           border: none;
           background-color: transparent;
           padding: 0;
         }

        .addtocart__wrapper {
          display: inline-block;
          padding: 0 5px 0 1px;

          &:hover {
            cursor: pointer;
          }
          form {
            display: inline-block;
          }

          .button__add-to-cart {
            font-size: 20px;
            color: @SECONDARY;
            width: 23px;
            display: inline-block;

            &:hover {
              cursor: pointer;
              color: @SECONDARY-BUTTON-HOVER;
            }

            .add2Cart__text {
              display: none;
            }
          }
        }

        .order__icon {
          display: inline-block;
          padding: 0 1px;
          font-size: 20px;
          margin: 0;

          &.is-hidden {
            display: none;
          }
        }
      }
    }

    .order__right {
      -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;

      -webkit-order: 2;
      -ms-flex-order: 2;
      order: 2;

      width: 85%;

      .order__row {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;

        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;

        margin-bottom: 15px;

        .oder__item--mobile {
          display: none;
        }

        &.row__bottom {
          .order__item {

            &.item__right {
              width: 42%;
            }

            &.item__left {
              width: 20%;
            }

            &.item__middle {
              width: 15%;
            }

            .order__table{
              tr {
                .next-delivery-date {
                  &.is-hidden {
                    display: none;
                  }
                }
                td {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }

        &.row__top {
          .order__item.product__main-info {
            .order__table{
              tr {
                td:first-child {
                  width: 107px;
                }
              }
            }
          }
        }

        &:first-child {
          .order__item {
            .order__table {
              tr {
                td {
                  &:last-child {
                    width: 90%;
                  }
                }
              }
            }
          }
        }

        .order__item {
          -webkit-flex: 1 0 auto;
          -ms-flex: 1 0 auto;
          flex: 1 0 auto;

          width: 40%;

          &--small {
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;

            width: 20%;
          }

          .order__table {
            table-layout: fixed;

            &.order__table--left {
              display: inline-block;
            }

            &.order__table--right {
              display: inline-block;

              tr {
                td {
                  &:first-child {
                    width: 90px;
                  }

                  .delivery-quantity {
		            &.red {
		              color: @delivery-status-red;
		            }
		          }

                  .delivery-quantity-x {
                    &.is-hidden {
                      display: none;
                    }
                  }

                  .delivery-status {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    position: relative;
                    border-radius: 50%;
                    margin-right: 5px;

                    &.red {
                      background-color: @delivery-status-red;
                    }
                    &.yellow {
                      background-color: @delivery-status-yellow;
                    }
                    &.green {
                      background-color: @delivery-status-green;
                    }
                  }

                  .delivery-status-x {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    position: relative;
                    border-radius: 50%;
                    margin-right: 5px;

                    &.red {
                      background-color: @delivery-status-red;
                    }
                    &.green {
                      background-color: @delivery-status-green;
                    }
                  }
                }
              }
            }

            tr {
              .next-delivery-date {
                &.is-hidden {
                  display: none;
                }
              }
              td {
                padding-bottom: 5px;

                &:first-child {
                  max-width: 35%;
                  width: 35%
                }

                &:last-child {
                  display: -webkit-box;
                  overflow: hidden;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  padding: 0;
                  width: 100%;
                  font-weight: 700;

                  margin-bottom: 5px;

                }
              }
            }

          }

          .order__label {
            display: inline-block;
            width: 30%;
            padding-bottom: 5px;
            vertical-align: top;
          }

          .order__text {
            font-weight: 700;
            display: inline-block;
            width: 65%;
          }
        }
      }
    }
  }

  .orders__filter {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;

    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;

    .filter__item {
      -webkit-flex: 1 0 0;
      -ms-flex: 1 0 0;
      flex: 1 0 0;

      .orders__button {
        width: 90%;
        margin: 0 auto 10px auto;
      }

      select,
      input {
        width: 90%;
        margin: 0 auto 10px auto;
        height: 32px;
      }
    }
  }
}
}

@media (max-width: @screen-md) {
  .order-administration {
    .orders {
      .orders__list {
        .order {
          .order__right {
            tr {
              .next-delivery-date {
                &.is-hidden {
                  display: none;
                }
              }
              td {
                &:first-child {
                  width: 90px;
                }

                .delivery-quantity {
                  &.red {
                    color: @delivery-status-red;
                  }
                }

                .delivery-quantity-x {
                  &.is-hidden {
                    display: none;
                  }
                }

                .delivery-status {
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  position: relative;
                  border-radius: 50%;
                  margin-right: 5px;

                  &.red {
                    background-color: @delivery-status-red;
                  }
                  &.yellow {
                    background-color: @delivery-status-yellow;
                  }
                  &.green {
                    background-color: @delivery-status-green;
                  }
                }

                .delivery-status-x {
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  position: relative;
                  border-radius: 50%;
                  margin-right: 5px;

                  &.red {
                    background-color: @delivery-status-red;
                  }
                  &.green {
                    background-color: @delivery-status-green;
                  }
                }
              }
            }

            .order__item {
              &.oder__item--desktop {
                display: none;
              }

              &.oder__item--mobile {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}