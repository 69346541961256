.product__grid {
	position: relative;
	width: 320px;
	margin: 0 auto;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	margin-left: 0;
	margin: 0 auto;

	.product__grid-wrapper {
		position: relative;
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		min-height: 540px;
		padding: 20px 10px;
		width: 320px;
		overflow: hidden;

		&:after,
		&:before {
			display: block;
			content: "";
			position: absolute;
			background-color: @GRAY-LIGHT;
			z-index: 3;
		}

		&:before {
			width: 2px;
			top: 20px;
			bottom: 20px;
			right: -1px;
		}

		&:after {
			bottom: -1px;
			left: 20px;
			right: 20px;
			height: 2px;
		}

		.product__grid-item {
			display: flex;
			flex-direction: column;
			padding: 10px;
			width: 100%;
			height: 100%;
			
			.product__campaign {
				top: 20px;
				left: 20px;
			}
	
			.grid__header {
				flex: 0 0 auto;
	
				.product__image {
					flex: 0 0 auto;
					height: 150px;
	
					.product__image-thumb {
						display: flex;
						align-items: center;
						height: auto;
						text-align: center;
						flex-direction: column;
						justify-content: center;
	
						img {
							max-width: 100%;
							display: inline-block;
							max-height: 120px;
							margin-left: auto;
							margin-right: auto;
							flex: 0 0 auto;
							margin-top: 25px;
						}
					}
				}
			}

			.product__info.partial {

				.product__info-product-line {
					display: block;
					height: 22px;
				}
				.product__info-name,
				.product__info-color {
					display: block;
					height: 25px;
				}

				.product__info-brand,
				.product__info-attribute {
					display: block;
				}
			}
	
			.grid__body {
				display: flex;
				flex-direction: column;
				flex: 1 1 auto;
				padding: 10px 0 8px 0;
				margin-bottom: auto;

				.product__code-icon {
					height: 36px;

					.product__icons.partial {
						text-align: left;

						margin-top: 3px;
						margin-bottom: 5px;

						.icon {
							margin-left: 0;
							margin-right: 5px;
						}
					}
				}
	
				.product__code {
					color: @FONT-COLOR;
					font-size: 11px;
	
					&:before {
						display: block;
						content: "";
						position: absolute;
						background-color: @GRAY-LIGHT;
						z-index: 3;
	
						width: 2px;
						top: 20px;
						bottom: 20px;
						left: -1px;
					}
				}
	
				.product__links {
					position: absolute;
					bottom: 50px;
				}

				.product__prices {
					.price__wrapper {
						white-space: nowrap;
						.price__label,
						.price{
							display: inline-block;
							width: 50%;
							white-space: normal;
						}

						.price{
							text-align: right;
							padding-right: 4px;
						}
					}
				}
			}
	
			.grid__footer {
				flex: 0 0 auto;
				display: flex;
				justify-content: space-between;

				width: 100%;
				
				.product__salesUnit {
					margin-left: 30px;
					align-self: center;
				}

				.addtocart__wrapper {

					.button__add-to-cart {
						float: right;
						display: block;
						background: @PRIMARY-BUTTON;
						color: #fff;
						padding: 5px 14px;

						.add2Cart__text {
							display: none;
						}
					}

					.button__add-to-cart-asm {
						.button__add-to-cart;
						background: #8c8c8c;
					}
				}
			}
		}
	}
}



#addToCartButton.btn{
	text-align: center;
	min-height: 55px;

	&,
	.add2Cart__text,
	.fa-shopping-cart,
	.spinner__wrapper {
		line-height: 27px;
	}

	.fa-shopping-cart,
	.spinner__wrapper{
		min-width: 30px;
		font-size: 22px;
		font-weight: 900;
	}
}



@media (min-width: 700px) {
	.product__grid {
		width: 640px;
	}
}

@media (min-width: 1200px) {
	.product__grid {
		width: 960px;
	}
}