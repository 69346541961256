table {

  width: 100%;

  th {
	  font-size: 13px;
	  font-weight: 400;
	  text-transform: uppercase;
	  background-color: @GRAY-LIGHT;
	  color: @FONT-COLOR;
	  padding: 8px 10px;
      vertical-align: top;

    &:last-child {
      text-align: right;
    }
  }

  tr.entry-group-header {
    a {
      color: lightgrey;
      &:hover {
        color: black;
      }
    }
    .error {
      background-color: PaleVioletRed;
    }
  }

  .entry-group-error-message {
    text-transform: none;
  }

  div.left-align {
    text-align: left;
  }

  td {
    vertical-align: top;
  }
}

.responsive-table {
  th {
    &:first-child {

      padding-left: 30px;

      @media (max-width: @screen-md) {
        padding-left: 20px;
      }
    }

    &:last-child {
      text-align: right;
      padding-right: 30px;

      @media (max-width: @screen-md) {
        padding-right: 20px;
      }
    }
  }

  td {

    &:nth-child(2){

      @media (min-width: @screen-sm) {
        padding-left: 20px;
      }

      @media (min-width: @screen-md) {
        padding-left: 30px;
      }
    }

    &:last-child {
      text-align: right;
      padding-right: 30px;

      @media (max-width: @screen-sm-min) {
        padding-right: 20px;
      }
    }
  }
}

.responsive-table-item {

  padding: (@grid-gutter-width/2);

  @media (max-width: @screen-sm-min) {
    padding: 10px 20px;
  }

  &:nth-child(even) {
    background-color: @table-2n-line-bg;
  }

  a {
    &.responsive-table-link {
      color: @link-color;
    }

    &:hover, &:focus{
      color: @link-hover-color;
    }
  }

  td {

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      text-align: right;
    }

  }

  @media (max-width: @screen-xs-max) {

    display: block;

    .status strong{
      display: block;
    }

    td {

      display: inline-block;
      width: 40%;
      padding: 3px 10px;

      &:last-child {

        text-align: left;

      }

      &.responsive-table-cell {
        width: 55%;

        &-bold {
          font-weight: 600;
        }
      }

    }

  }

}