.teaserComponent {
	font-size: 14px;
	/*position: relative; */
	padding: 15px 0;
	&:before {
		position: absolute;
		bottom: 0;
		height: 1px;
		left: 10px;
		right: 10px;
		background-color: @GRAY-LIGHTER;
		display: block;
		content: "";
	}

	.teaserComponentMediaWrapper {
		align-content: center;
		justify-content: center;
		.teaserComponentMediaLink {
			display: block;
			line-height: 0;
			overflow: hidden;
			-ms-grid-row-align: center;
			align-self: center;
			width: 100%;
			-webkit-transform-style: preserve-3d;
			transform-style: preserve-3d;
			.teaserComponentMedia {
				-ms-grid-row-align: center;
				align-self: center;
				display: block;
				width: 100%;
				height: auto;
				-webkit-transition: -webkit-transform .15s ease-out;
				transition: -webkit-transform .15s ease-out;
				transition: transform .15s ease-out;
				transition: transform .15s ease-out, -webkit-transform .15s ease-out;
				&:hover {
					-webkit-transform: scale(1.05);
					transform: scale(1.05);
				}
			}
		}
	}

	.teaserComponentTitle {
		margin: 10px 0 0;
		padding: 0 10px;
		font-size: 16px;
	}

	.teaserComponentBody {
		margin: 10px 0 0;
		padding: 0 10px;
		.teaserComponentBodyLink {
			text-decoration: none;
			color: @GRAY;
		}
	}
}

@media (max-width: @screen-xs) {
	.teaserComponent {
		&:first-of-type {
			padding-top: 30px;
		}
	}
}

