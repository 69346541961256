.language-selector {
  .language-selector__list-item {
    display: inline-block;
    text-transform: uppercase;
    color: @language-selector-selected-color;

    &:hover {
      cursor: pointer;
    }

    &:not(:first-child):before {
      content: '|';
      display: inline-block;
      margin: 0 2px;
      vertical-align: text-bottom;
    }
  }
}

@media (max-width: @screen-xs) {
  .sticky .language-selector {
    display: none;
  }
}

@media (max-width: @screen-md) {
  .language-selector {
    grid-area: headerRight;
    position: absolute;
    top: 39px;
    right: 5px;
  }
  .sticky .language-selector {
    display: none;
  }
}

// IE Hack
  @media all and (max-width: @screen-md), (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .language-selector {
      position: absolute;

    }
  }

@media (max-width: @screen-md) {
  @supports (-ms-accelerator:true) {
    .language-selector {
      position: absolute;
    }
  }
}



@media all and (min-width: @screen-md), (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .language-selector {
    position: inherit;
    margin-right: 20px;
  }
}

@media (min-width: @screen-md) {
  @supports (-ms-accelerator:true) {
    .language-selector {
      position: inherit;
      margin-right: 20px;
    }
  }
}


