// product toggle
.product-details-toggle {
	position: relative;
	cursor: pointer;
	margin-bottom: @grid-gutter-width;
	padding-right: @grid-gutter-width;

  &.name {
    font-weight: 600;
  }

	.product-details.open & {
		margin-bottom: 0;
	}

	.glyphicon {
		float: right;
		font-size: @font-size-base;
		line-height: 35px;

		.product-details.open &:before {
			content: "\e114";
		}
	}
}
.product-details-toggle-wrap {
	display: none;
    .full-width-padding();

	.product-details.open + & {
		display: block;
	}

	.product-details {
		font-size: @font-size-large-3;
	}

	img {
		width: 175px;
		margin: 0 30px 35px 0;
		float: left;
	}
}
@media (min-width: @screen-sm-min) {
	.product-details-toggle-wrap {
		.product-details {
			text-align: right;
		}
	}
}
@media (max-width: @screen-xs-max) {
	.product-details-toggle {
		padding: 0 (@grid-gutter-width + 5) 0 @grid-gutter-width/2;

		.glyphicon {
			right: @grid-gutter-width/2;
		}
	}
	.product-details-toggle-wrap {
		margin: 0;
		img {
			float: none;
			width: 100%;
			margin: 0  0 @grid-gutter-width 0;
		}
		.product-details {
			margin-bottom: @grid-gutter-width;
		}
	}
}

// product action
.product-action {
	overflow: hidden;
	border-top: 1px solid @border-color-2;
	border-bottom: 1px solid @border-color-2;
	margin-bottom: 15px;
  	.full-width-padding();

	ol {
		list-style: none;
		overflow: hidden;
		margin: 0;
		padding: 0;

		li {
      font-size: @font-size-large;
			float: right;
			margin: 15px 0 15px @grid-gutter-width;
			line-height: (@padding-large-vertical * 2) + (@btn-font-size * @line-height-base) + (@btn-border-width * 2);
		}
	}
  #total-price {
    font-size: @font-size-large;
    .text-variant-bold();
  }
	div {
		float: left;
		margin: 15px 0;
		line-height: (@padding-large-vertical * 2) + (@btn-font-size * @line-height-base) + (@btn-border-width * 2);
	}
}
@media (max-width: @screen-xs-max) {
	.product-action {
		margin-bottom: 0;

		ol{
			li{
				margin-right: 0;
			}
		}
	}
}
@media (max-width: @screen-xs) {
	.product-action {
		ol li,
		.btn {
			width: 100%;
		}
		div {
			float: none;
			line-height: 1.42857;
			margin-bottom: 0;
		}
	}
}


// future stock
.update-future-stock {
	text-align: right;
	padding: 20px 7px 15px 0;
	background-color: @body-bg;

	.update_future_stock_button,
	.hide_future_stock_info {
		font-size: @font-size-small;
    font-weight: 700;
	}

	.hide_future_stock_info {
		display: none;
	}
}


// order grid
.product-grid-container,
#cboxContent {
	.sku-quantity {
		font-size: @font-size-small;
		line-height: @grid-gutter-width;
		width: 60px;
		padding: 9px 10px;
		margin: 5px 0 3px;
		border: 1px solid @input-border;
		text-align: center;
	}

	.td_stock {
		display: block;
		padding-bottom: 5px;

		.in-stock {
			color: @text-color-secondary;
			font-size: 13px;
			font-weight: 600;
		}
	}

	.future_stock {
		display: block;
		font-size: 12px;
		line-height: 14px;

		.future_stock_value {
			margin: 2px 0 8px 0;
		}
	}

	.data-grid-total {
		display: inline-block;
		padding-top: 5px;
		border-top: 1px solid @border-color-3;
		font-size: 15px;
		font-weight: 700;
	}
}
.product-grid-container {
	table {
		td {
      .price {
        font-size: @font-size-large-1 - 1;
        font-weight: bold;
      }

			&.out-of-stock {
				.variant-prop,
				.price {
					opacity: 0.3;
				}
				.sku-quantity {
					opacity: 0.3;
					background-color: #fff;
				}
			}
		}
	}
}
@media (min-width: @screen-sm-min) {
	@scrollWidth: 17px;
	.add-to-cart-order-form-wrap {
		position: relative;
		.full-width-padding();
	}

	.add-to-cart-order-form-wrap::after {
		background-color: white;
		bottom: 0;
		content: "";
		height: @scrollWidth;
		position: absolute;
		width: 100%;
	}
	.add-to-cart-order-form-wrap::before {
		background-color: white;
		right: 0;
		content: "";
		//width: @scrollWidth;
		position: absolute;
		height: 100%;
	}
	.add_to_cart_order_form {
		overflow: scroll;
		max-height: 907px;
	}

	.order-form-scroll {
		cursor: pointer;
		background-color: @brand-primary;
		opacity: 0.4;
		color: #fff;
		text-align: center;
		position: absolute;
		display: none;

		&.right {
			width: 50px;
			height: 75px;
			line-height: 75px;
			right: 0px;
			top: 50%;
			margin-top: -35px;
		}
		&.left {
			width: 50px;
			height: 75px;
			line-height: 75px;
			left: 0;
			top: 50%;
			margin-top: -35px;
		}
		&.up {
			width: 75px;
			height: 50px;
			line-height: 50px;
			top: 0;
			left: 50%;
			margin-left: -35px;
		}
		&.down {
			width: 75px;
			height: 50px;
			line-height: 50px;
			bottom: @scrollWidth;
			left: 50%;
			margin-left: -35px;
		}
	}

	.product-grid-container {
		&-dim-3,
		&-dim-2 {
			width: 100%;
		}

		table {
			border-bottom: 1px solid @table-border-color;
			background-color: @body-bg;

			&:nth-child(2n){
				background: @table-2n-line-bg;
			}

			tr {
				&:first-child > th {
					&:first-child {
						border-right: 1px solid @table-head-border-color;
						white-space: nowrap;
					}
				}
				> td:first-child {
					border-right: 1px solid @table-border-color;
					padding-top: @grid-gutter-width;
				}
			}

			th {
				text-align: center;
			}

			td {
				text-align: center;
				padding: 25px 10px 15px;
				vertical-align: top;
				max-width: 200px;
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	#cboxContent {
		.sku-quantity {
			font-size: @font-size-large-1;
		}
	}
	.product-grid-container {
		font-size: @font-size-large-1;
		.make-row();
		table {
			table-layout: fixed;
			width: 100%;

			tr {
				td {
					border-bottom: 1px solid @border-color-2;
				}
				.variant-detail {
					width: 60%;
					padding: 15px 0 15px @grid-gutter-width;

					img {
						float: left;
						padding-right: @grid-gutter-width;
					}
					.description {
						padding: 12px 0;
					}
				}

				.variant-select {
					width: 40%;
					text-align: right;
					font-weight: 600;
					padding-right: @grid-gutter-width;
          vertical-align: middle;
				}
			}
			.hidden-size {
				display: none;
			}

			.variant-summary {
				display: none;
				background-color: @border-color-2;

				.variant-summary-items {
					margin: 15px 0;
				}
				tr {
					td {
						padding: 0 @grid-gutter-width;
						font-weight: bold;

						&:last-child {
							text-align: right;
						}
					}
					&:first-child {
						td {
							font-weight: normal;
						}
					}
				}
				span {
					display: none;
				}
			}
			.editSize {
				display: none;
			}
			&.selected {
				.variant-summary {
					display: table-row;
				}
				.editSize {
					display: inline;
				}
				.selectSize {
					display: none;
				}
			}
		}
	}
	.variantSelectMobile #cboxLoadedContent {
		overflow: visible!important;
		padding-left: 0;
		padding-right: 0;
	}
	.variantSelectMobile #cboxContent {
		table {
			width: 100%;
			td {
				display: block;
				font-weight: 600;
				text-align: center;
				padding: 25px 0 10px 0;

				&:first-child {
					width: auto;
				}

				&:nth-child(2n+2) {
					background: @table-2n-line-bg;
				}
			}
		}
		.product-grid-container {
			margin: 0;
		}
		.orderForm_grid_group {
			position: relative;
		}
		.update-future-stock {
			position: absolute;
			margin-left: -65px;
			left: 50%;
			top: 100px;
		}
		.variant-detail {
			padding: 0 0 60px 0;

			img {
				display: inline-block;
				max-width: 200px;
				float: none;
				padding: 0;
			}

			.description {
				padding: 0;

				> div {
					display: inline;
				}
			}
		}
		.mobile-cart-actions {
			padding: @grid-gutter-width;
			border-bottom: none;
			background-color: #fff !important;

			.btn {
				width: 100%;
			}
		}
		.variant-select,
		.variant-summary {
			display: none;
		}
		.variant-prop {
			> span {
				font-weight: 400;
			}
		}
		.sku-quantity {
			font-weight: 400;
			line-height: 60px;
			width: 120px;
			height: 80px;
		}
	}
}
#orderFormContainer {
	.headline,
	.advanced-search-wrap {
		.full-width-padding();
	}
}
#resultsList.item__list {
	.item__list--item {
		&:last-child {
			padding-right: 0;
		}
	}
}