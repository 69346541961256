.checkout__progression {
  display: flex;
  margin-right: 13px;
  margin-top: 15px;

  .progression__item {
    flex: 1 1 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin: 0 3px;
    padding: 10px 10px 10px 30px;
    position: relative;
    background-color: @progression-bar-background;

    &:before,
    &:after {
      content: " ";
      position: absolute;
      top: 0;
      right: -16px;
      width: 0;
      height: 0;
      border-top: 21px solid transparent;
      border-bottom: 21px solid transparent;
      border-left: 17px solid @progression-bar-background;
      z-index: 2;
    }

    &:before {
      right: auto;
      left: -1px;
      border-left: 17px solid #fff;
      z-index: 0;
    }

    &.green {
      &.active {
        background: @progression-bar-background-active-green;

        &:after {
          border-left: 17px solid @progression-bar-background-active-green;
        }

        .progression__text {
          color: @progress-bar-color-active;
        }
      }
    }

    &.orange {
      &.active {
        background: @progression-bar-background-active-orange;

        &:after {
          border-left: 17px solid @progression-bar-background-active-orange;
        }

        .progression__text {
          color: @progress-bar-color-active;
        }
      }
    }



    .progression__text {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: @progression-bar-color;
    }
  }
}

@media (max-width: @screen-md) {
  .checkout__progression {
    padding: 15px 0;
    .progression__item:not(.active) {
      width: 15%;

      .progression__label {
        display: none;
      }
    }
    .progression__item {
      width: 60%;
    }
  }
}
