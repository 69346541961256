.tab_wrapper {
  display: inline-block;
  width: 100%;
  position: relative;
}
.tab_wrapper * {
  box-sizing: border-box;
}
.tab_wrapper > ul {
  clear: both;
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 36px;
}
.tab_wrapper > ul li {
  float: left;
  cursor: pointer;
  border: 1px solid @GRAY-DARK;
  position: relative;
  margin: 0;
  padding: 10px 25px;
  outline: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: #ffffff;
  color: #717375;
  list-style: none;
}
.tab_wrapper > ul li.active {
  border-color: @GRAY-DARK;
  color: @WHITE;
  background: @GRAY-DARK;
  height: 42px;
}
.tab_wrapper .controller {
  display: block;
  overflow: hidden;
  clear: both;
  position: absolute;
  top: 0;
  right: 0;
}
.tab_wrapper .controller span {
  padding: 8px 22px;
  background: #49a4d9;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
}
.tab_wrapper .controller span.next {
  float: right;
  margin-left: 10px;
}

.tab_wrapper .content_wrapper .tab_content {
  display: none;
  padding: 20px 30px;
  margin-bottom: 10px;
  border: 1px solid #7e8082;
  max-width: ~"calc(100vw - 40px)";
}

.tab_wrapper .content_wrapper .tab_content .error {
  color: red;
}
.tab_wrapper .content_wrapper .accordian_header {
  padding: .5em .5em .5em 25px;
  margin-bottom: 10px;
  background: @accordion-header-bg;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  border: none;
  display: none;
}
.tab_wrapper .content_wrapper .accordian_header {
  position: relative;

  &:after {
    &:extend(.fa);
    content: @fa-var-angle-down;
    font-size: 25px;
    position: absolute;
    right: 25px;
    top: 10px;

    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;

    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
  }

  &.active {
    &:after {
      -webkit-transform: rotate(18deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

.tab_wrapper.left_side > ul {
  width: 25%;
  float: left;
  border-bottom: 1px solid #ccc;
}
.tab_wrapper.left_side > ul li {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 12px 18px;
  border-width: 1px;
  border-right: 0;
  border-left: 0;
}
.tab_wrapper.left_side > ul li:after {
  left: 0;
  top: 0;
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #ccc;
}
.tab_wrapper.left_side > ul li.active {
  border-color: #ccc;
}
.tab_wrapper.left_side > ul li.active:after {
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background: #49a4d9;
}
.tab_wrapper.left_side > ul li.active:before {
  right: -1px;
  position: absolute;
  top: 0;
  content: "";
  width: 2px;
  height: 100%;
  background: #fff;
}
.tab_wrapper.left_side .content_wrapper {
  width: 75%;
  border: 1px solid #ccc;
  float: left;
}
.tab_wrapper.left_side .controller {
  top: -50px;
}
.tab_wrapper.right_side > ul {
  width: 25%;
  float: right;
  border-bottom: 1px solid #ccc;
}
.tab_wrapper.right_side > ul li {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 12px 18px;
  border-width: 1px;
  border-right: 0;
  border-left: 0;
  text-align: right;
}
.tab_wrapper.right_side > ul li:after {
  right: 0;
  left: inherit;
  top: 0;
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #ccc;
}
.tab_wrapper.right_side > ul li.active {
  border-color: #ccc;
}
.tab_wrapper.right_side > ul li.active:after {
  right: 0;
  left: inherit;
  top: 0;
  width: 5px;
  height: 100%;
  content: "";
  background: #49a4d9;
}
.tab_wrapper.right_side > ul li.active:before {
  left: -2px;
  right: inherit;
  position: absolute;
  top: 0;
  content: "";
  width: 2px;
  height: 100%;
  background: #fff;
}
.tab_wrapper.right_side .content_wrapper {
  width: 75%;
  float: left;
  border: 1px solid #ccc;
}
.tab_wrapper.right_side .controller {
  top: -50px;
}
.tab_wrapper.accordion .content_wrapper {
  border-color: #ccc;
}
.tab_wrapper.accordion .content_wrapper .accordian_header {
  display: block;
}
.tab_wrapper.accordion > ul {
  display: none;
}
.tab_wrapper .active_tab {
  display: none;
  position: relative;
  background: #000000;
  padding: 12px;
  text-align: center;
  padding-right: 50px;
  color: #fff;
  cursor: pointer;
  border-radius: 30px;
}
.tab_wrapper .active_tab .arrow {
  width: 30px;
  height: 30px;
  overflow: hidden;
  line-height: 30px;
  text-align: center;
  background: #fff;
  position: absolute;
  right: 6px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.tab_wrapper .active_tab .arrow:after {
  content: "";
  width: 10px;
  display: inline-block;
  height: 10px;
  border-right: 3px solid #000;
  border-bottom: 3px solid #000;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -8px;
}
.tab_wrapper .active_tab .text {
  text-indent: 39px;
  display: block;
}

@media (max-width: @screen-md) {
  .tab_wrapper > ul {
    display: none;
  }
  .tab_wrapper .content_wrapper {
    border: 0;
  }
  .tab_wrapper .content_wrapper .accordian_header {
    display: block;
  }
  .tab_wrapper.left_side .content_wrapper, .tab_wrapper.right_side .content_wrapper {
    width: 100%;
    border-top: 0;
  }
  .tab_wrapper .controller {
    display: none;
  }
  .tab_wrapper.show-as-dropdown > .tab_list {
    border: 0;
    box-shadow: 0 0 8px #ccc;
    position: absolute;
    z-index: 3;
    background: #fff;
    border-radius: 5px;
    margin-top: 15px;
  }
  .tab_wrapper.show-as-dropdown > .tab_list:before {
    content: "";
    position: absolute;
    top: -6px;
    left: 50%;
    width: 14px;
    height: 14px;
    background: #fff;
    transform: rotate(45deg);
    box-shadow: -3px -4px 5px #efefef;
    margin-left: -7px;
  }
  .tab_wrapper.show-as-dropdown > .tab_list li {
    border: 0;
    border-bottom: 1px solid #ccc;
  }
  .tab_wrapper.show-as-dropdown > .tab_list li.active:after {
    display: none;
  }
  .tab_wrapper.show-as-dropdown .active_tab {
    display: block;
  }
  .tab_wrapper.show-as-dropdown > .tab_list {
    display: none;
  }
  .tab_wrapper.show-as-dropdown > .content_wrapper > .accordian_header {
    display: none;
  }
  .tab_wrapper.show-as-dropdown > .tab_list li {
    display: block;
    float: none;
    width: 100%;
  }
  .tab_wrapper.show-as-dropdown > .tab_list {
    display: block !important;
    overflow: hidden;
  }
}
