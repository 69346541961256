.localized-media-component {
	&:before {
		&:extend(.fa);
		position: relative;
		top: 4px;
		font-size: 25px;
	}
}

.localized-media-component[data-mime="application/zip"] {
	&:before {
		content: @fa-var-file-archive;
	}
}
.localized-media-component[data-mime="video/mpeg"] {
	&:before {
		content: @fa-var-file-video;
	}
}
.localized-media-component[data-mime="video/quicktime"] {
	&:before {
		content: @fa-var-file-video;
	}
}
.localized-media-component[data-mime="video/x-msvideo"] {
	&:before {
		content: @fa-var-file-video;
	}
}
.localized-media-component[data-mime="video/x-ms-wmv"] {
	&:before {
		content: @fa-var-file-video;
	}
}
.localized-media-component[data-mime="application/vnd.openxmlformats-officedocument.presentationml.presentation"] {
	&:before {
		content: @fa-var-file-powerpoint;
	}
}
.localized-media-component[data-mime="application/pdf"] {
	&:before {
		content: @fa-var-file-pdf;
	}
}
.localized-media-component[data-mime="application/vnd.openxmlformats-officedocument.wordprocessingml.document"] {
	&:before {
		content: @fa-var-file-word;
	}
}
.localized-media-component[data-mime="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"] {
	&:before {
		content: @fa-var-file-excel;
	}
}
@media (max-width: @screen-md) {
	.localized-media-component {
		display: block;
		height: 40px;
	}
}