.product__delivery.partial {
	.delivery__container {
		position: relative;
		display: inline-block;
		width: 100%;
		margin-bottom: 4px;

		.delivery__label {
			display: inline-block;
			width: 40%;
			hyphens: auto;
			.text-overflow();

			&.delivery__label--headline {
				font-weight: 700;
			}

			&.delivery__label--no-ellipsis {
				overflow: visible;
				margin-right: 20px;
				font-weight: 700;
			}
		}

		.delivery__info {
			position: absolute;
			top: 0;
			width: 25%;
			display: inline-block;
			text-align: left;
			white-space: nowrap;

			&.delivery__info--green {
				color: @delivery-info-green;
				font-weight: 700;
			}

			&.delivery__info--orange {
				color: @delivery-info-orange;
				font-weight: 700;
			}

			&.delivery__info--gray {
				color: @delivery-info-gray;
				font-weight: 700;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&.delivery__info--cube {
				&:before {
					&:extend(.fa);
					content: @fa-var-cube;
					font-size: 18px;
					margin-right: 5px;
					color: @delivery-info-icon-color;
				}
			}

			&.delivery__info--truck {
				&:before {
					&:extend(.fa);
					content: @fa-var-truck;
					font-size: 18px;
					margin-right: 5px;
					color: @delivery-info-icon-color;
				}
			}

			&.delivery__info--home {
				&:before {
					&:extend(.fa);
					content: @fa-var-home;
					font-size: 18px;
					margin-right: 5px;
					color: @delivery-info-icon-color;
				}
			}

			&.delivery__info--plus {
				&:before {
					&:extend(.fa);
					content: @fa-var-plus-square;
					font-size: 18px;
					margin-right: 8px;
					color: @delivery-info-icon-color;
				}
			}
		}

		.delivery__price {
			float: right;
			text-align: end;
			&.discounted {
				color: @delivery-info-big-color;
				font-weight: bold;
			}
		}

		.delivery__scale-quantity {
			display: block;
			font-size: 10px;
			line-height: 8px;
			text-align: end;
		}
	}

	.delivery__description {
		display: flex;
		justify-content: flex-end;

		.delivery__description-text {
			font-style: italic;
			.text-overflow();
		}
	}

	.delivery__week {
		.delivery__info {
			&.delivery__info--orange {
				color: @delivery-info-orange;
				font-weight: 700;
			}
		}
	}
}
