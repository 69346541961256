/*
## landingLayout2Page
********************************************************************/

.section1 {
  margin:10px 0;
}

.zoneA {

  .disp-img {
    height:108px;
    margin:10px 0 0 0;
    border-bottom:1px dotted #A5A5A5;
    position:relative;

    &:first-child {
      margin:0;
    }

    a {
      display:block;

    }

    .title {
      display:block;
      margin-left:106px;
      border-bottom:1px dotted #A5A5A5;
      margin-bottom:10px;
    }

    .thumb {
      position:absolute;
      top:0;
    }

    .details {
      width:124px;
      height:96px;
      margin-left:106px;
      display:block;
    }
  }

  .action {
    display:none;
  }

}

.zoneB {
  .simple_disp-img {
    &:first-child {
      margin:0 0 10px 0;
    }
  }
}

#homepage_slider {

  &.stripViewer {
    position: relative;
    overflow: hidden;
    margin: 0;

    ul {
      margin: 0;
      padding: 0;
      position: relative;
      left: 0;
      top: 0;
      width: 1%;
      list-style-type: none;

      li {
        float: left;
      }
    }
  }

  .svw {
    width: 50px;
    height: 20px;
    background-color: #ffffff;

    ul {
      position: relative;
      left: -999em;
    }
  }

  .tooltip {
    padding: 0.5em;
    background-color: #ffffff;
    color: #000000;
    border: 5px solid #dedede;
  }

}

.stripTransmitter {
  overflow: auto;
  padding: 0 15px ;
  width: 438px !important;
  height:51px;
  border-bottom:1px dotted #A5A5A5;
  background-color: #eee;

  ul {
    margin: 0;
    padding: 0;
    position: relative;
    list-style-type: none;

    li {
      width: 30px;
      float: left;
    }
  }

  a {
    line-height: 47px;
    display: block;
    padding: 0 0 0 0;
    text-align: center;
    text-decoration: none;
    font-size: 1.4em;

    &:hover,
    &.current {
      font-weight: bold;
    }
  }
}

.tooltip {
  padding: 0.5em;
  background-color: #ffffff;
  color: #000000;
  border-bottom:1px dotted #A5A5A5;
}

.section4 {

  .disp-img {
    margin:0 0 15px 0;

    .title {
      border-bottom:1px solid #a5a5a5;
      height:30px;
      line-height:30px;
      text-transform:uppercase;
      font-weight:bold;
      font-size:1em;
      white-space: nowrap;
      display:block;
      margin:0 0 10px 0;
    }

    .thumb {
      margin:0 0 5px 0;
      display:block;
    }

    .action{
      display:none;
    }
  }
}