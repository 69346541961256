label {
  color: @label-color;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  &.uncased {
    text-transform: none;
  }
}

.help-block {
  font-style: italic;
}

.form-control:focus {
  border-color: @input-border-focus;
  outline: 0;
  box-shadow: none;
  border-width: 2px;
}

select.form-control {
  text-transform: uppercase;
  font-size: 13px;
}

.form-control {
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    color: @text-color-secondary;
  }
}

.has-error {
  .form-control-validation(@label-color, @input-error, @input-error-bg, @input-error-text);
}

.has-success {
  .form-control-validation(@label-color, @input-success, @input-success-bg, @input-success-text);
}

legend {
  font-size: 18px;
  border-bottom: 0;
  margin-top: @grid-gutter-width;
  margin-bottom: @grid-gutter-width;
}

// For input fields with glyphicon
.form-element-icon {
  position: relative;

  .glyphicon {
    position: absolute;
  }

  &.datepicker {
    .glyphicon {
      top: 10px;
      right: 10px;
      color: @brand-primary;
      cursor: pointer;

      @media (max-width: @screen-sm-max) {
        top: 38px;
      }
    }
  }
}

// radio button styling
.form-control-radio,
.form-control-checkbox {
  display: block;

  input {
    margin-bottom: 0;
    float: left;
  }
  .form-control-label {
    margin-left: 25px;
  }
}

fieldset {
  > div:last-of-type {
    input,
    label {
      margin-bottom: 0;
    }
  }
}

.legend {
  margin-bottom: 20px;
  margin-right: 15px;
}

textarea {

  + .help-block {
    font-size: 12px;
    margin-bottom: 25px;
    text-align: right;
  }
}

.input-sm {
  .input-size(@input-height-small; @input-padding-vertical-small; @padding-base-horizontal; @font-size-base; @line-height-base; @input-border-radius);
}

/* Toggle Button */
.toggle-button {
  position: absolute;
  top: 19px;
  right: 73px;

  //the input
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    //disabled
    &[disabled] ~ label {
      color: lighten(@border-color-3, 20%);
      pointer-events: none;

      .toggle-button__switch {
        opacity: 0.4;
      }

    }

    //unchecked
    &:focus ~ label .toggle-button__switch,
    &:hover ~ label .toggle-button__switch {
      background-color: lighten(@border-color-3, 20%);
      border-color: darken(@border-color-3, 20%);
    }

    //checked
    &:checked ~ label .toggle-button__switch {
      background-color: @brand-primary;

      &:after {
        content: '\00a0';
        background-color: @body-bg;
        transform: translate3d(22px, 0, 0);
      }

      :before {
        content: '\00a0';
        left: 0;
      }

    }


  }

  //the label
  label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .toggle-button__switch {
      position: relative;
      height: 33px;
      flex: 0 0 56px;
      border-radius: 60px;
      border: 1px solid @border-color-3;
      background-color: @body-bg;

      &.is-checked {
        border-color: @brand-primary;
      }

      &:before {
        left: 28px;
        font-size: 12px;
        line-height: 23px;
        width: 28px;
        padding: 0 12px;
        content: '\00a0';
        position: absolute;
        top: 0;
      }

      &:after {
        top: 4px;
        left: 4px;
        border-radius: 30px;
        width: 23px;
        line-height: 23px;
        font-size: 12px;
        color: @body-bg;
        background-color: @border-color-3;
        content: '\00a0';
        position: absolute;
        z-index: 5;
      }

    }

  }

}
//overrides to avoid unchecked state taking precedence
.toggle-button input[type="checkbox"]:checked:focus ~ label .toggle-button__switch,
.toggle-button input[type="checkbox"]:checked:hover ~ label .toggle-button__switch {
  background-color: lighten(@brand-primary, 20%);
  border-color: lighten(@brand-primary, 20%);
}

.entryInformation{
	min-height: 3rem;
}

.checkbox__hidden {
  display: none;
}

.option__checkbox {
  margin-bottom: 10px;

  input[type="checkbox"] {
    display: none;
  }
}

.fake__checkbox {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }

  &:before {
    &:extend(.far);
    content: @fa-var-square;
    font-size: 25px;
  }

  &.is-checked {
    &:before {
      &:extend(.far);
      content: @fa-var-check-square;
    }
  }
}

.checkbox__text--deselect,
.checkbox__text--select {
  display: none;

  &.is-show {
    display: inline-block;
  }
}


