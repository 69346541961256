
.product__campaign {
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
    max-width: calc(~"100% - 10px");

    .campaign__text {
        padding: 4px 9px;
        display: block;
        background-color: @product-campaign-bg;
        color: @product-campaign-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}