.account-section .account-section-content .import-csv__form {
  padding: 0;
}
.import-csv__well {
  .full-width-padding();
}
.import-csv__file-spec {
  padding-top: @grid-gutter-width - 5;
  ul {
    padding-left: 0;
  }
}
.import-csv__file-spec-item {
  padding-top: 5px;
  list-style: none;
}
.import-csv__file-upload {
  padding-top: @grid-gutter-width;
}
.import-csv__actions {
  margin: 35px 0;
  .full-width-padding();
}
@media (min-width: @screen-sm-min) {
  .import-csv__file-upload {
    border-left: 1px solid @border-color-3;
    padding-top: @grid-gutter-width - 5;
    padding-bottom: @grid-gutter-width/2;
    padding-left: @grid-gutter-width*2;
  }
}
@media (max-width: @screen-xs-max) {
  .import-csv__well {
    margin: 0;
  }
}

////////////////////////////////
//        file upload         //
////////////////////////////////
.file-upload {
  margin-top: 40px;
}
.file-upload__label {
  display: block;
}
.file-upload__wrapper {
  position: relative;
  overflow: hidden;
  margin-right: 10px;

  &.btn.btn-small {
    max-width: 270px;
    bottom: 0;
  }
}
.file-upload__input {
  position: absolute;
  right: -3px;
  top: -3px;
  bottom: -3px;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
}
.file-upload__file-name {
  font-size: @font-size-large-1;
  display: inline-block;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  position: relative;
  top: 8px;
}
