.asm__overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: @asm-overlay-bg;
  z-index: 999;

  .overlay__text {
    width:100%;
    position: absolute;
    text-align: center;
    padding-top:60%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Matrix(Dx=0,Dy=0)';
    filter: progid:DXImageTransform.Microsoft.Matrix(Dx=0,Dy=0);
    transform: translateY(-50%);

    font-size: 20px;
    font-weight: 700;
  }
}

.login__header {
  border-bottom: 1px solid @header-border;
  padding: 50px 10px;
  max-width: 1180px;
  margin: 0px 10px;
  position: relative;

  .header__logo {
    max-width: 330px;
  }

  .language-selector {
    position: absolute;
    right: 20px;
    top: 65px;
  }
}


.login__section {
  max-width: 580px;
  margin: 0 auto;
  padding-top: 60px;

  .control-label {
    color: @login-label !important;
    text-transform: uppercase !important;
  }

  .forgotten-password {
    margin-bottom: 20px;
  }
}

.alert-dismissable {
  max-width: 1180px;
  margin: 0px 10px;

  .close {
    right: 0;
  }

}

.forgotten__password {

  .control-group {
    margin-top: 15px;

    .control-label {
      color: @login-label !important;
      text-transform: uppercase !important;
    }
  }

}

@media (max-width: @screen-md) {

  .login__header {
    padding: 20px 10px;
    .header__logo {
      max-width: 150px;
    }

    .language-selector {
      top: 40px;
    }
  }
  .login__section {
    .login__action {
      .btn-small {
        max-width: 100%;
      }
    }
  }
}

@media (min-width: 376px) {
	.login__section {
		padding-bottom: 245px;
	}
}

@media (min-width: 667px) {
	.login__section {
		padding-bottom: 230px;
	}
}

@media (min-width: 960px) {
  .login__section {
      padding-bottom: 80px;
  }
}

@media (min-width: 1024px) {
  .login__header {
    margin: 0 auto;
  }

  .alert-dismissable {
    margin: 0 auto;
  }
}