.upload {
  .upload__area{
    max-width: 450px;
    max-height: 200px;
    padding: 15px;
    border: 2px solid @GRAY-LIGHT;
    margin: 0 auto;
    text-align: center;
    overflow: auto;

    &:hover{
      cursor: pointer;
    }

    .upload__icon {
      margin: 15px auto;
      display: block;
      font-size: 35px;
      color: @SECONDARY;

      &:after {
        &:extend(.fa);
        content: @fa-var-file-upload;
      }
    }
  }

  .upload__file{
    display: none;
  }

  .upload__thumbnail {
    display: none;
    text-align: center;
    margin-top: 25px;

    &.is-visible {
      display: block;
    }
  }
}
