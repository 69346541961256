.product__detail {
  display: -ms-grid;
  display: grid;
  grid-template-areas: "detailImage detailContainer";
  -ms-grid-columns: 50% 50%;
  grid-template-columns: 50% 50%;

  .detail__image {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column: 1;
    grid-column-start: 1;
    position: relative;
    grid-area: detailImage;

    .product__campaign {
      top: 18px;
    }

    .product__image {
      .image__slider {
        .slick-arrow {
          display: none !important;
        }
      }
    }

  }

  .detail__wrapper {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column: 2;
    grid-column-start: 2;
    grid-area: detailContainer;

    .product__info .product__info-color::before {
      content: " - ";
    }
  }

  .container__right,
  .container__left {
    display: inline-block;
    width: 50%;
    white-space: normal;
  }

  .container__right--first {
    margin-bottom: 25px;
  }

  .container__left {
    padding-right: 20px;
  }

  .product__image {
    padding-right: 45px;
  }

  .detail__wrapper {
    white-space: nowrap;

    .container__left,
    .container__right {
      position: relative;
      display: inline-block;
      width: 50%;
    }

    .detail__container {
      display: flex;
      margin-bottom: 20px;

      &.detail__container--first {
        margin: 0;
      }


      .detail__inform-when-available {
        text-align:center;
      }

      .product__info.partial {
        .product__info-code {
          display: none;
        }
      }

      .product__icons {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .product__code {
        text-align: right;
        display: block;
        font-weight: 700;

        &.product__code--mobile {
          display: none;
        }
      }

      .detail__link {
        display: block;
      }

      .price__wrapper {
        .price {
          float: right;
        }
      }

      .delivery__container {
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        align-items: flex-end;
      }

      .delivery__label {
        flex: 1 1 0;
        overflow: hidden;
        text-overflow: ellipsis;

        &.delivery__label--headline {
          font-weight: 700;
        }

        &.delivery__label--no-ellipsis {
          overflow: visible;
          margin-right: 20px;
          font-weight: 700;
        }
      }

      .delivery__colored {
        color: @delivery-info-big-color;
      }

      .delivery__info {
        &.delivery__info--green {
          color: @delivery-info-green;
          font-weight: 700;
        }

        &.delivery__info--orange {
          color: @delivery-info-orange;
          font-weight: 700;
        }

        &.delivery__info--gray {
          color: @delivery-info-gray;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.delivery__info--big {
          color: @delivery-info-big-color;
          font-weight: 700;
          font-size: 18px;
        }

        &.delivery__info--strike {
          text-decoration: line-through;
        }

        &.delivery__info--cube {
          &:before {
            &:extend(.fa);
            content: @fa-var-cube;
            font-size: 18px;
            margin-right: 5px;
            color: @delivery-info-icon-color;
          }
        }

        &.delivery__info--truck {
          &:before {
            &:extend(.fa);
            content: @fa-var-truck;
            font-size: 18px;
            margin-right: 5px;
            color: @delivery-info-icon-color;
          }
        }

        &.delivery__info--home {
          &:before {
            &:extend(.fa);
            content: @fa-var-home;
            font-size: 18px;
            margin-right: 5px;
            color: @delivery-info-icon-color;
          }
        }

        &.delivery__info--plus {
          &:before {
            &:extend(.fa);
            content: @fa-var-plus-square;
            font-size: 18px;
            margin-right: 5px;
            color: @delivery-info-icon-color;
          }
        }
      }

      .delivery__address {
        span {
          display: block;
        }
      }

      .supplier_table {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0 auto;
      }

      .supplier-row {
        display: flex;
        flex-direction: row;
        width: 100%;
      }

      .supplier-name {
        flex: 1;
      }

      .supplier-price {
        flex: 0;
        text-align: right;
        white-space: nowrap;
      }

      .detail__cta {
        display: -ms-flex;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 50%;
      }
    }
  }
}


.detail__columns {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 50px;

  .detail__tabs {
    flex: 1 auto;
    max-width: 1180px;
    width: 100%;

    .tabs {
      .tabhead {
        display: none;
      }
    }

    .details__subline {
      padding-top: 10px;
    }

    .feedback {
      .feedback__headline {
        display: block;
        font-size: 16px;
        color: @feedback-headline-color;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .feedback__text {
        display: block;
        margin-bottom: 10px;
      }

      button {
        margin-bottom: 10px;
      }

      .write-feedback {
        .control-label {
          color: @FONT-COLOR;
        }

        .help-block {
          span {
            font-size: 14px;
          }
        }

        .btn-tetiary {
          width: 48%;
          float: left;
          margin-right: 2%;
        }

        .btn-default {
          width: 50%;
        }
      }
    }
  }

  .detail__crossselling {
    flex: 1 auto;
    width: 25%;
    margin-top: 42px;
    border: 1px solid @crossselling-border;

    .crossselling__container {
      margin: 10px 15px 10px 10px;
      border-bottom: 2px solid @crossselling-border;

      &:last-of-type {
        border-bottom: none;
      }

      .crossselling__head {
        padding-bottom: 10px;

        .crossselling__label {
          font-size: 16px;
        }

        .crossselling__link {
          float: right;
          position: relative;
          color: @crossselling-link-color;
          margin-right: 7px;

          &:after {
            &:extend(.fa);
            content: @fa-var-angle-right;
            position: absolute;
            top: 1px;
            right: -12px;
          }
        }
      }

      .crossselling__products {
        padding-bottom: 5px;

        .crossselling__product {
          display: flex;
          padding: 5px 0;
          color: @crossselling-link-color;

          &:hover {
            text-decoration: none;
          }

          .crossselling__product-left {
            width: 25%;
          }

          .crossselling__product-right {
            width: 60%;

            .crossselling__brand {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.stock-notification {
  padding: 20px;
  .control-group {
    margin-top: 15px;

    .control-label {
      color: @login-label !important;
      text-transform: uppercase !important;
    }
  }

}

.product__detail {
  .col-no-padding {
    padding: 0;
  }

  .product__details {
    &.product__details--left {
      height: 375px;
      position: relative;

      .product__detail-batches {
        margin-top: 10px;

        .product__detail-batch {
          display: inline-block;
          margin-right: 10px;

          &.product__detail-batch-text {
            padding: 5px 15px;
            background-color: @product-campaign-bg;
            color: @product-campaign-color;
          }
        }
      }

      .product__name {
        font-size: 15px;
        font-weight: 700;
        margin: 10px 0;
      }

      .product__code {
        display: block;
        margin: 10px 0;
      }

      .product__description {
        display: block;
        margin: 10px 0;
        max-height: 140px;
        overflow: hidden;
      }

      .product__stock-info {
        position: absolute;
        bottom: 10px;

        a {
          text-decoration: underline;
        }
      }
    }

    &.product__details--right {
      .product__prices {
        min-height: 100px;
      }

      .product__acquisition {
        display: block;
        margin-bottom: 20px;

        .acquisition__headline {
          display: block;
          font-size: 16px;
          font-weight: 700;
        }

        .acquisition__subline {
          font-weight: 700;
        }

        .acquisition__price {
          float: right;

          &.acquisition__price--red {
            font-size: 15px;
            font-weight: 700;
            color: @product-acquisition-price-color-red;
          }

          &.acquisition__price--grey {
            font-size: 15px;
            font-weight: 700;
            color: @product-acquisition-price-color-grey;
          }
        }
      }

      .product__delivery-info {
        display: block;
        font-size: 13px;
      }

      .detail__actions {
        margin-top: 20px;
      }
    }
  }

  .product__icon-stock-wrapper {
    display: inline-block;

    .product__icon-stock {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;

      &.product__icon-stock--green {
        background: @GREEN;
      }

      &.product__icon-stock--red {
        background: @RED;
      }

      &.product__icon-stock--yellow {
        background: @YELLOW;
      }
    }
  }

  .product__icons {
    display: inline-block;

    .product__icon-delivery {
      display: inline-block;
      width: 30px;
      text-align: center;

      i {
        font-size: 21px;
        color: @SECONDARY;
        vertical-align: bottom;
        position: relative;
        top: -3px;
      }

      .product__icon-delivery-img {
        height: 25px;
        width: 25px;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        top: 8px;

        &.product__icon-delivery-img--truck {
          background-image: url('../images/LKW.svg');
        }

        &.product__icon-delivery-img--truck-v {
          background-image: url('../images/LKW_V.svg');
        }

        &.product__icon-delivery-img--truck-ep {
          background-image: url('../images/LKW_EP.svg');
          width: 30px;
        }
      }
    }

    .product__icon-delivery-char {
      font-size: 22px;
      font-weight: 500;
      color: @SECONDARY;

      &.product__icon-delivery-char--k {
        &:before {
          content: "K";
        }
      }

      &.product__icon-delivery-char--o {
        &:before {
          content: "O";
        }
      }

      &.product__icon-delivery-char--e {
        &:before {
          content: "E";
        }
      }
    }
  }

  .product__detail-links {
    text-align: right;

    .product__detail-link {
      display: inline-block;
      margin-left: 15px;
      text-decoration: underline;
    }
  }
}

.details__sustainability {
  margin-top: 10px;

  .details__sustainability-title {
    font-weight: bold;
    font-size: 16px;
    color: @detail-title-top-color;
  }

  .details__sustainability-body {
    font-size: 14px;
  }
}


.distribution-types {
  display: inline-flex;

  span {
    order: 2;
    line-height: 35px;
  }
}

.go-green__wrapper {
  background-image: url("../../../../../../_ui/responsive/common/images/Go-green-icon_Infonet.png");
  background-repeat: no-repeat;
  background-position: center center;
  display: inline;
  order: 1;

  .go-green__icon {
    width: 20px;
    display: inline-block;
    background-image: none;
  }
}
.slider__wrapper {
  position: relative;
}
.go-green__banner {
  position: absolute;
  left: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  margin: 0;
  visibility: visible;
}

.detail__block--wrapper {
  padding: 0 0 15px 0;

  .detail__block {
    margin-bottom: 5px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    color: #000;
  }

  .detail__title--top {
    display: block;
    width: 100%;
    color: @detail-title-top-color;
    font-weight: 700;
  }

  .detail__block {
    .detail__title {
      display: inline-block;
      width: 50%;
    }

    .detail__desc {
      display: inline-flex;
      vertical-align: top;
      width: 49%;

      .detail__hook {
        display: inline-block;

        &::after {
          &:extend(.fa);
          content: @fa-var-check;
          font-size: 15px;
        }
      }
    }
  }
}

.product__icons.partial .distribution-types .icon {
  margin-left: 10px;
}

.detail__info {
  .info__wrapper {
    width: 49%;
    display: inline-block;
    vertical-align: top;

    .info__container {
      border-bottom: 1px solid @detail-border-color;
      padding: 15px 0;
    }

    .info__title {
      display: inline-block;
      width: 39%;
      color: @detail-title-top-color;
      font-weight: 700;
      white-space: normal;
      vertical-align: top;
    }

    .info__value {
      display: inline-block;
      width: 60%;
      white-space: normal;

      &.info__value--sameline {
        display: inline;
      }
    }
  }
  .product__icons.partial {
    text-align: left;
    display: inline-block;

    .icon {
      font-size: 14px;
      &.icon-status {
        &:after{
          font-size: 14px;
        }

      }
    }
  }

  .icon {
    color: @delivery-icon-color;
    font-size: 14px;
    font-weight: 700;
    &.icon__exclusiveFlag {
      &:after {
        &:extend(.fa);
        content: @fa-var-certificate;
      }
    }
  }
}

@media (max-width: @screen-md) {
  .detail__info {
    .info__wrapper {
      width: 100%;
      display: block;

      .info__value,
      .info__title {
        display: block;
        width: 100%;

        &.info__value--sameline {
          display: inline;
        }
      }
    }
  }
}

.detail_price {
  .price__container-wrapper {

    &.price__container-wrapper--right {
      width: 49%;
      display: inline-block;
      vertical-align: top;
    }

    &.price__container-wrapper--left {
      width: 50%;
      display: inline-block;
      vertical-align: top;

      .price__container {
        padding: 15px 0;
        border-bottom: 1px solid @detail-border-color;

        &.price__container--no-border {
          border: none;
        }

        &.price__container--long {

          .price__text {
            display: inline-block;
            width: 64%;
            float: right;
          }
        }
      }
    }


    .price__title {
      display: inline-block;
      font-weight: 700;
      color: @detail-price-title-color;


      &.price__title--margin {
        margin-left: 15px;
      }
    }

    .price_table {
      display: flex;
      flex-direction: column;
    }

    .price_row {
      display: flex;
    }

    .price_cell {
      flex: 1;
      flex-basis: 40%;
    }

    .price_cell_ttl {
      flex: 1;
      flex-basis: 30%;
    }

    .price_cell_empty {
      flex: 1;
      flex-basis: 30%;
    }

    .price_rowspan {
      flex: 2;
    }

    .price_align-right {
      text-align: right;
    }

    .price__base_price {
      font-weight: bold;
      font-size: 12px;
    }

    .price__text {
      padding: 5px 0;
      display: block;
    }

    .price__block {
      .price__title {
        display: block;
      }

      .price__wrapper {
        margin: 10px 0 10px 25px;

        .price__subtitle {
          display: block;
          margin: 0 25px;
        }

        .text {
          width: 35%;
          display: inline-block;
          vertical-align: top;
        }

        .price {
          width: 30%;
          display: inline-block;

          &.price--red {
            font-weight: 700;
            color: @detail-price-red-color;
          }

          &.price--third {
            width: 29%;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-sm) and (max-width: @screen-md){
  .detail_price {
    .price__container-wrapper {
      .price__block {
        .price__wrapper {
          margin: 10px 0 10px 0px;
        }
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .detail_price {
    .price__container-wrapper {
      .price__block {
        .price__wrapper {
          margin: 10px 0 10px 0px;

          .text {
            display: block;
            width: 100%;
          }

          .price {
            display: block;
            width: 100%;

            &.price--second {
              width: 50%;
              display: inline-block;
            }

            &.price--third {
              width: 48%;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

.detail__test-results {
  .test-results {
  border-left: 5px solid @test-result-border-color;
  padding: 15px 0 5px 20px;
  margin-bottom: 25px;

    .test-results__result {
      font-size: 22px;
      font-weight: 700;
      color: @test-resulst-color;
    }

    .test-results__info {
      font-weight: 700;
    }
  }
}


.branch__wrapper {
.branch {
margin-bottom: 15px;

.branch__headline {
  font-size: 26px;
  font-weight: 700;
  padding: 10px 0;
  border-bottom: 3px solid @tabs-border-color;
}

.branch__table {
  display: table;
  width: 100%;

  .table__row {
    display: table-row;

    &:last-of-type {
      border: none;
    }

    &.row__headline {
      font-weight: 700;
    }

    .table__cell {
      display: table-cell;
      table-layout: fixed;
      padding: 10px;
      border-bottom: 1px solid @tabs-border-color;

      &:nth-child(1) {
        width: 50%;
      }
    }
  }
}
}
}

@media (max-width: @screen-md) {

  .detail_price {
    .price__container-wrapper {
      &.price__container-wrapper--right {
        width: 100%;
      }

      &.price__container-wrapper--left {
        width: 100%;
      }
      .price__container{
        &.price__container--long {
          .price__text {
            width: 100%;
          }
        }
      }

      .price__title {
        width: 100%;
      }
    }
  }

  .product__detail {
  grid-template-areas: "detailImage" "detailContainer";
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;

  .detail__image {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column: 1;
    grid-column-start: 1;

    .product__image {
      padding: 0;

      &:before,
      &:after {
        background: none;
      }

      .image__slider {
        .slick-arrow {
          display: inline-block !important;

          &.slick-disabled {
            display: none !important;
          }
        }
        &.image__slider--single {
          margin-top: 0;
        }
      }
    }
  }

  .detail__wrapper {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;

    .product__prices,
    .product__info {
      &:before,
      &:after {
        background: none;
      }
    }

    .detail__container {
      &.detail__container--first {
        display: none;
      }

      &.mobile__container {
        display: -ms-grid;
        display: grid;
        grid-template-areas: "containerLeft" "containerRight";
        margin: 0;

        &.mobile__container--revert {
          grid-template-areas: "containerRight" "containerLeft";
        }

        .container__left {
          grid-area: containerLeft;
          width: 100%;
          padding: 0;


          &.container__empty {
            display: none;
          }

          .delivery__container {
            &:last-of-type {
              margin-bottom: 15px;
            }
          }

          .delivery__address {
            margin-bottom: 20px;

            span {
              display: inline-block;
            }
          }

          .detail__cta {
            position: initial;
            margin-bottom: 20px;
          }
        }

        .container__right {
          grid-area: containerRight;
          min-width: 100%;
          padding: 0;

          .delivery__container {
            &:last-of-type {
              margin-bottom: 15px;
            }

            .delivery__info--big {
              font-size: 14px;
            }
          }

          .product__icons {
            &.partial {
              position: initial;
              padding: 10px 0;

              &:before,
              &:after {
                width: 0;
                background: none;
              }

              .icon {
                margin-left: 0;
                margin-right: 5px;
              }
            }
          }

          .detail__add2cart {
            .yComponentWrapper {
              margin: 0;
            }
          }
        }

        .product__prices,
        .product__info {
          padding: 0;
        }

        .product__prices {
          margin-bottom: 15px;
        }

        .product__code {
          &.product__code--mobile {
            display: block;
            text-align: left;
          }

          &.product__code--desktop {
            display: none;
          }
        }
      }
    }
  }
}


}

@media (max-width: 1024px) {
  .container__left {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column: 1;
    grid-column-start: 1
  }

  &.mobile__container--revert > .container__left {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  .container__right {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1
  }

  &.mobile__container--revert > .container__right {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }
}

@media (max-width: @screen-md) {
  .detail__info {
    .detail__info-row {
      display: initial;
      border-bottom: none;
      padding: 0;

      .info-row {
        width: 49%;
        display: inline-block;
        padding: 5px 0;
      }
    }

    .detail__info-title {
      color: @detail-title-top-color;
      font-weight: 700;
    }
}

  .detail_price {
    .price__container-wrapper {
      width: 100%;
      display: block;

      .price__block {
        .price__title {
          margin: 15px 0;
        }

        .price__text {
          margin-left: 0;
        }
      }
    }
  }

  .detail__columns {
    .detail__tabs {
      .feedback {
        .write-feedback {
          .btn-tetiary {
            width: 100%;
            float: none;
            margin-bottom: 15px;
          }

          .btn-default {
            width: 100%
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .details__column {
    .detail__block--wrapper {
      .detail__block {
        display: flex;

        .detail__desc {
          display: inline-block;
          text-align: right;
          width: 50%;
        }
      }
    }
  }

  .detail__info {
    .detail__info-row {
      .info-row {
        .detail__info-value {
          text-align: right;
          display: block;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .details__column {
    .detail__block--wrapper {
      .detail__block {
        display: block;
        margin-bottom: 10px;
        .detail__title {
          display: block;
          width: 100%;
        }
        .detail__desc {
          display: block;
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  .detail__info {
    .detail__info-row {
      display: block;
      border-bottom: none;
      padding: 10px 0;
      .info-row {
        width: 100%;
        display: block;
        padding: 0;
        .detail__info-value {
          text-align: left;
        }
      }
    }
  }
}

/////////////////////////////////////////////////////////////
// product-service-information-tab
/////////////////////////////////////////////////////////////

.product-service-information-tab {
  .product-service-information-tab__information-section {
    padding-bottom: 20px;
  }

  .product-service-information-tab__form-section {
    border-top: 1px solid @tab-content-separator;
    padding-top: 20px;
  }

  .product-service-information-tab__headline {
    display: block;
    font-size: 16px;
    color: @feedback-headline-color;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .product-service-information-tab__missing-information-text {
    margin: 15px 0 30px 0;
    font-weight: bold;

    &.product-service-information-tab__missing-information-text--is-hidden{
      display: none;
    }

    &.product-service-information-tab__connection-failed-text--is-hidden{
      display: none;
    }
  }

  .product-service-information-tab__text {
    display: block;
    margin-bottom: 10px;

    &.is-hidden {
      display: none;
    }
  }

  button {
    margin-bottom: 10px;
  }
  
  .product-service-information-tab__form-title {
    width: 100%;
    margin-bottom: 20px;
  }

  .product-service-information-form {
    .control-label {
      color: @FONT-COLOR;
    }

    .help-block {
      span {
        font-size: 14px;
      }
    }

    .btn-tetiary {
      width: 48%;
      float: left;
      margin-right: 2%;
    }

    .btn-default {
      width: 50%;
    }
  }
}

@media (min-width: 800px) {
  .product-service-information-tab {
    .product-service-information-tab__form-section {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .product-service-information-tab__form-text,
    .product-service-information-tab__form {
      width: 50%;
    }

    .product-service-information-tab__form-text {
      padding-right: 10px;
    }

    .product-service-information-tab__form {
      padding-left: 10px;
    }
  }
}

/////////////////////////////////////////////////////////////
// ajax-product-service-information
/////////////////////////////////////////////////////////////
.ajax-product-service-information {
  .ajax-product-service-information__details-key {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
  .ajax-product-service-information__details-contact {
    margin-top: 5px;
  }

  .ajax-product-service-information__free-infos {
    margin: 20px 0;
  }

  .ajax-product-service-information__free-info {
    margin: 10px 0;
  }

  .ajax-product-service-information__free-info-descr {
    font-weight: bold;
  }

  .ajax-product-service-information__free-info-footer-link {
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}

@media(min-width: 421px) {
  .ajax-product-service-information{
    .ajax-product-service-information__details {
      display: flex;
      flex-wrap: wrap;
    }

    .ajax-product-service-information__details {
      align-items: flex-start;
    }

    .ajax-product-service-information__details-key:first-child,
    .ajax-product-service-information__details-key,
    .ajax-product-service-information__details-value {
      margin-top: 10px;
    }

    .ajax-product-service-information__details-key,
    .ajax-product-service-information__details-value {
      width: 50%;
    }
  }
}

@media(min-width: 961px) {
  .ajax-product-service-information{
    .ajax-product-service-information__details {
      max-width: 50%;
    }
  }
}
