.product__info.partial {
	a.product__info-link {
		color: @GRAY;

		&:hover {
			color: @LINK;
		}
	}

	.product__info-code {
		display: block;
		font-size: .93em;
		font-weight: 200;
	}

	.product__info-product-line,
	.product__info-brand {
		font-size: 1.14em;
		font-weight: 200;
    }

    .product__info-attribute {
		display: block;
		font-size: 0.93em;
		font-weight: 700;
	}

	.product__info-color,
	.product__info-name {
		display: inline;
		font-size: 1.28em;
		font-weight: 700;
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			color: @LINK;
			text-decoration: underline;
		}
	}
	
	.product__info-description-wrapper{
		display: block;
		margin-top: 5px;
	}
	
	.product__info-description{
		display: block;
	}
}