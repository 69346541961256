.category {
  padding-bottom: 5px;
  border-bottom: 2px solid @category-border-color;
  font-size: 12px;

  .category__headline {
    background: @category-headline-bg;
    padding: 5px;
    color: @category-headline-color;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &.is-open {
      &:after {
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
      }
    }

    &:after {
      &:extend(.fas);
      content: @fa-var-angle-up;
      position: absolute;
      right: 5px;
      top: 8px;
      -webkit-transition: -webkit-transform .3s ease-in-out;
      transition: -webkit-transform .3s ease-in-out;
      transition: transform .3s ease-in-out;
    }
  }

  .category__list {
    list-style: none;
    display:none;
    padding: 0 10px;

    &.is-open {
      display: block;
    }

    .list__item {
      margin: 9px 0;

      &:before {
        &:extend(.fas);
        content: @fa-var-angle-right;
        padding-right: 5px;
      }

      a {
        color: @category-list-item-color;
      }
    }
  }
}
.facet__show {
  padding-top: 5px;
  display: block;
}

.product__facet {
  padding-top: 10px;

  .facet {
    padding: 10px 0;
    border-bottom: 2px solid @GRAY-LIGHTER;
    font-size: 12px;
  }

  .facet__list {
    padding: 0 1px;
    max-height: 175px;
    overflow: hidden;
    overflow-y: auto;

    &--selected {
      padding-left: 10px;

      a {
        &:focus {
          text-decoration: none;
        }
      }
    }

    a {
      &:hover {
        text-decoration: none;

      }
    }

    &__count {
      -ms-grid-row-align: center;
      align-self: center;
    }
  }

  .facet__list__checkbox {
    margin: 0;
    height: 0;
  }

  .facet__item {
    display: flex;
    list-style: none;
    padding-bottom: 5px;
    label, i {
      cursor: pointer;
    }
  }

  .facet__name {
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    position: relative;

    &.is-open {
      &:after {
        transform: rotate(180deg);
      }
    }

    &:after {
      &:extend(.fas);
      content: @fa-var-angle-up;
      position: absolute;
      right: 4px;
      top: 2px;
      -webkit-transition: -webkit-transform .3s ease-in-out;
      transition: -webkit-transform .3s ease-in-out;
      transition: transform .3s ease-in-out;
      visibility: hidden;
    }

    &.facet__name--category {
      &:hover {
        cursor: pointer;
      }
      &:after {
        visibility: visible;
      }
    }
  }

  .facet__values {
    &.facet__values--category {
      display: none;
      &.is-open {
        display: block;
      }
    }
  }

  .facet__label {
    margin: 0;
    font-weight: normal;
    color: @GRAY;
    display: inline;
    flex-grow: 1;
	font-size: 12px;
  }

  .facet__link,
  .facet__text {
    color: @GRAY;
    display: flex;
    flex-grow: 1;
  }

  i {
    display: inline-block;
    padding-right: 5px;
    -ms-grid-row-align: center;
    align-self: initial;
    padding-top: 4px;

    &.fa-times, &.fa-trash-alt {
      color: @SECONDARY;

      &:hover {
        color: @HOVER;
      }
    }
  }

   form {
    display: flex;
     flex-grow: 1;
  }
  .facet__filter {
    padding-bottom: 10px;
    position: relative;

    .facet__filter-input {
      padding-right: 30px;
      width: 100%;
      height: 30px;
      padding-left: 5px;
    }

    .facet__filter-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 20px;
      padding: 0;
      margin: 0;
      color: @GRAY;

      &:hover {
        color: @GRAY;
      }
    }
  }

  .facet__price {
    margin-top: 5px;

    &--min {
      float: left;
    }

    &--max {
      float: right;
    }
  }

  .facet__price-slider {
    width: 95%;
    margin-left: 6px;
    margin-bottom: 45px;
    height: 30px;

    .priceslider__inputw--wrapper {
      margin-bottom: 15px;
      text-align: center;

      .price-slider__input {
        width: 25%;

        &.priceslider__input--left {
          float: left;
        }
        &.priceslider__input--right {
          float: right;
        }
      }
    }
  }
}

.noUi-tooltip {
  display: none;
}

.noUi-active .noUi-tooltip {
  display: block;
}

@media (max-width: @screen-md) {

  .category {
    font-size: 14px;
  }

  .facet__show {
    font-size: 20px;
    padding-top: 0;
    margin-right: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .product__facet {
    display: none;
    padding: (@grid-gutter-width/2);
    margin: 0;
    border: 0 none;

    #colorbox & {
      display: block !important;
    }

    .facet {
      float: none;
      width: 100%;
      border: 0;
      margin: 0;
      padding: 0 0  (@grid-gutter-width/2) 0;
      font-size: 14px;

      .facet__name {
        background: @table-2n-line-bg;
        cursor: pointer;
        padding: 15px;
        text-align: center;
        text-transform: uppercase;
        position: relative;

        &:after {
          right: 10px;
          top: 10px;
          visibility: visible;
          font-size: 30px;
        }
      }

      .facet__filter-input,
      .facet__price-slider {
        margin-top: 25px;
      }


      .facet__list {
        margin-top: 20px;
        padding-left: 15px;
        list-style: none;
      }

      .facet__values {
        display: none;

        &.is-open {
          display: block;
        }
      }
    }

    .facet.active {

      .facet__arrow {
        &:before {
          content: "\e114";
        }
      }

      .facet__values {
        display: block;
        margin-bottom: 20px;
      }

    }
  }

  .facet__price-slider-form {
    margin-top: 20px;
    width: 90%;
    margin-left: 20px;
  }

  .product__facet .facet__price {
    margin-top: 10px;
  }

  .facet__name--category {
    &:after {
      right: 10px;
      top: 5px;
      font-size: 33px;
    }
  }
}
