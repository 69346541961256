.image-gallery{
	img{
		width: 100%;
		height: auto;
		-webkit-touch-callout: none;
	}

	.zoomImg{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		max-height: none;
		max-width: none;
		z-index: 1000;
		border:0;
		opacity:0;

		img{
			width: 100%;
			height: 100%;
		}
	}




	.zoomImgMask{
		
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10000;
	}
}