@fontsBaseDir: "../templatefonts";

@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=55a69a76-732a-4340-a0a6-8ef58316e70e");

@font-face{
  font-family: "Neue Helvetica W01";
  src: url("../templatefonts/e0781a75-0ecb-464e-b6e9-85780ddc0921.eot?");
  src: url("../templatefonts/e0781a75-0ecb-464e-b6e9-85780ddc0921.eot?") format("eot"),
  url("../templatefonts/b8765d4b-d9a3-48b9-ac65-560e7517cf0e.woff2") format("woff2"),
  url("../templatefonts/d7d2e6c6-fc3a-41a5-9b52-648e12e215b2.woff") format("woff"),
  url("../templatefonts/cb64744b-d0f7-4ef8-a790-b60d6e3e4f21.ttf") format("truetype"),
  url("../templatefonts/ccde919e-a712-41ef-b90f-9efc47661659.svg#ccde919e-a712-41ef-b90f-9efc47661659") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Neue Helvetica W01";
  src: url("../templatefonts/fc780ce0-5b33-4e1d-b89b-66111a4d0ed8.eot?#iefix");
  src: url("../templatefonts/fc780ce0-5b33-4e1d-b89b-66111a4d0ed8.eot?#iefix") format("eot"),
  url("../templatefonts/0381d699-73ef-4558-9e61-38385c9fc5da.woff2") format("woff2"),
  url("../templatefonts/2731e338-d92f-4492-8480-8b55ae1139f6.woff") format("woff"),
  url("../templatefonts/40e43cb5-d6b2-4ceb-b369-527d1007a2c7.ttf") format("truetype"),
  url("../templatefonts/c1993d56-4077-454a-8b19-635fa5a39532.svg#c1993d56-4077-454a-8b19-635fa5a39532") format("svg");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Neue Helvetica W01";
  src: url("../templatefonts/706ec8e5-fe4a-4518-91a6-7aba4d3d333f.eot?#iefix");
  src: url("../templatefonts/706ec8e5-fe4a-4518-91a6-7aba4d3d333f.eot?#iefix") format("eot"),
  url("../templatefonts/dc50c02f-3f77-4e75-b89c-e3f9bb4752e6.woff2") format("woff2"),
  url("../templatefonts/ce6f5b38-1cb5-4a27-8182-583aa68b2436.woff") format("woff"),
  url("../templatefonts/ff06cde7-e06a-4cf0-af4d-5b2f737bf544.ttf") format("truetype"),
  url("../templatefonts/958b12cf-2908-4254-8cb9-9b12c3aa151e.svg#958b12cf-2908-4254-8cb9-9b12c3aa151e") format("svg");
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: "Neue Helvetica W01";
  src: url("../templatefonts/5935d076-bd6d-4ca4-b995-d739ffa09754.eot?#iefix");
  src: url("../templatefonts/5935d076-bd6d-4ca4-b995-d739ffa09754.eot?#iefix") format("eot"),
  url("../templatefonts/56e66f56-3016-44fd-84b2-8e40d9743656.woff2") format("woff2"),
  url("../templatefonts/4d65bb6f-e667-4bd0-8e92-34e67bf6ac8b.woff") format("woff"),
  url("../templatefonts/86631d82-6e27-4db2-988a-6b79a6c0d8ed.ttf") format("truetype"),
  url("../templatefonts/205c950e-e47f-4d97-9738-0a68edc4258d.svg#205c950e-e47f-4d97-9738-0a68edc4258d") format("svg");
  font-weight: 400;
  font-style: italic  ;
}


@font-face {
  font-family: "Neue Helvetica W01";
  src: url("../templatefonts/a747e1ea-ecdf-4f3d-ad47-3df9cfbee3cc.eot?#iefix");
  src: url("../templatefonts/a747e1ea-ecdf-4f3d-ad47-3df9cfbee3cc.eot?#iefix") format("eot"),
  url("../templatefonts/3e8a8b56-3cb0-4347-b670-eaaf06b76e9b.woff2") format("woff2"),
  url("../templatefonts/07173950-fa69-4b65-9c71-0cf2ec00b51d.woff") format("woff"),
  url("../templatefonts/aac5a4b8-ffd3-4529-a098-21b91bc86f9a.ttf") format("truetype"),
  url("../templatefonts/2379b081-2371-47d9-8be9-4e39d7892ebf.svg#2379b081-2371-47d9-8be9-4e39d7892ebf") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Neue Helvetica W01";
  src: url("../templatefonts/4da0f075-0a40-4129-a4df-43aa140d94b0.eot?#iefix");
  src: url("../templatefonts/4da0f075-0a40-4129-a4df-43aa140d94b0.eot?#iefix") format("eot"),
  url("../templatefonts/7d8282ed-f0c0-49f6-99f1-95be8623cbf1.woff2") format("woff2"),
  url("../templatefonts/2620c12a-dedb-4d35-8f4c-b951dcc1d4df.woff") format("woff"),
  url("../templatefonts/ae4300e1-4ab3-46c8-b619-0d142d97522c.ttf") format("truetype"),
  url("../templatefonts/4e787888-465d-4004-b616-6565e88fbe50.svg#4e787888-465d-4004-b616-6565e88fbe50") format("svg");
  font-weight: 500;
  font-style: italic;
}


@font-face {
  font-family: "Neue Helvetica W01";
  src: url("../templatefonts/a95d2895-fa71-4ce7-bc1e-631169924be3.eot?#iefix");
  src: url("../templatefonts/a95d2895-fa71-4ce7-bc1e-631169924be3.eot?#iefix") format("eot"),
  url("../templatefonts/6dfa3ef5-ebc5-4d23-a631-b3d746c2ad39.woff2") format("woff2"),
  url("../templatefonts/588c9ba8-1d28-4ad6-bf18-7128152d84c4.woff") format("woff"),
  url("../templatefonts/55334dfb-9c72-4147-8de0-21239f17ff25.ttf") format("truetype"),
  url("../templatefonts/62455047-c81f-44f1-8938-7739fa0f0e8d.svg#62455047-c81f-44f1-8938-7739fa0f0e8d") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Neue Helvetica W01";
  src: url("../templatefonts/a48e33d0-3542-482d-9284-e41d31c4d583.eot?#iefix");
  src: url("../templatefonts/a48e33d0-3542-482d-9284-e41d31c4d583.eot?#iefix") format("eot"),
  url("../templatefonts/15ed0ff2-0715-4aca-af0c-00840c3a4dd7.woff2") format("woff2"),
  url("../templatefonts/9b77b336-b221-497e-b255-7833856bb4a9.woff") format("woff"),
  url("../templatefonts/5c9a7798-4870-4139-ab84-7a300a7dc3e1.ttf") format("truetype"),
  url("../templatefonts/3b59e91c-919a-4e7a-9ada-8c73b3378f03.svg#3b59e91c-919a-4e7a-9ada-8c73b3378f03") format("svg");
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: "Neue Helvetica W01 Outline";
  src: url("../templatefonts/9dc6b540-d36d-447c-9506-2efc57b08dcd.eot?#iefix");
  src: url("../templatefonts/9dc6b540-d36d-447c-9506-2efc57b08dcd.eot?#iefix") format("eot"),
  url("../templatefonts/9ee0873c-9714-4d70-885e-d886b42a2089.woff2") format("woff2"),
  url("../templatefonts/3b5acf42-062d-4f4b-ba86-4ecec66620f2.woff") format("woff"),
  url("../templatefonts/f40c0a50-b46e-4973-a683-e362217b8d3e.ttf") format("truetype"),
  url("../templatefonts/10f78320-fe9b-4e26-b835-37be049b1c0f.svg#10f78320-fe9b-4e26-b835-37be049b1c0f") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.dev.electronicpartner.io/ui/font/icons.woff2") format('woff2');
  font-display: swap;
}

/* libre-franklin-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 100;
  src: url('../templatefonts/libre-franklin-v14-latin-100.eot'); /* IE9 Compat Modes */
  src: url('../templatefonts/libre-franklin-v14-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../templatefonts/libre-franklin-v14-latin-100.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../templatefonts/libre-franklin-v14-latin-100.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../templatefonts/libre-franklin-v14-latin-100.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../templatefonts/libre-franklin-v14-latin-100.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-100italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Franklin';
  font-style: italic;
  font-weight: 100;
  src: url('../templatefonts/libre-franklin-v14-latin-100italic.eot'); /* IE9 Compat Modes */
  src: url('../templatefonts/libre-franklin-v14-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../templatefonts/libre-franklin-v14-latin-100italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../templatefonts/libre-franklin-v14-latin-100italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../templatefonts/libre-franklin-v14-latin-100italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../templatefonts/libre-franklin-v14-latin-100italic.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 200;
  src: url('../templatefonts/libre-franklin-v14-latin-200.eot'); /* IE9 Compat Modes */
  src: url('../templatefonts/libre-franklin-v14-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../templatefonts/libre-franklin-v14-latin-200.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../templatefonts/libre-franklin-v14-latin-200.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../templatefonts/libre-franklin-v14-latin-200.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../templatefonts/libre-franklin-v14-latin-200.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Franklin';
  font-style: italic;
  font-weight: 200;
  src: url('../templatefonts/libre-franklin-v14-latin-200italic.eot'); /* IE9 Compat Modes */
  src: url('../templatefonts/libre-franklin-v14-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../templatefonts/libre-franklin-v14-latin-200italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../templatefonts/libre-franklin-v14-latin-200italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../templatefonts/libre-franklin-v14-latin-200italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../templatefonts/libre-franklin-v14-latin-200italic.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  src: url('../templatefonts/libre-franklin-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../templatefonts/libre-franklin-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../templatefonts/libre-franklin-v14-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../templatefonts/libre-franklin-v14-latin-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../templatefonts/libre-franklin-v14-latin-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../templatefonts/libre-franklin-v14-latin-regular.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Franklin';
  font-style: italic;
  font-weight: 400;
  src: url('../templatefonts/libre-franklin-v14-latin-italic.eot'); /* IE9 Compat Modes */
  src: url('../templatefonts/libre-franklin-v14-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../templatefonts/libre-franklin-v14-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../templatefonts/libre-franklin-v14-latin-italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../templatefonts/libre-franklin-v14-latin-italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../templatefonts/libre-franklin-v14-latin-italic.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 500;
  src: url('../templatefonts/libre-franklin-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../templatefonts/libre-franklin-v14-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../templatefonts/libre-franklin-v14-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../templatefonts/libre-franklin-v14-latin-500.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../templatefonts/libre-franklin-v14-latin-500.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../templatefonts/libre-franklin-v14-latin-500.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Franklin';
  font-style: italic;
  font-weight: 500;
  src: url('../templatefonts/libre-franklin-v14-latin-500italic.eot'); /* IE9 Compat Modes */
  src: url('../templatefonts/libre-franklin-v14-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../templatefonts/libre-franklin-v14-latin-500italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../templatefonts/libre-franklin-v14-latin-500italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../templatefonts/libre-franklin-v14-latin-500italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../templatefonts/libre-franklin-v14-latin-500italic.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 700;
  src: url('../templatefonts/libre-franklin-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: url('../templatefonts/libre-franklin-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../templatefonts/libre-franklin-v14-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../templatefonts/libre-franklin-v14-latin-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../templatefonts/libre-franklin-v14-latin-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../templatefonts/libre-franklin-v14-latin-700.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Franklin';
  font-style: italic;
  font-weight: 700;
  src: url('../templatefonts/libre-franklin-v14-latin-700italic.eot'); /* IE9 Compat Modes */
  src: url('../templatefonts/libre-franklin-v14-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../templatefonts/libre-franklin-v14-latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../templatefonts/libre-franklin-v14-latin-700italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../templatefonts/libre-franklin-v14-latin-700italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../templatefonts/libre-franklin-v14-latin-700italic.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
