.form-component{
	max-width: 580px;
	margin: 0 auto;

	.form-component__container{
		padding-bottom: 15px;
	}

	.form-component__container--label{
		color: @login-label !important;
		text-transform: uppercase !important;
	}

	.form-component__container--error-msg{
		display: none;
		color: #8f0c09;
		font-style: italic; 
	}

	.form-component__container--button-container{
		list-style-type: none;
		padding-right: 50px;
	}

	.form-component__container--button-group{
		padding-top: 5px;
		padding-bottom: 5px;
		display: flex;
		justify-content: space-between;
	}

	.form-component__container--reset-input {
		align-self: center;
		margin-right: 5px;
	}

	.form-component__container--button-list{
		margin-bottom: 0;
		display: flex;
		flex-wrap: wrap;
	}

	.form-component__container--button-input{
		margin-top: 0px;
		vertical-align: middle;
	}

	.form-component__container--button-group--has-reset-btn{
		.form-component__container--x-button{
			margin-left: 10px;
		}
	}

	.form-component__buttons{
		.clearfix;
	}

	.form-component__buttons--button{
		margin-top: 5px;
	}

	.form-component__container--file-upload{
		display: inline-block;
		text-align: center;
		border: 2px solid #e3e3e3;
		padding: 5px;
	}

	.form-component__container--file-upload-input{
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	.upload__icon{
		font-size: 35px;
		color: @SECONDARY;
		padding-right: 4px;

		&:after{
			&:extend(.fa);
			content: @fa-var-file-upload;
		}
	}

	.form-component__container--file-upload-label{
		border: 2px solid #e3e3e3;
		text-align: center;
		padding: 10px;
		font-size: 1em;
		cursor: pointer;
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}

	.form-component__container--file-upload-input:focus + .form-component__container--file-upload-label,
	.form-component__container--file-upload-input + .form-component__container--file-upload-label:hover,
	.is-dragover {
		color: #95979a !important;

		.upload__icon {
			color: @HOVER;
		}
	}

	.form-component__container--file-upload-input:disabled + .form-component__container--file-upload-label{
		opacity: 0.6;
		pointer-events: none;
	}

	.has-dragndrop{
		display: none;
	}

	.has-advanced-upload .has-dragndrop{
		display: inline;
	}

	
	.form-component__container--file-upload-attached{
		padding: 10px 5px 10px 5px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	.form-component__container--file-upload-attached:not(:first-child) {
		border-top: 2px solid #e3e3e3;
	}
	
	.form-component__container--file-upload-file {
		display: flex;
		justify-content: space-between;
		align-items: center;
		white-space: nowrap;
		flex-direction: row;
		width: 100%;
	}

	.form-component__container--file-upload-file-name{
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 700;
	}
	.form-component__container--file-upload-file-size{
		font-style: italic;
	}

	.form-component__container--file-upload-file-info {
		display: flex;
		flex-direction: column;
		align-items: left;
	}
	.form-component__container--file-upload-file-progress {
		display: block;
		width: 100%;

		progress {
			width:100%;
		}
	}
	.form-component__container--file-upload-remove-file {
		align-self: center;
	}


	.has-error{
		.form-component__container--error-msg{
			display: block;
		}

		.form-component__container--button-group{
			border: 1px solid #8f0c09;
		}
		.form-component__container--file-upload-label {
			border: 1px solid #8f0c09;
		}
	}
}

@media(min-width: 500px){
	.form-component{
		.form-component__container--button-group--has-reset-btn,

		.has-error{
			.form-component__container--x-button{
				right: 4px;
			}
		}
	}
}

@media(min-width: @screen-xs){
	.form-component{
		.form-component__buttons--button{
			width: auto;
			margin-top: 0;
			margin-left: 15px;
			float: right;
		}
	}
}

.cmsformcomponent-colorbox{
	text-align: center;
	
	&.cmsformcomponent-colorbox--loading{
		.cmsformcomponent-colorbox__close-btn{
			display: none;
		}
	}

	&.cmsformcomponent-colorbox--success{
		.cmsformcomponent-colorbox__icon{
			color: @login-label;
		}
	}

	&.cmsformcomponent-colorbox--error{
		.cmsformcomponent-colorbox__icon{
			color: #8f0c09;
		}
	}

	.cmsformcomponent-colorbox__icon{
		font-size: 45px;
		margin-bottom: 10px;
	}

	.cmsformcomponent-colorbox__close-btn{
		margin-top: 10px;
	}
}