.account-section {
  .account-section-content {

    .label-value {
      display: block;
      font-weight: 600;
      margin-bottom: 15px;

      @media (min-width: @screen-sm-min){
        margin-top: 10px;
      }
    }

    .accountActions {
      .half-width-padding();
    }

    .accountActions-link {
      clear: both;
      text-align: right;
      .full-width-padding();

      @media (max-width:@screen-xs-max) {
        margin-top: (@grid-gutter-width + 5);
      }

      .disable-link, .enable-link {

        @media (max-width:@screen-xs-max) {
          margin:8px 0;
        }
      }
    }
  }

  .account-list {
    .account-cards {
      .card {
        .glyphicon-ok {
          font-size: 20px;
          color: @text-color-secondary;
          padding-right: 15px;
          padding-top: 15px;
        }

        .roles-wrapper {
          margin: 8px 0 5px;

          > ul {
            display: block;
            width: 100%;
          }
        }
      }

      .selected {
        background-color: @list-active-bg;

        .glyphicon-ok {
          color: @link-color;
        }

        &:first-child {
          border-left: 1px solid @border-color-3;
        }
      }
    }
    .search-empty {
      margin: 0;
      padding: @grid-gutter-width;
      border-bottom: 1px solid @border-color-3;
      border-right: 1px solid @border-color-3;
    }
    .remove-item,
    .edit-item {
      color: @border-color-2;
    }
  }
  .account-header-done-btn {
    margin-top: 15px;
    padding-right: 30px;

    @media (max-width: @screen-md) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
.account-status-inactive {
  color:@text-color-secondary;
}

.accountActions-bottom {
  clear: both;
  margin-top: 35px;
  .full-width-padding();
}

@media (max-width: @screen-xs-max){
  .account-section {
    .account-section-content {
      #addNewPermissionForm {
        form {
          .confirm {
            margin-bottom: @grid-gutter-width;
          }
        }
      }

      .accountActions-bottom {
        .btn-default {
          margin-top: 0;
        }

        .btn.confirm {
          margin-bottom: 30px;
        }
      }
    }

    .pagination-bar.top + .account-list .account-cards {
      margin-top: -1px;
    }

    .account-list {
      .account-list-header {
        padding-left: @grid-gutter-width;
        padding-right: @grid-gutter-width;
      }
      
      .account-cards {
        border: none;

        .card {
          border-left: 0;
          border-right: 0;

          &:first-child {
            margin: 0;
          }
        }

        .selected{
          &:first-child {
            border-left: none;
          }
        }
      }

      .add-user-action-menu {
        .add-user-action-buttons {
          display: none;
        }

        &.open {
          .add-user-action {
            background-color: @btn-primary-bg;
            color: @btn-primary-color;
            padding: 16px 20px;
            text-transform: uppercase;
          }

          .add-user-action-buttons {
            display: block;
            position: absolute;
            right: 0;
            width: 120px;
            top: 38px;
            z-index: 9000;
          }
        }
      }
    }

    #unittree {
      padding-left: 0;
      padding-right: 0;
    }
  }
}