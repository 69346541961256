/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('../templatefonts/slick.eot');
    src: url('../templatefonts/slick.eot?#iefix') format('embedded-opentype'), url('../templatefonts/slick.woff') format('woff'), url('../templatefonts/slick.ttf') format('truetype'), url('../templatefonts/slick.svg#slick') format('svg');
}



.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &.slick-initialized {
        visibility: visible;
        opacity: 1;
    }

    .slick-dots {
        text-align: center;
        li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;
            text-align: center;

            &.slick-active {
                button {
                    &:before {
                        color: @PRIMARY;
                    }
                }
            }

            button {
                font-size: 0;
                line-height: 0;
                display: block;
                width: 20px;
                height: 20px;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                background: transparent;

                &:before {
                    font-family: 'slick';
                    font-size: 6px;
                    line-height: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    content: '•';
                    text-align: center;
                    opacity: .25;
                    color: black;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }

            }
        }
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-vertical {
    .slick-track {
        display: block;
    }

    .slick-slide {
        -ms-grid-row-align: unset;
        align-self: unset;

        .slick-initialized & {
            display: block;
            flex-direction: unset;
            justify-content: unset;
            align-content: unset;
            align-items: unset;
        }
    }
}

#product_slider {
    .slick-slide {
        min-height: 380px;
    }
}

.slick-slide {
	align-self: flex-start;
    float: left;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        max-width: 100%;
        margin: 0 auto;
        display: block;

        &[data-zoom-src] {
            cursor:zoom-in;
        }

    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: center;
    }

    .slick-selected .thumb-slider__item:after {
        cursor: default;
        &:after {
            opacity: 0.4
        }
    }

    .thumb-slider__item{
        position: relative;
        display: block;
        border: 1px solid @thumb-slider-item-border;
        overflow: hidden;

        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            opacity: 0;
            transition: opacity 0.3s;
            content: '';
            background: rgba(0,0,0, 0.7) url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMTQ5NyA4NzMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPg0KPG1ldGFkYXRhPg0KPHJkZjpSREY+DQo8Y2M6V29yayByZGY6YWJvdXQ9IiI+DQo8ZGM6Zm9ybWF0PmltYWdlL3N2Zyt4bWw8L2RjOmZvcm1hdD4NCjxkYzp0eXBlIHJkZjpyZXNvdXJjZT0iaHR0cDovL3B1cmwub3JnL2RjL2RjbWl0eXBlL1N0aWxsSW1hZ2UiLz4NCjxkYzp0aXRsZS8+DQo8L2NjOldvcms+DQo8L3JkZjpSREY+DQo8L21ldGFkYXRhPg0KPHBhdGggZD0ibTEyNDcuNSA2NDQuNXEwIDEzLTEwIDIzbC01MCA1MHEtMTAgMTAtMjMgMTB0LTIzLTEwbC0zOTMtMzkzLTM5MyAzOTNxLTEwIDEwLTIzIDEwdC0yMy0xMGwtNTAtNTBxLTEwLTEwLTEwLTIzdDEwLTIzbDQ2Ni00NjZxMTAtMTAgMjMtMTB0MjMgMTBsNDY2IDQ2NnExMCAxMCAxMCAyM3oiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjgiLz4NCjwvc3ZnPg0K") no-repeat center;
        }

    }

    &.slick-selected .thumb-slider__item:after {
    	opacity: .4;
	}

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-down,
.slick-up {
    border: none;
    background-color: @WHITE;
    cursor: pointer;
    width: 100%;
    position: absolute;
    height: 25px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: auto;
    bottom: auto;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);

    &:before {
        content: "";
        width: 35px;
        height: 35px;
        position: absolute;
        transform: rotate(45deg);
        border: 1px solid @FONT-COLOR;
        left: 50%;
        top: 0;
        box-sizing: border-box;
        transform-origin: 0 0;

    }

}

.slick-up {
    top: 0;
    left: 0;

    &:before {
        border-right:0;
        border-bottom:0;
    }

}
.slick-down {
    bottom: 0;
    left: 0;

    &:before {
        border-left:0;
        border-top:0;
        top:-100%;
    }
}


.slick-disabled {
    display: none !important;
}




