.ep-slider{
	margin-top: 10px;
	
	.ep-slider__inputs_wrapper,
	.ep-slider__labels_wrapper{
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-start;
	}
	
	.ep-slider__input-start,
	.ep-slider__input-end{
		width: 25%;
		
		&:enabled{
			border: 1px solid;
			border-radius: 3px;
		}
	}
	
	.ep-slider__slider{
		margin-top: 15px;
		margin-bottom: 10px;
	}
	
	.ep-slider__label-min,
	.ep-slider__label-max{
		flex: 0 1 auto;
		max-width: 50%;
	}
	
	.ep-slider__label-min{
		padding-right: 10px;
	}
	
	.ep-slider__label-max{
		padding-left: 10px;
	}
}

@media(min-width: 1024px){
	.ep-slider{
		margin-top: 0;
		
		.ep-slider__slider{
			margin-top: 10px;
			margin-bottom: 5px;
		}
	}
}