///////////////////////////////////////////
//   configuration page styling          //
///////////////////////////////////////////

#textFieldConfigurationForm,
.textFieldConfigurationForm {
  .full-width-padding();
}

.product__config {
  margin-bottom: 25px;
}

.product__config .product__config-row {
  margin-bottom: 10px;
}