.product-item-price-wrapper {
	font-size: 12px;
	margin-bottom: 5px;

	.row {
		margin: 0 !important;
	}

	.col-xs-6 {
		padding: 0 !important;
	}

	.product-item-price-container {
		text-align: right;
	}

	.product-item-price {

		&.product-item-price--strike {
			text-decoration: line-through;
		}

		&.product-item-price--new {
			display: block;
			font-weight: 700;
			color: @newPriceColor;
		}
	}
}