.btn{
	text-transform: uppercase;
	font-weight: 600;
	padding: 10px 15px;
	font-size: 16px;
	line-height: 1.375;
	display: block;
	border: @btn-border-width solid;
	cursor: pointer;

	&:hover {
		cursor: pointer;
	}

	&.btn-small {
		max-width: 300px;
		position: absolute;
		right: 0;
		bottom: 5px;
	}

}

@media (max-width: 360px) {
	.btn {
		font-size: 14px;
	}
}

@media (max-width: @screen-xs) {
	.btn {
		&.btn-small {
			float: none;
			margin: 0 auto;
			position: initial;
		}
	}
}


.btn-default {
	.button-variant(@btn-default-color; @btn-default-bg; @btn-default-border; @btn-default-hover);
}

.btn-primary {
	.button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border; @btn-primary-bg-hover);
}

.btn-tetiary {
	.button-variant(@btn-tertiary-color; @btn-tertiary-bg; @btn-tertiary-border; @btn-tertiary-hover);
}

.btn-a2c {
	.button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border; @btn-primary-bg-hover);
}

.linkAsButton(@backgroundColor, @backgroundColorHover) {
	background-color: @backgroundColor;
	will-change: background-color;
	transition: background-color 0.3s;
	cursor: pointer;
	display: inline-block;
	border: 0;
	padding: 0 10px;
	color: @WHITE;

	&:disabled {
		opacity: 0.6;
	}

	&:hover,
	&:active {
		background-color: @backgroundColorHover;
		color: @WHITE;
		cursor: pointer;
	}

}