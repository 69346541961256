.ellip {
  display: block;
  height: 100%;
}

.ellip-line {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.ellip {
  font-weight: 700;
  padding-left: 5px;
}

@media (max-width: 960px)  {
  .ellip {
    padding-left: 0;
  }
}