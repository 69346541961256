.seeking-features-widget {
	position: relative;
	width: 100%;
	
	&.seeking-feature--search-enabled{
		.seeking-feature {
			.seeking-feature__header {
				.seekging-feature__top {
					.seekging-feature__search--wrapper{
						display: block;
					}
				}
			}
		}
	}
	
	.js-checkbox{
		display: none;
	}
}

.overlay-widget{
	&.overlay-widget--seeking-features{
		
		.overlay-widget__overlay{
			width: 100%;
		}
		
		.overlay-widget__content{
			padding-top: 0;
		}
	}
}

.seeking-feature {
	.seeking-feature__no-entry {
		display: none;
		padding-top: 5px;
	}

	.btn.btn-small{
		float: none;
		max-width: none;
		width: 100%;
	}

	.btn.btn-small{
		float: right;
		position: static;
	}

	.accordion .accordion__header{
		min-height: 45px;
	}

	.seeking-feature__header {
		.seeking-feature__button{
			margin-top: 15px;
		}

		.seekging-feature__top {
			.clearfix;
			 margin-bottom: 15px;

			.seekging-feature__search--wrapper {
				position: relative;
				display: none;

				.seeking-feature__search {
					height: 44px;
					line-height: 40px;
					font-size: 15px;
					padding: 6px 12px;
					width: 100%;
					color: #717375;
					border: 1px solid #ccc;
					box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
					
					&::-webkit-input-placeholder{
						opacity: 0.8;
						font-weight: lighter;
					}

					&::-moz-placeholder{
						opacity: 0.8;
						font-weight: lighter;
					}

					&:-ms-input-placeholder{
						opacity: 0.8;
						font-weight: lighter;
					}

					&:-moz-placeholder{
						opacity: 0.8;
						font-weight: lighter;
					}

					&:placeholder-shown{
						opacity: 0.8;
						font-weight: lighter;
					}

					&::placeholder{
						opacity: 0.8;
						font-weight: lighter;
					}

					&:focus{
						border: 2px solid #168727;
						outline: 0;
						box-shadow: none;
						opacity: 1;
					}
				}

				.feature__search--icon {
					position: absolute;
					top:0;
					right: 1px;
					cursor: pointer;
					color: #717375;
					outline: 0;
					padding: 0;
					margin: 0;
					border: 0;
					height: 44px;
					width: 34px;
					
					i {
						font-size: 28px;
						color: #004b07;
						line-height: 44px;
					}
				}
			}
		}

		.seeking-feature__links {
			.clearfix;
			display: flex;
			flex-direction: column;
			margin-bottom: 10px;
			border-bottom: 1px solid #000;
			padding-bottom: 10px;

			.links__left,
			.links__right,
			.seeking-feature__results-count{
				flex: 0 1 auto;
				text-align: right;
				margin-top: 5px;
				align-content: space-between;
			}

      .links__left {
        order: 3;
        .link__reset {
          font-size: 14px;
        }
      }
      
      .links__right{
        order: 2;
      }
      
      .seeking-feature__results-count{
        text-align: center;
        order: 1;
        padding-top: 4px;
        line-height: 25px;
        margin-top: 0;
      }
    }
    
    .link_open_close,
    .link__reset{
      margin-right: 0;
    }
    
    .link__reset{
      line-height: 25px;
    }
    
    .link_open_close{
      display: none;
      text-decoration: none;
      line-height: 0;
      
      &.initialized{
        display: block;
      }
      
      &:after{
       &:extend(.fa);
        content: @fa-var-angle-down;
        font-size: 25px;
        position: relative;
        top: 4px;
      }
      
      .link_close_text,
      .link_open_text,
      &:after{
        line-height: 25px;
      }
      
      .link_close_text{
        display: none;
        text-decoration: underline;
      }
        
      .link_open_text{
        display: inline-block;
        text-decoration: underline;
      }
      
      &.all-open{
        &:after{
          content: @fa-var-angle-up;
        }
      
        .link_close_text{
          display: inline-block;
        }
        
        .link_open_text{
          display: none;
        }
      }
    }
  }

  .seeking-feature__body {
    .seeking-feature__accordion {
      .accordion__header {
        &.is-active {
          color: @accordion-active-color;
        }
      }

      .accordion__content {
        font-weight: initial;

        .seeking-feature__form_entry_label{
          margin-bottom: 5px;
          line-height: 21px;
          padding-top: 2px;
        }

        .seeking-feature__form {
          .clearfix;
          padding: 10px;
          
           select{
             width: 100%;
             max-width: 100%;
             height: 32px
           }

          .option__checkbox{
            .fake__checkbox{
              line-height: 0;
              vertical-align: top;
              max-width: 21px;
            }
            span{
              line-height: 21px;
              padding-top: 2px;
              width: calc(79%);
              word-break: break-word;
            }
          }

          .option__select,
          .option__checkbox {
            margin-bottom: 20px;
            cursor: pointer;
            
            &:last-child{
              margin-bottom: 0;
            }

            select {
              width: 95%;
              height: 32px;
            }

            span {
              display: inline-block;
            }
          }
        }
        
        .seeking-feature__form_entry{
          margin-top: 18px;
          
          &:first-child{
            margin-top: 0;
          }
        }
      }
    }
  }

	.seeking-feature__form_entry_enabled_wrapper{
		margin-top: 10px;
	}

	.seeking-feature__form_entry_slider{
		margin-top: 15px;
		padding-left: 5px;
	}

	.seeking-feature__form_entry_slider_select{
		border-radius: 3px;
	}

  .seeking-feature__footer {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #000;
    
    .seeking-feature__results-count{
      text-align: center;
      margin: 5px 0 15px 0;
    }

    .seeking-feature__action {
      display: flex;
      flex-direction: column;
      .cancel__button {
        order: 2;
        margin-top: 15px;
      }
    
      .seeking-feature__button {
        order: 1;
      }
    }
  }
}

#cboxContent {
  .seeking-feature {
    display: block;
  }
}

@media(min-width: 600px){
  .seeking-feature{
    .seeking-feature__header{
      .seeking-feature__links{
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
      
        .links__left,
        .links__right{
          margin-top: 0
        }
      
        .links__right{
          order: 3;
        }
      
        .links__left{
          padding-top: 4px;
          order: 1;
        }
        
        .seeking-feature__results-count{
          order: 2;
        }
      
        &:before,
        &:after{
          display: none;
        }
      }
    }
  }
}

@media(min-width: 700px){
  .seeking-feature{
    .btn.btn-small{
      max-width: 250px;
    }
  
    .seeking-feature__header{
      .seekging-feature__top {
        .seekging-feature__search--wrapper{
          float: left;
          width: 50%;
        }
      }
      
      .seeking-feature__button {
        width: 250px;
        display: inline-block;
        float: right;
        margin-top: 0;
      }
    }
    
    .seeking-feature__body {
      .seeking-feature__accordion {
        .accordion__content {
          .seeking-feature__form_entry {
            float: left;
            width: 50%;
            padding-left: 18px;
            
            &:nth-child(2){
              margin-top: 0;
            }
            
            &:nth-child(2n + 1){
              clear: both;
              padding-left: 0;
              padding-right: 18px;
            }
          }
        }
      }
    }
    
    .seeking-feature__footer {
      .seeking-feature__action {
        flex-direction: row;
        justify-content: space-between;
        
        .cancel__button {
          order: 1;
          margin-top: 0;
        }
    
        .seeking-feature__button {
          order: 2;
        }
      }
    }
  }
}

@media(min-width: 1000px){
  .seeking-feature{
    .seeking-feature__header {
	    .seeking-feature__title {
			display: block;
	    }
	}
    .seeking-feature__body {
      .seeking-feature__accordion {
        .accordion__content {
          .seeking-feature__form_entry {
            width: 33%;
            padding-left: 0;
            
            &:nth-child(2n + 1){
              padding-right: 0;
            }
            
            &:nth-child(3){
              margin-top: 0;
            }
            
            &:nth-child(2n + 1){
              clear: none;
            }
            
            &:nth-child(3n + 1){
              clear: both;
              padding-right: 24px;
            }
            
            &:nth-child(3n + 2){
              padding-left: 12px;
              padding-right: 12px;
            }
            
             &:nth-child(3n){
              padding-left: 24px;
            }
          }
        }
      }
    }
    .seeking-feature__title {
       display: none;
    }
  }
}

@media(min-width: 1024px){
	.seeking-feature{
		.seeking-feature__form_entry_slider{
			margin-top: 10px;
		}
	}
}

@media(min-width: 1220px){
	.overlay-widget{
		&.overlay-widget--seeking-features{
		
			.overlay-widget__overlay{
				max-width: 1100px;
			}
		}
	}
}