//
// Breadcrumbs

//SOLL: Links #168727 /Unterstrichen, hover: #168727/nicht unterstrichen, aktive Seite #717375
// --------------------------------------------------



.breadcrumb-section{
	grid-area: breadcrumb;
	padding: 10px 0 10px 10px;

	.breadcrumb{
		font-size: 12px;
		font-weight: 500;
		margin: 0;
		list-style: none;
		border-radius: 0;

		li + li:before {
			content: "/\00a0";
			padding: 0 5px;
			color: @GRAY;
		}

		li {
			display: inline;
			a {
				color: @breadcrumb-new-color;
				display: inline-block;
				text-decoration: underline;

				&:hover {
					color: @breadcrumb-new-color-hover;
					text-decoration: none;
				}
			}

			&.active {
				color: @breadcrumb-new-color-active;

				&:hover {
					color: @breadcrumb-new-color-active-hover;
				}
			}
		}
	}
}

@media (max-width: @screen-md) {
	.breadcrumb-section {
		padding: 10px 0;

		.breadcrumb {
			padding: 0;

			li + li:before {
				padding: 0;
			}
		}

	}
}
