.vjs-resolution-button.static-label {
	.vjs-icon-placeholder {
		&:before {
			content: '\f110';
			font-family: VideoJS;
			font-weight: normal;
			font-style: normal;
			font-size: 1.8em;
			line-height: 1.67em;
		}
	}
}

.vjs-resolution-button {
	.vjs-resolution-button-label {
		font-size: 1em;
		line-height: 3em;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		box-sizing: inherit;
	}
}

.vjs-resolution-button {
	.vjs-menu {
		.vjs-menu-content {
			width: 4em;
			left: 50%; /* Center the menu, in it's parent */
			margin-left: -2em; /* half of width, to center */
		}
	}
}

.vjs-resolution-button {
	.vjs-menu {
		li  {
		  text-transform: none;
		  font-size: 1em;
		}
	}
}