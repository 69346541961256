///////////////////////////////////////
//   coupon list on checkout steps   //
///////////////////////////////////////
.checkout-coupon-list {
  @media (max-width: @screen-xs-max) {
    padding-left: @grid-gutter-width/2;
    padding-right: @grid-gutter-width/2;
  }
}
.checkout-order-summary .checkout-coupon-list {
  padding: 30px 0;
  border-bottom: 1px solid @border-color-3;

  @media (max-width: @screen-xs-max) {
    padding-left: @grid-gutter-width;
    padding-right: @grid-gutter-width;
  }
}

.coupon-list__headline {
  .text-variant-header-order-info();
}
.coupon-list {
  .list-unstyled();
  margin-top: @grid-gutter-width/4;
  margin-bottom: 0;
}

.coupon-code {
  text-transform: uppercase;
}

////////////////////////////////
//     voucher in cart page   //
////////////////////////////////
.cart-voucher {
  margin-top: 27px;
  padding-left: 30px;
}
.cart-voucher__label {
  display: block;
  width: 100%;
}
.cart-voucher__input {
  width: 50%;
  float: left;
  margin-right: @grid-gutter-width/2;
}
.cart-voucher__input::-webkit-input-placeholder {
  .cart-voucher-placeholder();
}
.cart-voucher__input::-moz-placeholder {
  .cart-voucher-placeholder();
}
.cart-voucher__input:-ms-input-placeholder {
  .cart-voucher-placeholder();
}
.cart-voucher__input::-ms-input-placeholder {
  .cart-voucher-placeholder();
}
.cart-voucher__input::placeholder {
  .cart-voucher-placeholder();
}

.cart-voucher__help-block {
  display: none;

  .has-error &,
  .has-success & {
    display: block;
  }
}

@media (max-width: @screen-md-max) and (min-width: @screen-md-min) {
  .cart-voucher__input {
    width: 70%;
  }
}
@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
  .cart-voucher {
    padding-left: 30px;
  }
}

@media (max-width: @screen-xs-max) {
  .cart-voucher {
    padding-left: @grid-gutter-width;
    padding-right: @grid-gutter-width;
  }
}

///////////////////////////////////////
//     voucher list on cart page     //
///////////////////////////////////////
.voucher-list {
  .list-unstyled();
  margin-top: -5px;
}
@voucherListItemPaddingVertical: @grid-gutter-width/2;
.voucher-list__item-box {
  display: inline-block;
  border: 1px solid @coupon-list-item-border;
  background-color: @coupon-list-item-bg;
  font-size: @font-size-small;
  font-weight: 600;
  text-transform: uppercase;
  padding: @voucherListItemPaddingVertical 15px;
  margin-top: @grid-gutter-width/2;
}
.voucher-list__item-remove {
  color: @coupon-list-item-remove-icon;
  padding: @voucherListItemPaddingVertical;
  cursor: pointer;
  margin: -@voucherListItemPaddingVertical -@voucherListItemPaddingVertical -@voucherListItemPaddingVertical 0;
  position: relative;
  top: 2px;
}
@media (max-width: @screen-sm-max) {
  .voucher-list__item {
    display: inline-block;
    padding-right: 5px;
  }
}

///////////////////////////////////////
//  Coupon order confirmation page   //
///////////////////////////////////////

.gift__coupons {
  text-align: center;
  border-top: 1px solid @border-color-3;
  margin: 0 auto 55px;
  padding-top: 20px;
  .full-width-padding();
  width: 80%;

  span {
    display: block;
  }

  @media (max-width: @screen-xs) {
    width: 90%;
  }

  @media (min-width: @screen-md) {
    width: 40%;
  }
}

.gift__coupons .gift__coupon--title {
  margin-bottom: 5px;
}

.gift__coupon .gift__coupon--name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}