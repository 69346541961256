
// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(@color; @background; @border; @hover) {
	color: @color;
	background-color: @background;
	border-color: @border;
	width: 100%;

	&:hover,
	&:focus,
	&:active,
	&.active{
		color: @color;
		background-color:  @hover;
		border-color: @hover;
		.box-shadow(none)
	}
	&:active,
	&.active,
	.open .dropdown-toggle& {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			color: @color;
			background-color: @background;
			border-color: @background;
			opacity: .6;
		}
	}

  .icon {
    font-size: 22px;

    &.spinner__wrapper {
      font-size: 18px;
      padding-left: 5px;
    }
  }
}

.flex-display(@value) {
	display: ~"-webkit-@{value}";
	display: ~"-moz-@{value}";
	display: ~"-ms-@{value}box";
	display: ~"-ms-@{value}";
	display: @value;
}

.flex-wrap(@value) {
	-webkit-flex-wrap: @value;
	-moz-flex-wrap: @value;
	-ms-flex-wrap: @value;
	flex-wrap: @value;
}

.flex-direction(@value) {
	-webkit-flex-direction: @value;
	-moz-flex-direction: @value;
	-ms-flex-direction: @value;
	flex-direction: @value;
}

.flex (@value) {
	-webkit-flex: @value;
	-moz-flex: @value;
	-ms-flex: @value;
	flex: @value;
}

.accordion-bg (@n, @i: 1) when (@i =< @n) {
  @left-spacing: 15 + @grid-gutter-width * (@i + 2);
  .panel-heading {
    background-color: lighten(@accordion-bg, (95% -  (@i * 5)));
    .accordion-lnk {
      left: @left-spacing;
      &.no-subs {
        padding-left: @left-spacing;
      }
    }
  }
  .panel-collapse {
    .accordion-bg(@n, (@i + 1));
  }
}


// text variants
.page-headline {
  color: @headline-color;
  font-size: @font-size-h3;
  line-height: 35px;
  font-weight: 700;
  margin: 0;

  &.border {
    border-bottom: 1px solid @border-color-2;
    margin-bottom: @grid-gutter-width + 5;
  }
}

.page-sub-headline {
  font-size: @font-size-base;
  font-weight: 400;
  line-height: 1.571;
}

.text-variant-bold {
  font-weight: 600;
}

.text-variant-header-order-info() {
  font-size: @font-size-large-2;
  line-height: 38px;
  font-weight: bold;
}

// padding variants

.full-width-padding {
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: @screen-md) {
	padding-left: 20px;
	padding-right: 20px;
  }

}

.half-width-padding {
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: @screen-md) {
	padding-left: 10px;
	padding-right: 10px;
  }

}

.form-control-validation(@label-color; @border-color; @background-color; @hint-color) {
  .control-label{
    color: @label-color;
  }

  input.form-control {
    border-color: @border-color;
  }

  .help-block {
    font-weight: 600;
    color:@hint-color;
    text-align: left;
  }
}

.cart-voucher-placeholder(){
  font-size: @font-size-small;
  font-weight: 600;
  color: @text-color;
  text-transform: uppercase;
}


/* Micro ClearFix Mixin */
.clearfix{
  zoom:1;
  &:before, &:after{ content:""; display:table; }
  &:after{ clear: both; }
}

.energy-label-color(@color) {
  background-color: @color;
  &:before {
    border-right-color: @color;
  }

}