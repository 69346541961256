.retoure {
  .retoure__headline {
    color: @headline-color;
    font-size: 24px;
    line-height: 35px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .retoure__subline {
    font-weight: 700;
    font-size: 20px;
    margin: 20px 0;
    display: block;
  }

  .retoure__header {
    margin-bottom: 20px;

    .order-administration {
     .order {
       display: block;

       .order__left {
         width: 100%;
       }

       .order__row {
         display: block;

         .order__item {
           width: 100%;
         }
       }
     }
    }

    .info__wrapper {
      .info {
        display: inline-block;
        width: 35%;
		padding: 10px 0;

        .info__label {
          font-weight: 700;
        }
      }
    }

	.retoure__position {
		.order__consignement--retoure {

          border-bottom: 1px solid @GRAY-LIGHT;

          .consignment__info {
				width: 35%;
				display: inline-block;
				
				.orders__list {
					width: 100%;
					display: inline-block;
				
					.order__row-one{
						width: 35%;
						
						.order__icons {
							display: none;
						}
					}
					.order__row--info {
						width: 60%;
						
						.order__info {
							width: 100%;

                            .order__label {
                              width: 100%;
                            }

                          .order__text {
                            padding: 0;
                            margin: 0;
                          }

                          .product--code {
                              color: @LINK;
                          }
						}
					}
				}
			}

			
			.orders__list.order__list--detail {
				width: 65%;
				float: right;

              .order__info {
                padding-left: 10px;
              }
				
				.list__order{
					.clearfix;
				}
			
				.order__row {
					width: 33.33333%;
					float: left;
				}
				
				.order__info{
					display: block;
					width: 100%;
				}
				
				.order__row {

					.order__info.consignmentPackage {
						width: 100%;
						.order__label {
							width: 100%;
						}

                      .consignmentPackage__link {
                        display: block;
                      }
					}
					
					.order__info {
						.order__label {
							width: 100%;
						}
						.order__text {
                          padding: 0;
                          margin: 0;
						}
					}
				}
			}
		}
	}
  }

  .retoure__content {
    .retoure__row {
      margin-bottom: 20px;
      padding-bottom: 10px;
      position: relative;

      &.retoure__row--border {
        border-bottom: 1px solid @order-border-color;
      }

      .retoure__row-input {
         min-width: 50%;
      }

      .retoure__col {
        display: inline-block;
        margin-right: 30px;

        &.retoure__edit {
          position: absolute;
          right: 0;

          a {
            &:before {
              &:extend(.fas);
              content: @fa-var-pencil-alt;
              font-size: 14px;
              padding-right: 5px;
            }
          }
        }
      }
      .form-group {
        clear: left;
      }
    }

    .retoure__label {
      font-weight: 700;
      width: 200px;
      display: inline-block;
      margin-right: 35px;
      margin-bottom: 15px;
    }

    .retoure__label-checkbox {
      float: left;
      display: inline-block;
    }

    .retoure__value-checkbox {
      float: left;
      display: inline-block;
      margin-right: 5px;
    }

    .retoure__amount {
      height: 32px;
      width: 150px;
      margin-bottom: 5px;
    }

    .retoure__area {
      resize: none;
      height: 110px;
      width: 50%;
    }

    .retoure__tac {
      text-align: right;
      margin-bottom: 25px;

      input {
        display: none;
      }
    }

    .retoure__conditions {
      padding: 15px;
      background: @retoure-conditions-bg;

      .retoure__condition {
        margin-bottom: 15px;
      }

      .retoure__label {
        width: 25%
      }

      .retoure__value,
      .retoure__documents {
        width: 60%;
        display: inline-block;
        vertical-align: top;
      }
    }

    .retoure__actions {
      margin: 25px 0;
      height: 50px;

      .retoure__back {
        width: 250px;
        float: left;
      }

      .retoure__next {
        width: 250px;
        float: right;
      }
    }

    .retoure__link {
      text-align: right;
    }
  }

  &.retoure__success {
    .retoure__row {
      margin-bottom: 25px;

      .icon__pdf {
        margin-top: 15px;

        a {
          &:before {
            &:extend(.fas);
            content: @fa-var-file-pdf;
            font-size: 14px;
            padding-right: 5px;
          }
        }
      }
    }

    &.retoure__pdf {
      margin-top: 50px;
    }

    .retoure__actions {
      button {
        width: 250px;
      }
    }
  }

  &.retoure__modal {
    display: none;
    height: 170px;

    .retoure__headline {
      font-size: 18px;
      font-weight: normal;
      display: block;
    }
  }
}
#cboxLoadedContent {
  .retoure__modal {
    display: block;
  }
}

@media (max-width: @screen-md) {
	.retoure {
		.retoure__header {
			.info__wrapper {
				.info {
					display: block;
					width: 100%;
					margin-bottom: 5px;
				}
			}
			
			.retoure__position {
				.order__consignement--retoure {
					.orders__list.order__list--detail {
						.order__row {
							width: auto;
							float: none;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 560px) {
  .retoure {
    .retoure__content {

      .retoure__row {
        .retoure__col {
          display: block;
        }
      }
      
      .retoure__label {
        display: block;
        margin: 0
      }

      .retoure__value {
        display: block;
        margin-bottom: 15px;
      }

      .retoure__actions {
        height: auto;

        .retoure__next,
        .retoure__back {
          display: block;
          float: none;
          width: 100%;
          margin-bottom: 15px;
        }
      }

      .retoure__area {
        width: 100%;
      }
    }

    .retoure__conditions {
      .retoure__label {
        margin: 15px 0;
      }
    }
  }
}

@media (max-width: 700px) {
  .retoure {
    .retoure__content {
      .retoure__conditions {
        .retoure__documents,
        .retoure__value,
        .retoure__label {
          width: 100%;
        }
      }

      .retoure__row {
        .retoure__col{
          &.retoure__edit {
            position: relative;
            display: block;
          }
        }
      }

      .retoure__area {
        width: 75%;
      }
    }
  }


}
@media (max-width: @screen-md){


	.retoure {
		.retoure__header {
			
			.info__wrapper {
				padding: 0 35px;
			}
			
			.retoure__position {
				.order__consignement--retoure {
					.consignment__header {
						background: #f2f2f2;
						padding: 10px;
						margin-right: 15px;
						font-weight: 700;
					}
					.consignment__info {
						width: 100%;
						
						.orders__list {
                          .order__row--info {
                            .order__info {
                              .order__label {
                                width: 50%;
                              }
                              .order__text {
                                padding-left: 4px;
                              }
                            }
                          }

							.order__row-one{
								width: 100%;
								
								.order__icons {
									display: none;
								}
							}
							.order__row--info {
								width: 100%;
							}
						}
					}
					
					.orders__list.order__list--detail {
						width: 100%;
						float: none;

                      .order__info {
                        padding: 0;
                      }
						
						.order__row-one {
							.order__info {
								width: 100%;
							}
						}	
						.order__row {
							.order__info.consignmentPackage {
								.order__label {
                                  width: 50%;
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                                  overflow: hidden;
								}
							}
							
							.order__info {
								.order__label {
                                  width: 50%;

                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                                  overflow: hidden;
								}
							}
						}
					}
				}
			}
		}

	}
}

@media (min-width: 600px) {
  .retoure {
    .retoure__content {
      margin: 20px 40px;
    }
  }
}

@media (max-width: @screen-sm){
	.retoure {
		.retoure__header {
			
			.info__wrapper {
				padding: 0;
			}
		}
	}
}
