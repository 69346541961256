
.carousel-bundles__component {

    .carousel__component--headline {
      text-align: left;
    }

}


