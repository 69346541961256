//************************************************************
//************************************************************
//************************************************************
//************************************************************
//************************************************************
//                          BASE STYLES
//************************************************************
//************************************************************
//************************************************************
//************************************************************
//************************************************************

//*********Variables*********

@font-family-sans-serif: 'Libre Franklin', 'Neue Helvetica W01', Helvetica, Arial;


//*********General CSS*********
html {
  height: 100%;
}

body {
  overflow-x: hidden;
  position: relative;
  font-family: @font-family-sans-serif;
  min-height: 100%;

  &.is-fixed {
    overflow-y: hidden;

    @media (min-width: 961px) {
      padding-right: 16px; /* Avoid width reflow */

      .meta-nav,
      .header__container.sticky {
        padding-right: 16px;
      }
    }
  }
}

*:focus {
  outline: none;
}

h1, h2, h3, h4{
  margin: 0;
  font-weight: inherit;
  line-height: 1.2;
}

h1 {
  font-size: 1.602em;
}

h2 {
  font-size: 1.424em;
}

h3 {
  font-size: 1.266em;
}

h4 {
  font-size: 1.125em;
}

label {
  color: @FONT-COLOR !important;
  display: block;
  text-transform: initial !important;
}

.no-margin{
    margin: 0 !important;
}

.container-lg {
  @media (min-width: @screen-lg) {
    float: none;
    margin: 0 auto !important;
    overflow: hidden;
  }
}

button {
  font-weight: bold;
}

.btn{
  border-radius: 0 !important;
}

.font__normal {
  font-weight: normal !important;
}

ul, ol {
  padding-left: 15px;
}

.amount__select {
  height: 47px;

  .amount__input {
    height: 32px;
    width: 32px;
    display: inline-block;
    text-align: center;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
    border: solid 1px @amount-selection-boder-color;
    float: left;
  }

  .select__sub,
  .select__add {
    display: inline-block;
    height: 32px;
    width: 32px;
    float: left;
    border: none;
    background: @amount-selection-btn-disabled;
    color: @WHITE;
    font-size: 14px;
    display: block !important;
    text-align: center;
    &:hover, &:active, &:focus {
      background: @amount-selection-btn-hover; 
    }
  }



  .select__add {
    &:after {
      &:extend(.fa);
      content: @fa-var-plus;

    }
  }

  .select__sub {
    &:after {
      &:extend(.fa);
      content: @fa-var-minus;
    }
  }
}

@media (max-width: @screen-md) {
  .amount__select {
    .amount__input,
    .select__sub,
    .select__add {
      width: 50px;
      height: 50px;
    }
  }
}

@media (max-width: @screen-sm-max) {
  .form-control {
    .input-lg;
    font-size: @font-size-base;
  }
}

.layout__two-col--wrapper {

  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  padding: 0;

  &:before,
  &:after {
    content: "";
    display: table;  }

  .layout__two-col {
    float: left;

    &.layout__two-col--left {
      flex: 0 1 auto;
      width: 240px;
      min-width: 240px;
      -webkit-box-ordinal-group: 2;
      order: 1;
    }

    &.layout__two-col--right {
      flex: 0 1 auto;
      padding-left: 15px;
      -webkit-box-ordinal-group: 3;
      order: 2;
      width: calc(~"100% - 240px")
    }
  }
}

@media (max-width: @screen-md) {
  .layout__two-col--wrapper {
    display: block;

    .layout__two-col {
      &.layout__two-col--left,
      &.layout__two-col--right {
        width: 100%;
        float: none;
      }
    }
  }
}
.header__content,
.site__content {
  padding: 0 20px;
}

@media (min-width: @screen-lg) {
  .header__content,
  .site__content {
    padding: 0;
  }
}

option {
  font-size: 0.875em; /* 14/16 */
  line-height: 18px;
  padding: 6px 13px;
  width: 100%;
}

/*
General
*****************************************************************/

.site__content,
.header__content,
.meta-nav__content,
.footer__wrapper {
  max-width: @window-width;
  margin: 0 auto;
}

.site__content {
  min-height: 450px;
  &.sticky {
    margin-top: 270px;
  }
}
.site__content .row .col-filter-search-result-page {
    width: 16.66666667%;
    float: left;
}
.site__content .row  .col-search-result-page{
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
}
@media (min-width: 961px){
	.site__content .row .col-search-result-page {
    	width: 83.33333333%;
    	float: left;
    }
}

@media (max-width: @screen-md) {
  .site__content {
    &.sticky {
      margin-top: 137px;
    }
  }
}

a, button {
  color: @link-color;
  text-decoration: none;
}

output {
  display:block;
  padding-top:7px;
  font-size:14px;
  line-height:1.42857143;
  color: @text-color;
}

.open > .dropdown-menu {
  padding: 15px 0 0 0;
}

.dropdown-menu li {
  margin-top: 3px;
}

.dropdown-menu li:first-child {
  margin-top: 0px;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 25px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857;
  color: @text-color;
  white-space: nowrap;
}

.display-none {
  display: none;
}

.page-title {
  margin-bottom: 30px;
}

@item-value-font-size: 18px;
@media (min-width: @screen-sm-min) {
  .item-label,
  .item-value {
    display: block;
  }
}
@media (max-width: @screen-xs-max) {
  .item-label,
  .item-value {
    display: inline-block;
    line-height: @line-height-computed;
    vertical-align: top;
    width: 49%;
  }
  .item-value {
    padding-left: @grid-gutter-width/2;
  }
  .item-action {
    margin-top: @grid-gutter-width;
  }
}
.item-label {
  text-transform: uppercase;
  font-weight: 300;
}
.item-value {
  font-size: @item-value-font-size;
  padding-bottom: @grid-gutter-width/2;
  text-overflow: ellipsis;
  overflow: hidden;
}

.checkboxes {
  >span {
    display: block;
    position: relative;

    > input[type=checkbox] {
      position: absolute;
    }

    > label {
      font-weight: normal;
      cursor: pointer;
      display: block;
      min-height: 20px;
      padding-left: 20px;
      margin-bottom: 0;
    }
  }
}

.alert {
  .full-width-padding();
}

.container__full {
  .full-width-padding();
}


.js-link {
  cursor: pointer;
}

.js-template {
  display: none;
}

.fas {
  &.fa-search,
  &.fa-times {
      color: @header-search-icon-color;

      &:hover {
        color: @header-search-icon-hover-color;
      }
  }

  &.fa-search {
    font-size: 28px;
  }
}

/**
    ASM Disable
 */

.asm-click-disable {
  &:not(.btn) {
    color: @FONT-COLOR !important;
  }

  &:hover {
    cursor: not-allowed !important;
  }
}

.icon-asm {
   color: #ffffff
}

.icon-asm-order-history {
  background: #ffffff;
  color: #717375;
}

.input-group-btn.asm-click-disable {
  background: @DISABLED !important;;
}
input {
  &.asm-click-disable {
    background: @DISABLED !important;
  }
}

.twoline {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: normal !important;
  max-height: 33px;
}

.link {
  color: @LINK;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    color: @LINK;
    text-decoration: none;
  }

  &.link__no-margin {
    margin: 0;
  }

  &.link__small {
    font-size: 14px;
  }
  &.facet__show,
  &.link__bookmark,
  &.link__truck-loading,
  &.link__box,
  &.link__print,
  &.link__compare,
  &.link__price,
  &.link__map,
  &.link__remove,
  &.link__eye,
  &.link__close,
  &.link__edit,
  &.link__submit,
  &.item__remove {
    color: @SECONDARY;
    text-decoration: none;
    font-size: 20px;

    &:hover {
      color: @HOVER;
    }
  }

  &.link__truck-loading {
    &:after {
      &:extend(.fas);
      content: @fa-var-truck-loading;
      font-size: 18px;
    }
  }

  &.link__box {
    &:after {
      &:extend(.fas);
      content: @fa-var-box;
      font-size: 18px;
    }
  }

  &.link__download {
    &.is-disabled {
      pointer-events: none;
      cursor: pointer;
    }
  }

  &.link__compare {
    &.is-disabled {
      color: @GRAY;
    }
  }

  &.link__remove {
    &:after {
      &:extend(.fas);
      content: @fa-var-trash;
      font-size: 18px;
    }
  }

  &.link__close {
    &:after {
      &:extend(.fa);
      content: @fa-var-times;
      font-size: 18px;
    }
  }

  &.link__edit {
    &:after {
      &:extend(.fa);
      content: @fa-var-pencil-alt;
      font-size: 18px;
    }
  }

  &.link__submit {
    &:after {
      &:extend(.fa);
      content: @fa-var-check;
      font-size: 18px;
    }
  }

  &.link__bookmark {
    &:after {
      &:extend(.fa);
      content: @fa-var-star;
    }
  }

  &.link__print {
    &.is-deactive {
      color: @GRAY;
      &:hover {
        cursor: not-allowed;
      }
    }
    &.link__print--loading{
      &:after{
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
        content: @fa-var-spinner;
      }
    }
    
    &:after {
      &:extend(.fa);
      content: @fa-var-print;
    }
  }

  &.link__map {
    &:after {
      &:extend(.fa);
      content: @fa-var-map;
    }
  }

  &.link__compare {
    &:before {
      &:extend(.far);
      content: @fa-var-square;
    }

    &.is-checked {
      &:before {
        &:extend(.far);
        content: @fa-var-check-square;
      }
    }

    &.is-disabled {
      cursor: not-allowed;
    }
  }

  &.link__price {
    &:after {
      &:extend(.fa);
      content: @fa-var-tags;
    }
  }
}
//************************************************************
//************************************************************
//************************************************************
//************************************************************
//                          TABLET STYLES
//************************************************************
//************************************************************
//************************************************************
//************************************************************
//************************************************************

@media (max-width: @screen-sm-max) {

  body.offcanvas {
    max-height: 100vh;
  }

  main {
    .transition(transform 0.3s ease-out);
  }

  .container {
    width: @full-width;
  }

  textarea.form-control, select[multiple].form-control {
    height: auto
  }
}


//************************************************************
//************************************************************
//************************************************************
//************************************************************
//                          MOBILE STYLES
//************************************************************
//************************************************************
//************************************************************
//************************************************************
//************************************************************


/* Chris Styles */
div.webcomponent-component {
  line-height: 1;
}

.header__desktop i.webcomponent-component {
  min-width: 81.2px;
}

.header__mobile i.webcomponent-component {
  min-width: 32px;
}