.cart__header {
  margin-bottom: 50px;
  margin-top: 15px;

  .cart__headline {
    .page-headline();
    text-align: center;
    padding: 0;
    width: 70%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    margin: 20px 0 0 0;
  }

  .cart__bookmark-link {
    display: inline-block;

  }
}

.header__information {
  margin-bottom: 30px;

  .information__input {
    width: 300px;
  }
}

.cart__qty-selector-input {
  &.cart__qty-selector-input--warning {
    border: 2px solid @input-error;
  }
}

.cart__links {
  .link {
    color: @cart-link-deactivate;
    margin-right: 40px;
    text-decoration: underline;
    font-size: 14px;
    display: none;

    &.is-active {
      color: @cart-link-activate;
      display: inline-block;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.cart__action {
  display: table;
  table-layout: fixed;
  width: 100%;

  .cart__checkbox,
  .cart-search,
  .acquisition__wrapper,
  .cart__selection {
    display: table-cell;
    vertical-align: bottom;
  }

  .cart__checkbox {
    padding-left: 10px;
  }

  .cart__selection-title {
    display: block;
  }

  .acquisition__select,
  .cart-item__select {
    height: 32px;
    width: 95%;
  }

  .cart__export,
  .cart__import {
    display: table-cell;
    margin-top: 7px;
    text-align: center;
    color: @LINK;
    text-decoration: underline;
    vertical-align: middle;

    &:hover {
      text-decoration: none;
    }
  }

  .cart-search {

    .input-group {
      background-color: @cart-search-bg;
      border: 1px solid @cart-search-border;
      margin: 1px;

      &.is-focus {
        border-color: @input-border-focus;
        outline: 0;
        box-shadow: none;
        border-width: 2px;
        margin: 0;
      }

    }

    .form-control {
      border: 0;
      box-shadow: none;
      background-color: transparent;
    }

    .btn {
      padding: 0 15px 0 0;
    }

    .glyphicon-search {
      color: @searchbar-icon-color;
      font-size: 17px;
      &:hover {
        color: @searchbar-icon-hover-color;
      }
    }

    .ui-autocomplete {
      display: none;
      position: absolute;
      background: @autocomplete-background;
      left: 0;
      right: 0;
      -webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
      z-index: 100;
      width: auto !important;
      padding-left: 0;
      padding-top: 0;
      list-style: none;
      margin: 0;
    }

    &__list-item {
      display: table;
      width: 100%;
      color: inherit;
      text-decoration: none;
      padding: 10px !important;

      &.ui-state-active {
        background-color: @autocomplete;
        border-color: @border-color;
        margin: 0;
        border: 0;
        color: @text-color;
      }

      .thumb {
        display: table-cell;
        vertical-align: middle;
        padding: 0 10px 0 0;
      }

      .name {
        display: table-cell;
        padding: 10px;
        vertical-align: middle;
        width: 80%;
      }

      .price {
        display: table-cell;
        vertical-align: middle;
        padding: 0 10px;
        width: 20%;
      }
    }
  }
}

.cart__empty {
  margin: 0 auto;
  width: 50%;

  .empty__icon-wrapper {
    text-align: center;


    .empty__icon {
      border-radius: 50%;
      background: @empty-cart-icon-bg;
      height: 100px;
      width: 100px;
      margin: 0 auto;
      position: relative;

      i {
        position: absolute;
        top: 27%;
        left: 24%;
        font-size: 45px;
        color: @BLACK;
      }
    }
  }

  .empty__headline {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }

  .glyphicon {
    color: @header-search-icon-color;

    &:hover {
      color: @header-search-icon-color;
    }
  }


  .empty__import,
  .empty__back {
    margin-bottom: 30px;
    text-align: center;

    .btn {
      display: inline-block;
      margin: 0 auto;
    }

  }


}

.cart__content {
  .row();
}

.cart__top--totals {
  text-align: right;
  font-size: 15px;
  margin-top: 15px;
  .full-width-padding();

  .cart__top--amount {
    margin-left: 5px;

    &:before {
      content: "|";
      position: relative;
      left: -2px;
      color: @text-color-secondary;
      font-weight: 400;
      top: -1px;
    }
  }
}

.item__list__cart {
  margin-top: 0;
  margin-bottom: 10px;

  .item__list--item {

    .item__image {
      padding: 10px;
    }

    .item__menu {
      overflow: visible;

      .dropdown-menu {
        padding: 5px 0;
      }
    }

    .item__price {
      .price__before-discount {
        font-size: 12px;
      }
    }

    .item__total {
      .total__before-discount {
        font-size: 12px;
      }
    }

    .item__acquisition {

      &--type {
        width: 100%;
        height: @input-height-base;
        background-color: @input-background;
        margin-bottom: 5px;
      }

      //for cart icon different deliveryadress
      .product__icons.partial {
        text-align: center;
      }

      .acquisition__icon-distance_permission {
        display: block;
        margin-top: 3px;
        color: @acquisition-icon-distance_permission-color;

        &:before {
          &:extend(.fas);
          content: @fa-var-ban;
          font-size: 18px;
          margin-right: 5px;
        }
      }

    }
  }

  .item__list--comment {
    display: table;
    table-layout: fixed;
    width: 100%;

    .item__comment {
      .full-width-padding();

      textarea {
        min-height: inherit;
        resize: none;
        margin-bottom: 20px;
      }
    }

    .item__comment--role {
      margin-top: (@grid-gutter-width + 18);
    }

    .item__comment--author {
      font-weight: bold;
    }
  }

  .notification.has-error {
    padding: 0 30px;
    text-align: center;
    margin-top: 10px;
  }

  .item__configurations {
    margin: 10px 0;
  }

  .item__configuration--entry {
    @media (max-width: @screen-xs) {
      margin-bottom: 5px;
    }
  }

  .item__configurations--edit {
    .btn {
      padding-left: 0;

      &:hover {
        color: @link-hover-color;
      }
    }
  }

  .item__conditions {
    text-align: left;

    &.item__conditions--mobile {
      display: none;
    }
  }
}

.cart__head--link {
  &:after {
    content: "|";
    position: relative;
    left: 5px;
    color: @text-color-secondary;
    font-weight: 400;
    vertical-align: top;
  }

  &:first-child {
    &:after {
      content: "";
      position: relative;
      left: 0px;
      color: @text-color-secondary;
      font-weight: 400;
      vertical-align: top;
    }
  }
}

.cart__info {
  font-size: 13px;
  border: 1px solid green;
  padding: 10px;
}

.cart__action {
  margin-bottom: 30px;

  .form-group {
    margin-bottom: 0;
  }
}

.cart__buttons {
  display: inline-block;
  width: 100%;
  white-space: normal;
  text-align: right;
  margin-top: 50px;
  padding-right: 5px;

  > button,
  a {
    width: auto;
    display: inline-block;
  }
}



@cart-totals-padding-right: 55px;
.cart__total--headline {
  margin-top: 20px;
  font-weight: 700;
}

.cart-totals {
  display: inline-block;
  width: 50%;

  .cart-totals-wrapper {
    white-space: nowrap;
  }

  .cart-totals-left {
    display: inline-block;
    width: 50%;
    font-weight: 700;

    &.grand-total {
      font-size: 18px;
      margin-top: 15px;
      vertical-align: top;
    }
  }

  .cart-totals-right {
    display: inline-block;
    width: 50%;
    padding-right: 5px;

    &.grand-total {
      font-size: 18px;
      font-weight: 700;
      margin-top: 15px;

      .total-before-discount {
        font-size: 14px;
      }
    }
  }

  .cart-price {
    font-weight: bold;
  }

  .discount {
    color: @item-promo-color;
  }

  .quote-discount-link {
    text-transform: uppercase;
  }


  .cart-totals-taxes {
    float: left;
    margin-top: 15px;
  }
  .warn {
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: @item-sale-color;
  }
}

.marked__items {
  display: none;
  font-size: 15px;
  font-weight: 700;
  color: @marked-items-color;
  margin-bottom: 15px;
}


@image-offset: 100px;
.cartpotproline,
.cartproline {
  font-size: @font-size-large-2;
  padding-bottom: @grid-gutter-width + 5;
  border-bottom: 1px solid @border-color-2;
  .full-width-padding();

  .info {
    font-size: @font-size-base;
  }
}

.promotion {
  font-size: @font-size-base;
  font-weight: bold;
  color: @item-promo-color;
}

.error-details-section {
  .action {
    text-align: center;
    margin: (@grid-gutter-width/2) 0;
  }
}

@media (max-width: @screen-md) {
  .cart__header {
    display: grid;
    grid-template-areas: "cartHeadline cartBookmarkLink" "progression progression";
    margin-bottom: 0;

    .cart__bookmark-link {
      grid-area: cartBookmarkLink;
      text-align: right;
      margin-top: 28px;
      margin-right: 10px;
    }

    .cart__headline {
      grid-area: cartHeadline;
      margin-left: 10px;
      text-align: left;
      width: auto;
    }

    .checkout__progression {
      grid-area: progression;
    }
  }

  .fake__checkbox {
    &:before {
      font-size: 40px;
    }
  }

  .cart__action {
    display: grid;
    grid-template-areas: "acquisitionSelect acquisitionSelect" "cartSelection cartSelection" "cartExport cartImport" "cartSearch cartSearch" "cartCheckbox cartCheckbox";
    grid-template-columns: 50% 50%;

    .cart__checkbox {
      grid-area: cartCheckbox;
      margin-top: 15px;
      padding-bottom: 15px;
      padding-left: 0;
      border-bottom: 3px solid @mobile-cart-border-color;
    }

    .acquisition__wrapper {
      grid-area: acquisitionSelect;
    }

    .cart__selection {
      grid-area: cartSelection;
    }


    .cart__export {
      grid-area: cartExport;
      text-align: left;
      margin-left: 5px;
    }

    .cart__import {
      grid-area: cartImport;
      text-align: right;
      margin-right: 5px;
    }

    .acquisition__select,
    .cart-item__select {
      width: 100%;
    }

    .cart__selection,
    .acquisition__wrapper {
      margin-top: 15px;
    }


    .cart-search {
      grid-area: cartSearch;
      margin-top: 15px;
    }
  }

  .cart-totals {
    .cart-totals-taxes,
    .cart-totals-taxes,
    .cart-totals-left {
      padding-left: 0;
    }
  }

  .item__list__cart {

    .item__list--header {
      display: none;
    }

    .item__list--item {
      display: grid;
      grid-template-areas: "productInfo productInfo productInfo" "productAcquisition productQuantitiy productPrice" "productCheckbox productCheckbox productTotal" "productDelivery productDelivery productDelivery";
      border-bottom: 3px solid @mobile-cart-border-color;

      .item__product,
      .item__total {
        display: block;
        padding: 0;
      }

      .item__quantity,
      .item__price,
      .item__acquisition {
        padding: 10px 0;
      }
      .item__acquisition {
        text-align: left;

        .product__icons.partial {
          text-align: left;
        }
      }
      .product__checkbox {
        grid-area: productCheckbox;
        padding-left: 0px;
        text-align: left;
        .fake__checkbox {
          padding: 0;
          margin: 0;
        }
      }

      .item__product {
        display: grid;
        grid-area: productInfo;
        grid-template-areas: "productImage productName productName" "productImage productStock productRemove";
        padding: 0;
        width: 100%;

        &--saved-cart {
          grid-template-areas: "productImage productName productName" "productImage productStock productStock";

        }
        .product__image {
          grid-area: productImage;
          margin: 0;
          padding: 0 5px 0 0;

          &:before,
          &:after {
            background: none;
          }

          .item__image-wrapper {
            min-width: 120px;
          }
        }

        .product__name {
          grid-area: productName;
          float: none;
          width: auto;

          .product__info-name {
            white-space: normal;
          }

          .product__info-name,
          .product__info-color {
            font-size: 1.1em;
          }

        }

        //checkbox
        .product__checkbox {
          float: none;
          top: -30px;
          position: relative;
        }
      }

      //remove
      .item__remove {

        &.item__remove--desktop {
          display: none;
        }
        &.item__remove--mobile {
          display: block;
          grid-area: productRemove;
          width: auto;
          height: auto;
          text-align: right;
        }

        &:after {
          font-size: 30px;
          position: initial;
        }
      }

      //acquisition
      .item__acquisition {
        grid-area: productAcquisition;

        .acquisition__text {
          display: block;
          padding-top: 0;
        }

        .acquisition__label {
          display: block;
          padding-bottom: 5px;
        }

        select {
          height: 30px;
        }
      }

      //quantity
      .item__quantity {
        grid-area: productQuantitiy;
        text-align: center;

        .quantity__label {
          display: block;
          padding-bottom: 5px;
        }

        .form-control {
          width: 40px;
          height: 30px;
        }
      }

      //price
      .item__price {
        grid-area: productPrice;
        text-align: right;

        .price__label {
          font-weight: normal;
          display: block;
          padding-bottom: 5px;
        }
      }

      .item__total {
        grid-area: productTotal;
        padding-bottom: 10px;

        .total__label {
          display: block;
          font-weight: normal;
        }
      }

      //stock
      .item__stock {
        &.item__stock--desktop {
          display: none;
        }

        &.item__stock--mobile {
          display: block;
          grid-area: productStock;
          padding: 0;
          text-align: left;
        }

        .delivery__container,
        .stock__label {
          display: inline-block;
        }
      }

      //delivery
      .item__delivery {
        &.item__delivery--desktop {
          display: none;
        }

        &.item__delivery--mobile {
          display: block;
          grid-area: productDelivery;
        }
      }
    }
  }

  .cart__buttons {
    display: inline-block;
    width: 100%;
    white-space: normal;
    text-align: right;
    padding: 0;
  }
}

.cart__dynamic-info {
  display: inline-block;
  float: left;
  width: 50%;
}

.saved-cart__actions {

  margin-top: 50px;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  a {
    width: auto;
    display: inline-block;
    flex-grow: 1;
    margin: 0 12px 12px 12px;
  }
}

@media (max-width: @screen-xs) {
  .cart__header {
    &--saved-cart {
      grid-template-areas: "cartBookmarkLink cartBookmarkLink" "cartHeadline  cartHeadline";
    }
  }

  .item__list{
    .item__list--item {
      .product__checkbox {
        padding: 0;
      }
      .item__acquisition {
        text-align: left;
      }
    }
  }


  .cart-totals {
    width: auto;
    float: none;
  }

  .cart__dynamic-info {
    width: 100%;
  }
}

@media (max-width: @screen-md) {
  .item__list{
    .item__list--item {
      .product__checkbox {
        padding: 0;
      }
      .item__acquisition {
        text-align: left;
      }
    }
  }
  
  .cart-totals {
    width: 100%;
    padding-top: 25px;
    border-top: 1px solid;
    margin-top: 10px;
  }

  .cart__dynamic-info {
    width: 100%;
  }

  .item__list__cart {
    .item__conditions {
      position: inherit;

      &.item__conditions--mobile {
        display: block;
      }

      &.item__conditions--desktop {
        display: none;
      }
    }
  }
}

@media (max-width: 555px) {
  .cart__actions {
    > button,
    a {
      width: 100%;
      display: block;
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 1064px) {

  .cart__links {
    .link {
      &.is-active {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 540px) {
  .cart__empty {
    width: 100%;
    .cart__action {
      .cart-search {
        .input-group {
          width: 250px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .item__list {
    .item__list--item {
      .item__acquisition {
        .acquisition__text {
          max-width: 105px;
        }
      }
    }
  }
}

@media (max-width: 960px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cart__action {
    display: block;

    &.empty__search {
      padding-bottom: 60px;
    }

    .acquisition__wrapper,
    .cart__selection,
    .cart__checkbox {
      display: block;
      width: 100%;
    }

    .cart__import {
      float: left;
      display: block;
    }

    .cart__export {
      float: right;
      display: block;
    }

    .cart-search {
      display: block;
      width: 100%;
      float: left;
    }
  }

  .item__list {
    &.item__list__cart {
      display: block;

      .item__list--item {
        display: block;
      }
    }

    .item__remove {
      float: right;
      margin-right: 30px;
    }
  }
}

@media (max-width: @screen-sm) {
  .cart__buttons {
    display: flex;
    flex-direction: column-reverse;

    > button,
    a {
      margin: 2px 0;
    }
  }
}

@media (max-width: 510px) {
  .item__list__cart {
    .item__list--item {
      .item__acquisition {
        .product__icons.partial {
          .icon__address--text {
            display: block;
          }
        }
      }
    }
  }
}