#saveCart {
  display: block;
}

.modal__content {
  margin: 10px;
  max-width: 700px;

  .modal__content--padding {
    padding: 10px 0;
  }

  .modal__content--fixed {
    max-height: 50vh;
    overflow-x: auto;
  }

  .modal__headline {
    font-weight: 700;
    font-size: 18px;
    margin-right: 30px;
    margin-bottom: 30px;
  }

  .modal__image {
    max-width: 100%;
    margin: 10px 0;
  }

  .modal__link {
    display: block;
  }

  .modal__details {
    margin: 0 0 15px 0;
  }

  .modal__checkbox {
    width: 20px;
    height: 20px;
    display: inline;
    vertical-align: bottom;
  }

  .modal__label {
    font-weight: 700;
  }

  .modal__action {
    white-space: nowrap;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;

    .modal__button {
      display: inline-block;
      padding-right: 10px;
      flex-grow: 1;

      &:first-of-type {
        align-self: flex-start;
      }
      &:last-of-type {
        align-self: flex-end;
      }
    }
  }

  .modal__table {
    display: table;
    width: 100%;

    &.modal__table--half {
      width: 60%;
    }

    .modal__row {
      display: table-row;

      .modal__cell {
        display: table-cell;
        padding: 5px 0;
      }
    }
  }
}

@media (max-width: @screen-md) {
  .modal__content {
    .modal__content--fixed {
      max-height: 80vh;
      overflow-x: auto;
    }
  }
}