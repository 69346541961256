.account-section {
  .item-wrapper {
    .framed {
      .item-label,
      .item-value {
        display: inline-block;
      }

      .item-label {
        font-size: 18px;
        color: #ffffff;
        text-transform: none;
        padding-left: 10px;
        width: 48%;
        float:left;
      }
      .item-value {
        font-weight: 600;
        text-align: right;
        padding-right: 10px;
        width: 50%;
      }

      .item-group.total {
        margin-top: @grid-gutter-width/2;
        border-top: 1px solid @table-head-border-color;

        .item-label,
        .item-value {
          padding-top: @grid-gutter-width;
        }

        .item-label {
          font-weight: 400;
        }
      }
    }
  }

  .account-summary-filter {
    padding-right: 30px;
    padding-left: 30px;

    @media (max-width:@screen-sm) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .section-headline {
    font-size: 20px;
    line-height: 30px;
    padding: @grid-gutter-width 30px;
    border-bottom: 1px solid @border-color-3;
    margin-bottom: 30px;

    @media (max-width:@screen-sm) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .account-summary-detail {
    padding-left: 0;
    padding-right: 0;
  }
  .date-input {
    position: relative;

    > .glyphicon {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -7px;
      cursor: pointer;
    }
  }

  #search {
    margin-top: 8px;
  }

  .download-lnk {
    font-weight: 600;
  }
}
@media (min-width: @screen-md-min) {
  .account-section {
    .item-wrapper {
      .framed {
        border: 1px solid @table-head-border-color;
        padding: 15px @grid-gutter-width 10px;
      }
    }
  }
}
@media (max-width: @screen-sm-max) {
  .account-section {
    .item-wrapper {
      .framed {
        display: block;
        border-top: 3px solid @table-head-border-color;
        margin: @grid-gutter-width -@grid-gutter-width 0;
        padding: 30px @grid-gutter-width/2 0;
      }
    }
  }
}

@media (max-width: @screen-xs-max) {
  .account-section {
    .section-headline,
    .account-summary-table {
      .row();
    }

    .section-headline {
      padding-left: @grid-gutter-width;
    }

    .account-summary-filter {
      padding-left: @grid-gutter-width/2;
      padding-right: @grid-gutter-width/2;
    }
    .accountSummaryUnitBackBtn {
      margin-top: 30px;
    }
    .cancel-panel {
      padding-right: @grid-gutter-width/2;
    }
  }
}