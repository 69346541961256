//
// Pagination (multiple pages)
// --------------------------------------------------

.pagination-bar {
	border-bottom: 1px solid @GRAY-LIGHTER;
	padding: 0;
	margin: 0;


	&.bottom {
		margin-bottom: 18px;
		.pagination__layout,
		.pagination__sort-wrapper{
			display: none;
		}
	}
}

.product__list--wrapper,
.pages_search__list--wrapper{
	.results {
		margin: 10px 0;
	}
}

@media (min-width: @screen-lg) {
	.pagination-bar {
		padding: 0;
	}
}

.pagination-wrap {
	display: -ms-grid;
	display: grid;
	grid-template-columns: 46% 21% 33%;
	-ms-grid-columns: 46% 21% 33%;
	grid-template-areas: "result pagination sorting";
  	margin-top: 15px;
}

.pagination__result {
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-column: 1;
	grid-column-start: 1;
	grid-area: result;

	.result__text {
		margin-top: 7px;
		margin-bottom: 7px;
		display: inline-block;
	}
}

.grid__layout {
	display: inline-block;
	float: left;
	margin-right: 10px;
}
.pagination__filter {
	display: none;
}

.pagination__wrapper {
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-column: 2;
	grid-column-start: 2;
	grid-area: pagination;
}


.pagination__sort-wrapper {
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-column: 3;
	grid-column-start: 3;
	grid-area: sorting;
}

@media (max-width: @screen-sm) {
	.pagination-bar {
		.pagination-wrap {
			.pagination__result {
				text-align: center !important;
			}
		}
	}
}

@media (max-width: @screen-xs) {
	.pagination-bar {
		.pagination-wrap {
			.pagination__result {
				text-align: center !important;
				margin-top: 0 !important;
			}
		}
	}
}

@media (max-width: @screen-md) {
	.pagination-bar {
		.pagination-wrap {
			display: -ms-grid;
			display: grid;
			grid-template-columns: 60% 40%;
			-ms-grid-columns: 60% 40%;
			grid-template-areas: "pagination pagination" "result sorting";

			.pagination {
				margin: 0;
			}
			.pagination__wrapper {
				text-align: center;
				margin: 0;
			}

			.pagination__result {
				text-align: right;
				margin-top: 10px;
				-ms-grid-row: 2;
				grid-row-start: 2;
				-ms-grid-column: 1;
				grid-column-start: 1;

				.result__text {
					margin-right: 50px;
				}
			}

			.pagination__sort {
				margin-top: 5px;
				max-width: 300px;
				height: 30px;
			}
		}
	}

	.pagination__wrapper {
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 3;
	}
	.pagination__sort-wrapper {
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-column: 2;
		grid-column-start:2
	}
}

.pagination {
	margin: 5px 0;

	li {
		border: none;
		padding: 0 5px;
		font-size: 14px;
		line-height: 14px;

		&.active {
			font-weight: 700;
		}

		a {
			border: none;
			padding: 0;
			margin: 0;
			line-height: 14px;
			float: none;

			&:hover {
				cursor: pointer;
			}
		}
	}
}

.pagination__toggle {
	&:hover {
		cursor: pointer;
		color: @SECONDARY
	}

	&.is-active {
		color: @SECONDARY;
	}
}

.pagination__layout {
	display: inline-block;
	font-size: 20px;
	margin-right: 15px;
	padding-top: 2px;

  &:last-of-type{
    margin: 0;
  }
}

.pagination__sort {
	width: 100%;
	padding-left: 5px;
}

.pagination__sort-form {
	position: relative;
}

@media (max-width: @screen-sm) {
	.pagination__wrapper {
		.pagination {
			li {
				a {
					color: @pagination-color;
				}
			}
		}
	}
}

.extended__search {
	margin-left: 0px;
	margin-right: 0px;
	
	.wrapper {
		padding-left: 0px;
		padding-right: 0px;
	}
	
	&.top {
		border-bottom: 1px solid @GRAY-LIGHTER;
		.wrapper {
			margin-top: 8px;
			margin-bottom: 8px;
		}
	}
}

.filter__buttons {

	.facet__filter-icon {
		padding-left: 5px;
	}

	&.filter__buttons--mobile {
		display: none;
	}

	.btn {
		max-width: 250px;
		margin: 0 auto;
		color: @FONT-COLOR;
		background-color: @WHITE;
	}
}

@media (min-width: @screen-sm) and (max-width: @screen-md) {
	.filter__buttons {
		text-align: center;
		.btn {
			display: inline-block;
		}
	}
}

@media (max-width: @screen-sm) {
	.filter__buttons {
		.btn {
			margin: 0 auto 15px auto;
		}
	}
}

@media (max-width: @screen-md) {
	.filter__buttons {
		padding-top: 15px;

		&.filter__buttons--desktop {
			display: none;
		}

		&.filter__buttons--mobile {
			display: block;

			&.bottom {
				display: none;
			}
		}
	}
}

@media (max-width: 700px) {
	.pagination-bar {
		.pagination-wrap {
			grid-template-columns: 100%;
			grid-template-rows: 40px 40px 40px;
			grid-template-areas: "pagination" "result" "sorting";

			.pagination__result {
				-ms-grid-row: 1;
				grid-row-start: 1;
				-ms-grid-column: 1;
				grid-column-start: 1;
				margin: 0;

				.result__text {
					margin-right: 0;
				}
			}

			.pagination__result {
				-ms-grid-row: 1;
				grid-row-start: 1;
				-ms-grid-column: 1;
				grid-column-start: 1;
			}

			.pagination__wrapper {
				-ms-grid-row: 2;
				grid-row-start: 2;
				-ms-grid-column: 1;
				grid-column-start: 1;
				margin-top: 5px;
			}

			.pagination__sort-wrapper {
				-ms-grid-row: 3;
				grid-row-start: 3;
				-ms-grid-column: 1;
				grid-column-start: 1;

				.pagination__sort-form {
					text-align: center;
				}
			}
		}
	}
}

@media (max-width: 355px) {
	.grid__layout {
		margin-top: 12px;
	}
}