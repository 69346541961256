.listing-component {
    width: 100%;
    background-color: @listing-bg-color;
    font-weight: 500;
    .listing-component{
        &__info{
            padding-bottom: 20px;
        }


        &__brand-name{
            display: inline-block;
            width: 50%;
        }
        &__brand-id{
            vertical-align: top;
            width: 49%;
        }
    }
}

@media(min-width: 960px){
    .listing-component {
        .listing-component{
            &__rows{
                column-count: 2;
            }
        }
    }
}

