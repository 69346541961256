.pages_search__list--list {
  margin-top: 20px;
  .pages_search__list--list-item {
    margin-bottom: 20px;
    .searchpage-page {

      .search-results-highlight {
        font-weight: 700;
      }
      .searchpage-page--title {
        .searchpage-page--title-link {
          font-size: 18px;
        }
      }

      .searchpage-page--summary {
      }
    }
  }
}