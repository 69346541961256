.order-administration {

	.orderDetail__container {
		margin-bottom: 5px;
	}
	.orderDetail__header {
		background: @order-consignment-header-bg;
		padding: 5px;
		font-weight: 700;
		display: inline-block;
		width: 100%;
		border: none;
		text-align: left;

		&:after {
			&:extend(.fa);
			content: @fa-var-angle-down;
			font-size: 22px;
			position: relative;
			right: 5px;
			top: 0px;
			float: right;

			-webkit-transition-duration: 0.5s;
			-moz-transition-duration: 0.5s;
			-o-transition-duration: 0.5s;
			transition-duration: 0.5s;

			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			transition-property: transform;
		}

		&.ui-accordion-header-active {
			&:after {
				-webkit-transform: rotate(18deg);
				-moz-transform: rotate(180deg);
				-o-transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}
	}

	.order__headInformation {

		white-space: nowrap;

		.order__text {
			color: @GRAY-DARKER;
			white-space: normal;
		}
	}
	.entry__entryInformation {
		display: none;
	}

	.orderDetail__content {
		padding: 0;
		border: none;

		.tab_wrapper .content_wrapper .accordian_header:after{
			font-size: 18px;
			right: 10px;
			top: 5px;
		}

		.content_wrapper {
			.order__row-six {

				.order__userInformation {
					display: none;
				}
				.order__headInformation {
					display: none;
				}
				.entry__entryInformation {
					display: block;
				}
			}
		}
	}

	.tab_wrapper .content_wrapper .accordian_header {
		background: @order-consignment-header-bg;
		padding: 3px 10px;
		font-weight: 700;
		font-size: 13px;
		border-radius: 3px;
		border: none;
		color: #717375;
		margin-bottom: 5px;
	}


	.order__consignement--tab.tab_content{
		.order__row-one {
			.order__info.orderSystem {
				display: none;
			}
			.order__info.orderExportStatus {
				display: none;
			}
		}
	}

	.order-administration-link {
		display: inline-block;
		margin-top: 10px;
	}

	.order-administration__headline {
		display: inline-block;
		font-size: 18px;
		font-weight: 700;
		text-align: center;
		margin: 10px 0;
		width: 100%;
	}

	.order__image {
		margin: 10px 0;

		.order__image-thumb {
			width: 100%;
			text-align: center;

			.image{
				max-height: 150px;
				max-width: 110px;
			}
		}

		.product__icons.partial {
			float: none;
			text-align: left;
		}
	}
	.order__row {
		font-size: 12px;

		& .order__row-title {
			font-size: 14px;
			font-weight: bold;
			color: black;
			display: inline-block;
			margin-bottom: 10px;
		}

		.order__info {
			.clearfix;

			.order__label{
				width: 50%;
				float: left;
				padding-left: 5px;

				.delivery-status {
					display: inline-block;
					width: 10px;
					height: 10px;
					position: relative;
					border-radius: 50%;
					margin-right: 5px;

					&.red {
						background-color: @delivery-status-red;
					}
					&.yellow {
						background-color: @delivery-status-yellow;
					}
					&.green {
						background-color: @delivery-status-green;
					}
				}

				.carrier__logo {
					max-width:100px;
					display: block;
					margin-bottom: 10px
				}
			}

			.order__text {
				font-weight: 550;
				display: inline-block;
				padding: 0 5px;
			}

			.order__text-no-padding {
				width: 100%;
				padding-left: 0;
				text-overflow: initial;
			}

			.order__headInformation .order__text,
			>.order__text {
				color: @GRAY-DARKER;
				width: 50%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.product--code {
				color: @LINK;
			}

			.invoice__download-pdf {
				font-size: 10px;
				&-mobile {
					display: inline-block;
					cursor: pointer;
					margin-right:14px;
					text-decoration:none;
					&:hover {
						text-decoration:none;
					}
					&:after {
						&:extend(.fa);
						content:@fa-var-file-pdf;
						font-size:25px;
					}
				}
			}

			.is-invoice__downloaded,
			.fake__checkbox {
				&:before {
					position:relative;
					top: 5px;
					font-size: 1.5em;
				}
			}

			.export-state() {
				display: inline-block;
				padding: 2px 0;
				width: 1rem;
				height: 1rem;
				position: relative;
				border-radius: 50%;
				background-color: lighten(@YELLOW, 10%);
				box-shadow: 0 0 4px 1px @PRIMARY;
				transform: translateX(200%);
			}

			.export-state--lightOff(@color) {
				background-color: fade(@color, 15%);
				box-shadow: none;
			}
			.order__export {
				&--EXPORT_FAILED,
				&--NOTEXPORTED,
				&--EXPORTED {
					.export-state();

					&:before,
					&:after {
						.export-state();
						content: '';
						position: absolute;
					}

					&:before {
						background-color: @RED;
						box-shadow: 0 0 4px 1px @RED;
						transform: translateX(-150%);
					}

					&:after {
						background-color: @GREEN;
						box-shadow: 0 0 4px 1px @GREEN;
						transform: translateX(150%);
					}
				}

				&--EXPORT_FAILED {
					.export-state--lightOff(@YELLOW);

					&:after {
						.export-state--lightOff(@GREEN);
					}
				}

				&--NOTEXPORTED {
					&:before {
						.export-state--lightOff(@RED);
					}

					&:after {
						.export-state--lightOff(@GREEN);
					}
				}

				&--EXPORTED {
					.export-state--lightOff(@YELLOW);

					&:before {
						.export-state--lightOff(@RED);
					}
				}
			}

			.export-delivery-icon {
				display: inline-block;
				width: 10px;
				height: 10px;
				position: relative;
				border-radius: 50%;
				margin-left: 5px;
			}
			.export-delivery-icon--color (@color) {
				background-color: fade(@color, 75%);
			}
			.order__delivery {
				&--NOTSHIPPED,
				&--PARTSHIPPED,
				&--SHIPPED {
					.export-delivery-icon();
				}

				&--NOTSHIPPED {
					.export-delivery-icon--color(@RED);
				}
				&--PARTSHIPPED {
					.export-delivery-icon--color(@YELLOW);
				}
				&--SHIPPED {
					.export-delivery-icon--color(@GREEN);
				}
			}
		}
	}

	.order__details-headline {
		display: none;
	}

	.orders {
		>.orders__list {
			.order {
				cursor: pointer;
				
				&:hover {
					background: linear-gradient(125deg, rgba(255,255,255,1) 25%, rgba(22,135,39,0.2) 100%);
				}
			}
			.order__row {
				&.order__row-three,
				&.order__row-four,
				&.order__row-five,
				&.order__row-six,
				&.order__row-address {
					display: none;
				}
			}
		}

		>.invoices-filter__button {
			span {
				display: none;
			}
		}

		&.invoice-listing {
			.alert-dismissable {
				margin: 0;
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

	.orders__list{
		.order__consignment {
			.consignment__info {
				display: block;
				width: 100%;
				margin-bottom: 15px;

				.order {
					border: none;

					.orders__list {
						.consignment__header {
							display: none;
						}

						.list__order {
							width: 100%;
							margin-bottom: 5px;
						}
					}
				}
			}

			.order-administration__tabs {

				.order__consignement--tab {
					width: 100%;
					margin-bottom: 15px;
					padding: 5px 0;
					border: none;

					.consignment__link {
						padding-left: 5px;
						font-size: 12px;
					}
				}
			}
		}
	}

	.paginationBottom {
		>.orders__filter {
			border-bottom: none;
		}
	}

	.orders__filter {
		display: inline-block;
		width: 100%;
		text-align: center;
		padding: 10px 0;
		border-bottom: 2px solid @order-border-color;

		.filter__item {
			margin-bottom: 5px;

			select {
				border: 1px solid @INPUT-BORDER;
			}

			.orders__select {
				height: 32px;
				margin-bottom: 5px;

				select {
					width: 100%;
					height: 32px;
					border: 1px solid @INPUT-BORDER;
				}
			}

			.orders__search__placeholder::placeholder {
				font-size: 80%;
			}
		}
		.orders__pagination {
			.pagination__position {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				.pagination__text {
					width: 100%;
				}

				.pagination__size-options {
					display: flex;
					list-style-type: none;
					padding: 0;
					margin: 0;

					.pagination__link {
						display: inline-block;
						min-width: 40px;
						line-height: 40px;
						text-decoration: underline;
						&.is-active{
							color: @SECONDARY;
						}
					}
				}
			}

			.pagination__wrapper {
				margin: 0;

				ul > li {
					line-height: 40px;
				}
			}

			.pagination__sorting {
				display: flex;
				flex: 1 1 auto;

				.invoices-filter__button {

					span {
						display: none;
					}
				}

				.orders__select {
					width: 100%;

					select {
						height: 32px;
						width: 100%;
					}
				}
			}
		}

		.orders__search-form-mobile-header,
		.invoices__search-form-mobile-header {
			display: flex;
			justify-content: space-between;

			&__text {
				line-height: 4rem;
			}

			&__close {
				height: 4rem;
				font-size: 2rem;
				line-height: 4rem;
				color: @SECONDARY;
			}
		}

		.orders__row {
			.orders__select {
				height: 100%;
				width: 100%;
				margin: 0 auto;
				margin-bottom: 5px;
			}
		}
	}

	.orders__button {
		width: 90%;
		margin: 15px auto;
	}

	.orders__input {
		margin-bottom: 5px;
	}

	.orders__list {

		padding-top: 10px;

		.list__header {
			display: none;
		}
		
		.list__actions-mobile {
			width: 100%;
			display: inline-block;
			border-bottom: 1px solid @order-border-color;
			margin-bottom: 10px;

			.invoice__select-all-checkbox {
				width: 50%;
				float: left;
				color: @GRAY;
				padding: 10px 0 15px 0;
			}
			.invoice__download-all-link {
				float: left;
				padding: 10px 0 15px 0;
			}
		}

		.list__order.js-list-order {
			border-bottom: 1px solid @GRAY-LIGHT;
			padding-bottom: 10px;
		}

		.list__order.invoice {
			border-bottom: 1px solid @GRAY-LIGHT;
			padding-bottom: 10px;
			position: relative;

			.order__row {
				&.invoice__is-downloaded-col {
					padding-top: 10px;

					.invoice__selection-checkbox {
						width: 50%;
						float: left;
						color: @GRAY;
						margin-top: 2px;
					}

					.order__info {
						width: 50%;
						float: left;
						.option__checkbox {
							font-size: 14px;
							.invoice__is-downloaded-icon {
								width: 60%;
								float: left;
								margin: 0 5px 5px 0;
							}
							.invoice__download-icon {
								float: left;
							}
						}
					}
				}

				&.invoice__number,
				&.invoice__net,
				&.invoice__total,
				&.invoice__date {
					font-weight: 700;
					line-height: 2em;
					font-size: 13px;

					div[class^=order__mobile-label-invoice-] {
						width: 50%;
						float: left;
					}

					.order__text {
						padding: 0;
						float: right;
						margin: 0;
					}
				}
				&.invoice__pdf{
					.invoice__download-pdf {
						display: none;
					}
				}
			}
		}
	}

	.order-administration__tabs {
		margin: 0;
		padding: 0;
		background: none;
		border: 0;

		.ui-tabs-nav {
				margin: 0;
				padding: 0;
				background: none;
				border: 0;
				outline: none;

			.ui-tabs-tab {
				width: 100%;
				margin: 0;
				padding: 0;
				outline: none;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				border-radius: 0;
				background: @tab-bg;
				color: @tab-color;
				border-bottom-width: 1px;

				&.ui-tabs-active {
					background: @tab-active-bg;
					color: @tab-active-color;
					outline: none;
				}

				a {
					padding: 15px 15px;
					outline: none;
					font-size: 18px;
					width: 100%;
				}
			}
		}
	}

	.order__icons {
		margin-top: 10px;
		text-align: center;
		justify-content: space-evenly;

		.addtocart__wrapper {
			display: inline-block;
			padding: 0 5px 0 1px;

			&:hover {
				cursor: pointer;
			}

			form {
				display: inline-block;
			}

			.button__add-to-cart {
				font-size: 20px;
				color: @SECONDARY;
				width: 23px;
				display: inline-block;

				&:hover {
					cursor: pointer;
					color: @SECONDARY-BUTTON-HOVER;
				}

				.add2Cart__text {
					display: none;
				}
			}

			.button__add-to-cart-asm {
				.button__add-to-cart;
				background: #ffffff;
				color: #8c8c8c;
				&:hover {
					background: #ffffff;
				}
			}
		}

		.order__icon {
			display: inline-block;
			padding: 0 1px;
			font-size: 20px;
			margin: 0;

			&.is-hidden {
				display: none;
			}
		}
	}

	.invoices-filter__button,
	.advanced-search__button {
		height: 36px;
		display: inline-block;
		border: 1px solid @GRAY-LIGHT;
		padding: 10px;
		margin-right: 15px;
		cursor: pointer;
		float: left;

		span {
			display: none;
		}
		&:after {
			&:extend(.fa);
			content: @fa-var-filter;
		}
		&.is-open {
			&:after {
			&:extend(.fa);
			content: @fa-var-times;
			}
		}
	}

	.invoices__search,
	.orders__search {
		display: none;

		&.is-open {
			display: block;
			position: fixed;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			background-color: rgba(0, 0, 0, .5);
			z-index: 100;
			overflow-y: scroll;

			.invoices__search-form,
			.orders__search-form {
				border: none;
				padding: 10px 30px;
				background: white;
				min-width: 320px;
				width: 80%;
				position: absolute;
				left: 50%;
				top: 120px;
				margin-bottom: 100px;
				transform: translate(-50%, 0);
			}
		}
	}
	.invoices-message__noResults,
	.advanced-search-message__noResults {
		padding: 10px 0;
		display: inline-block;
		margin-right: 20px;
	}

	.order__left {
		margin-bottom: 5px;
		.clearfix;
		.order__image {
			text-align: center;
		}

		.order__code {
			font-weight: 550;
			display: block;
		}

		.product__icons {
			float: left;
			padding-top: 5px;
		}
	}


	.mobileOnly--orderIcons {

		height: 37px;

		.order__icons {
			display: inline-block;
		}
	}

	.orders__list {
		>.order {
			border-bottom: 1px solid @GRAY-LIGHT;
			padding: 5px 0 10px 0;
		}

		>.order:last-child {
			border-bottom: none;
		}
	}

	.order__table {

		tr {

			td {
				width: 50%;
				word-break: break-word;

				&:last-child {
					font-weight: 700;
				}
			}
		}

	}

	.desktopOnly--entryInformation {
		display: none;
	}

	.order__icons--desktop {
		display: none;
	}

	.order__icons--mobile {
		display: block;

		.order__icons {
			text-align: left;
		}
	}
}

.order_cancelation {
	.order__consignment {
		.consignment__info {
			.order {
				border: none;
			}

			&.accordion__header {
				.orders__list {
					.list__order {
						margin-bottom: 20px;

						.order__row {
							width: auto;

							&.order__row-one {
								width: 20%;
							}

							&.order__row--info {
								width: 35%;
							}

							.order__info {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: @screen-sm) {

	.order-administration {


		.orders__list {
			> .order {
				padding: 20px 0;
			}

			.order__consignment .order-administration__tabs .order__consignement--tab .consignment__link {
				padding-left: 40px;
			}
		}

		.order__row .order__info .invoice__download-pdf {
			font-size: 12px;

			i {
				font-size: 1.5em;
			}
		}
	}
}

@media (min-width: @screen-md) {
	.order-administration {

		.order-administration__tabs {

			.ui-tabs-nav {

				.ui-tabs-tab {
					width: auto;
					border-bottom-width: 0px;

					a {
					padding: 10px 15px;
					font-size: 14px;
					}
				}
			}
			.tab_list {
				margin: 0;
				padding: 0;
				background: 0;
				border: 0;
				outline: 0;

				>li {
					font-weight: 400;
					border: 1px solid #c5c5c5;
					border-bottom-width: 0;

					a {
						text-decoration: none;
					}
					&.active {
						border-color: @GRAY-DARK;
						color: @WHITE;
						background: @GRAY-DARK;
						height: 40px;

						span {
							color: white;
						}
					}

					span {
						color: #454545;
					}
				}
			}

			.content_wrapper {
				.order__consignement--tab.tab_content {
					border: 1px solid @GRAY-DARK;
					padding: 1.4em 1.4em;

					.orders__list.order__list--detail.details__overview {
						.order__row {
							width: 30%;

							.order__info {
								width: 100%;
							}

							&.order__row-date,
							&.order__row-number,
							&.order__row-one {
								float: left;
								clear: left;
							}
							&.order__row-four {
								.order__info {
									display: block;
								}
							}
							&.order__row-six {
								width: 50%;
							}

							&.order__row-address {
								display: inline-block;
							}
						}
					}

					.orders__list.order__list--detail{
						&.details__consignment {
							.order__row {
								width: 30%;
								.order__info {
									width: 100%;
								}
							}
						}

						&.details__invoice{
							.list__order{
								.clearfix;
							}

							.order__row {
								width: 33.33333%;
								float: left;
							}

							.order__info{
								display: block;
								width: 100%;
							}
						}
					}

					.consignment__link {
						padding: 0;
					}
				}
			}
		}

		.order__left {
			.product__icons {
				float: none;
				display: block;
			}
			.order__icons {
				display: block;
			}
		}

		.mobileOnly--orderIcons {
			display: none;
		}

		.invoices__search,
		.orders__search {
			display: block;

			.orders__search-form-mobile-header,
			.invoices__search-form-mobile-header{
				display: none;
			}
		}

		.invoices-filter__button,
		.advanced-search__button {
			display: none;
		}

		.order__row .order__info .invoice__download-pdf-mobile {
			display: none;
		}

		.orders__list {

			padding: 0;

			>.order {
				padding: 15px 0;
			}


			&.order__list--detail {
					.list__order .order__row{
						word-break: break-word;
					}
				}
			.list__order {
				&.invoice {
					padding: 0;
					.order__row {
						margin-top: 10px;
					}
					.order__row.invoice__pdf .invoice__download-pdf {
						display: inline-block;
					}
					.order__row.invoice__is-downloaded-col {
						padding: 0;
						.order__info {
							.option__checkbox {
								margin: 0;
								font-size: 13px;
							}
						}
					}
					.order__row.invoice__number,
					.order__row.invoice__date {
						.order__info {
							width: 100%;
						}
						.order__text {
							float: left;
						}
					}
				}

				.order__info {
					margin: 0;
					display: inline-block;
					width: 30%;

					.order__label {
						width: 40%;
						padding: 0;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}

					&.consignmentPackage {
						display: block;
					}
				}

				.order__image {
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					position: relative;
					min-height: 125px;

					.order__image-thumb {
						width: 100%;
						text-align: center;
						display: inline-block;
						margin: 0 auto;
					}
				}
			}
		}

		.orderDetail__header {
			&:after {
				content: "";
			}
		}

		.orderDetail__content {

			.order__consignment .consignment__info .orders__list .list__order .order__row {
				&.order__row-one {
					width: 20%;
				}
				&.order__row--info {
					padding-top: 25px;
					width: 35%;

					.order__info {
						width: 100%;
					}
				}
			}

			.content_wrapper .order__row.order__row-six {
				display: none;
			}
		}

		>.orders__list.order__list--detail.details__overview {
			margin-bottom: 10px;

			.list__order {

				.order__row {
					width: 33%;
					.order__info {
						width: 100%;

						.order__text {
							width: 60%;
						}
					}
					&.order__row-number{
						float: left;
					}
					&.order__row-date{
						float: left;
						clear:  left;
					}
					&.order__row-address {
						display: none;
					}
				}
			}
		}

		.orders__filter {

			.orders__pagination {

				display: flex;
				align-items: center;

				.pagination__position {
					display: block;
					width: 40%
				}

				.pagination__wrapper {
					display: inline-block;
					width: 20%;

					.pagination {
						margin: 0;
					}
				}

				.pagination__sorting {
					width: 40%;
					flex-flow: row-reverse;

					.orders__select {
						margin-right: 13px;
						&.second-column {
							width: 37.5%;
						}
						&.third-column {
							width: 56.2%;
						}
					}
				}
			}
		}

		.paginationBottom {
			.orders__pagination {
				justify-content: center;
			}
		}

		.ui-tabs {
			.ui-tabs-panel {
				border: 1px solid @tab-border-color;
			}
		}

		.orders>.orders__list .order__row .order__item .order__table--right,
		.orders>.orders__list .order__row .order__item .order__table--left,
		.orders>.orders__list .order__row .order__item .order__table--date {
			tr:not(:first-child) {
				display: table-row;
			}
		}

		.desktopOnly--entryInformation {
			display: inline-block;
	}
	}
}
@media (min-width: 960px){

.order-administration {


	.order__icons--desktop {
		display: block;
	}

	.order__icons--mobile {
		display: none;
	}

  .order-administration-link {
    display: inline-block;
    margin-top: 15px;
    font-size: 1em;
  }

  .order-administration__headline {
    .page-headline();
    text-align: center;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
    display: inline-block;
  }

  .order-administration__daily {
    margin-bottom: 15px;

  }

  .orders__search-form-mobile-header,
  .invoices__search-form-mobile-header {
    display: none;
  }

  .orders__select {
    select {
      height: 32px;
      width: 90%;
    }
  }

  .orders__filter {

    .orders__row {
      width: 25%;
      float: left;

      .orders__input {
        width: 90%;
        height: 32px;
        margin-bottom: 5px;
      }

      .order__code {
        margin-bottom: 15px;
        margin-right: 0;

        &:hover {
          cursor: pointer;
        }
      }

      .orders__button {
        width: 90%;
        margin: 15px 0;
      }
    }

    .orders__pagination {
      width: 100%;
		height: 33px;

      .pagination__position{
        float: left;
      }

      .pagination__sorting{
        float: right;
      }
      .pagination__position,
      .pagination__wrapper,
      .pagination__sorting {
        position: relative;
        vertical-align: top;
      }

      .pagination__link {
        &.is-active {
          color: @SECONDARY;
        }
      }

      .pagination__position {
        padding-left: 0;
        text-align: left;

        .pagination__text {
          display: inline-block;
			margin-right: 15px;
			width: auto
        }

        .pagination__size-options {
          display: inline-block;

          li {
            display: inline;
            min-width: 20px;
          }
        }

        a {
          color: @LINK;
          text-decoration: underline;

          &:hover {
            color: @LINK;
            text-decoration: none;
          }
        }
      }

      .pagination__sorting {
        .sorting {
          float: left;
          margin-right: 15px;
          width: 250px;
          height: 32px;
        }
      }
    }
  }

  .orders__list {

    .list__header {
      display: table;
      table-layout: fixed;
      width: 100%;
      border-bottom: 1px solid @order-border-color;
      margin-bottom: 10px;

      .header__column {
        display: inline-block;
        padding: 15px 0px;
        font-weight: 700;
        color: @order-list-header-color;
        width: calc(~"100% / 6");
        overflow-x:hidden;
        &.is-invoice__downloaded-title {
          width: 80px;
        }

        &.invoice__download-title {
          width: 20%;
          padding: 5px 0;
          font-weight: normal;
        }

        &.header__column--one {
          width: 120px;
        }

        &.header__column--five {
          width: 200px;
        }

        &.header__column--small {
          width: 130px;
        }
      }
    }

	.list__actions-mobile {
		display: none;
	}

    .consignment__header {
      background: @order-consignment-header-bg;
      padding: 10px;
      margin-right: 15px;
      font-weight: 700;
    }

    .list__order {
      width: 100%;

      &.is-hidden {
        display: none;
      }

      .order__mobile-label-status,
      .order__mobile-label-invoice-no,
      .order__mobile-label-invoice-date,
      .order__mobile-label-invoice-net,
      .order__mobile-label-invoice-total,
      .order__more {
        display: none;
      }

      .order__row {
        display: inline-block;
        padding: 0;
        vertical-align: top;
        width: calc(~"100%  / 6");

        &.invoice__is-downloaded-col {
          width: 80px;
        }

        &.invoice__pdf {
          text-align:center;
          font-size:1.5em;
        }

        &.order__row-three {
          white-space: nowrap;
        }

        &.order__row-five {
          white-space: nowrap;
          width: 200px;
        }

      }

      .order__row-six {
        padding-right: 0;
        .order__info.always-twoline {

          &:first-child {
            margin-bottom: 46px;
          }
        }
      }

      &.invoice {
        .is-invoice__downloaded{
          font-size:1em;
          cursor:default;
        }

		  .order__info {
			  width: 75%;

			  .order__text {
				  width: 100%;
			  }
		  }

        .fake__checkbox {
          cursor:default;

        }
      }




      .order__info {
        .order__label {

          &.order_label__saporderid {
            font-weight: bold;
          }


        }

        .order__text {
          font-weight: 700;
          padding-left: 15px;
          text-align: left;

          &.order__text--export-status {
            min-width: 65px;
            min-height: 25px;
          }

          .delivery-quantity {
            &.red {
              color: @delivery-status-red;
            }
          }
        }
		  .order__text-no-padding {
			  width: 100%;
			  padding-left: 0;
			  text-overflow: initial;
		  }

        .order__brand,
        .order__product-line,
        .order__info-attribute,
        .order__description,
        .order__code {
          font-weight: 700;
        }

        .order__info-attribute {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .order__description {
          width: 90%;
        }

        .product__info-color::before {
          content: " - ";
        }

        .delivery__container {
          display: inline;
        }
      }

      .order__link {
        font-weight: 300;
        color: @text-color;

        :hover {
          color: @text-color;
          text-decoration: none;
        }
      }
    }
  }
}

.consignment__modal {
  .consignment__actions {
    margin: 25px 0;
    height: 50px;

    .consignment__back {
      width: 250px;
      float: left;
    }

    .consignment__next {
      width: 250px;
      float: right;
      position: relative;

      .spinner__wrapper {
        position: absolute;
        top: 7px;
        right: 10px;
      }
    }
  }

  .order__row-five {
    display: none;
  }
}

.oder_cancelation__success {
  i {
    color: @SECONDARY;
  }
}

.oder_cancelation__failure {
  i {
    color: @RED;
  }
}

.oder_cancelation__success,
.oder_cancelation__failure {
  margin: 50px 0;
  text-align: center;

  i {
    font-size: 35px;
    margin-bottom: 25px;
  }

  a,
  button {
    width: 250px;
    margin: 45px auto;
  }
}

.order-administration {
  .order-administration__tabs {
    .orders {
      .orders__list {
        .list__order {
          border-bottom: 1px solid @GRAY-LIGHT;
        }
      }
    }
  }
}

}
@media(max-width: 650px) {
  .consignment__modal {
    .consignment__actions {
      display: flex;
      flex-direction: column;

      .btn{
        width: 100%;
        float: none;
        margin-top: 10px;
      }

      .btn-tetiary {
        order: 2;
      }

      .btn-default {
        order: 1;
        margin-top: 0;
      }
    }
  }
}