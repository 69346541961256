.cms__button {
  .linkAsButton(@PRIMARY-BUTTON, @PRIMARY-BUTTON-HOVER);

  padding: 10px;
  margin: 0 auto;
  display: block;
  max-width: 400px;
  margin-top: 20px;
  text-align: center;
  @media (max-width: 700px) {
    max-width: 100%;
  }


}

