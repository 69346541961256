body.body--account-vacant-positions-result-positions-visible {
  overflow-y: hidden;
}

.account-vacant-positions {

  account-vacant-positions__sort-btn {
    cursor: pointer;
  }

  .account-vacant-positions__mobile-header {
    i {
      float: right;
      margin: 5px 10px;
    }
  }

  &.account-vacant-positions--searching {
    .account-vacant-positions__search-sections {
      position: relative;
    }

    .account-vacant-positions__loading_overlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .account-vacant-positions__loading_overlay {
    display: none;
    text-align: center;

    &:after{
      &:extend(.fas);
      content: @fa-var-spinner;
      &:extend(.fa-spin);
      line-height: 300px !important;
      font-size: 40px;
    }
  }

  .account-vacant-positions__headline {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0;
    width: 100%;
  }

  .account-vacant-positions__search-section-wrapper {
    &:extend(.clearfix all);
  }

  .account-vacant-positions__search-mobile-btn {
    float: right;
    margin: 20px 0 0 0;
    width: auto;

    i {
      margin-left: 5px;
    }
  }

  .account-vacant-positions__search-section-mobile-close-btn{
    display: none;
    position: absolute;
    top: 16px;
    right: 30px;
    border: 0;
    background-color: transparent;
    font-size: 20px;
    padding: 0;
  }

  .account-vacant-positions__search-section-mobile-title {
    display: none;
    margin: 10px 0;
  }

  .account-vacant-positions__search-section-underlay{
    display: none;
  }

  .account-vacant-positions__search-section,
  .account-vacant-positions__info-section {
    border-bottom: 2px solid @order-border-color;
  }

  .account-vacant-positions__search-content {
    display: none;
  }

  .account-vacant-positions__search-status-header,
  .account-vacant-positions__search-account-header,
  .account-vacant-positions__search-company-header,
  .account-vacant-positions__search-business-header,
  .account-vacant-positions__search-date-header
  {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .account-vacant-positions__search-status {
    .form-group {
      margin-bottom: 0;

      >.checkbox{
        margin: 0;
      }
    }
  }

  .account-vacant-positions__search-status-input {
    margin-right: 2px;
  }

  .account-vacant-positions__search-date-input {
    width: 100%;
    display: block;
  }

  .account-vacant-positions__search-date-input-hidden {
    display: none;
  }

  .account-vacant-positions__info-section {
    margin: 30px 0;
  }

  .account-vacant-positions__result-positions-cell.account-vacant-positions__result-positions-cell--show{
    .account-vacant-positions__result-positions-underlay {
      display: block;
    }

    .account-vacant-positions__result-positions {
      display: block;
    }
  }

  .account-vacant-positions__sum-of-payments-row {
    font-weight: bold;
  }

  .account-vacant-positions__result-positions {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 1180px;
    max-height: 90vh;
    padding: 35px 30px 10px 30px;
    background: #fff;
    z-index: 15001;
    overflow-y: auto;
  }

  .account-vacant-positions__result-positions-close-btn {
    position: absolute;
    top: 10px;
    right: 30px;
    border: 0;
    background-color: transparent;
    font-size: 20px
  }

  .account-vacant-positions__search-account,
  .account-vacant-positions__search-company {
    .checkboxes {
      > span {
        >label {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .checkbox-toggle-all {
      >input[type=checkbox] {
        position: absolute;
      }
      >label {
        margin-left: 20px;
        margin-bottom: 0px;
        cursor: pointer;
        text-transform: lowercase !important;
      }
    }
  }

  .account-vacant-positions__search-business {
    margin-bottom: 15px;
  }

  .account-vacant-positions__result-positions-table {
    width: 97%;
    margin-left: 3%;
  }

  .account-vacant-positions__result--open{
    .account-vacant-positions__result-toggle-btn{
      color: @accountVacantPositions;
    }
  }

  .account-vacant-positions__result-toggle-btn{
    padding: 0 5px;
    border: 0;
    background-color: transparent;
  }

  .account-vacant-positions__result-positions-underlay{
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 15000;
  }

  .account-vacant-positions__results-no-result-msg {
    margin: 10px 0;
  }
}

@media (max-width: 959px) {
  body.body--account-vacant-positions-mobile-filter-visible{
    overflow-y: hidden;
  }

  .account-vacant-positions {
    &.account-vacant-positions--mobile-filter-visible {
      .account-vacant-positions__search-content {
        display: block;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90vw;
        max-width: 320px;
        max-height: 90vh;
        padding: 10px 30px;
        background: #fff;
        z-index: 15001;
        overflow-y: auto;

        input[type=text],
        select{
          width: 100%;
          margin-bottom: 10px;
        }
      }

      .account-vacant-positions__search-section-mobile-title {
        display: block;
      }

      .account-vacant-positions__search-section-mobile-close-btn {
        display: block;
      }

      .account-vacant-positions__search-section-underlay{
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0,0,0,.5);
        z-index: 15000;
      }

      .account-vacant-positions__search-btn{
        margin-top: 20px;
      }
    }
  }

  .account-vacant-positions__search-content-block {
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }
  }

}

@media (min-width: 960px) {

  .account-vacant-positions {

    .account-vacant-positions__mobile-header i {
      display: none;
    }

    .account-vacant-positions__headline {
      color: #717375;
      font-size: 24px;
      line-height: 35px;
      font-weight: 700;
      text-align: center;
      padding: 0;
      margin: 20px 0;
      width: 100%;
      display: inline-block;
    }

    .account-vacant-positions__search-mobile-btn {
      display: none;
    }

    .account-vacant-positions__search-sections {
      padding: 1em 1.4em;
      border: 1px solid @tab-border-color;
    }

    .account-vacant-positions__search-section {
      padding: 10px 0;
    }

    .account-vacant-positions__info-section {
      padding: 0 0 20px 0;
    }


    .account-vacant-positions__search-content {
      display: flex;
      align-items: flex-start;
    }

    .account-vacant-positions__search-content-block {
      padding: 0 6px;
      flex: 1 0 auto;

      &.first {
        width: 25%;
      }

      &.second {
        width: 25%;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      .checkboxes {
        max-height: 200px;
        overflow-y: auto;
      }
    }

    .account-vacant-positions__search-date-input {
      margin-bottom: 5px;
      max-width: 300px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .account-vacant-positions__search-date-select {
      border: 1px solid #CCCCCC;
    }

    .account-vacant-positions__search-btn {
      margin-top: 10px;
    }
  }
}

@media print {
  .account-vacant-positions{
    .account-vacant-positions__info-section {
      display: none;
    }
  }

  body.body--account-vacant-positions-result-positions-visible {
    overflow-y: auto;

    .account-vacant-positions__result-toggle-btn{
      display: none;
    }

    .account-vacant-positions__result> table {
      width: 100%;
      > thead > tr,
      > tbody >tr >td:not(.account-vacant-positions__result-positions-cell--show) {
        display: none;
      }
    }

    .account-vacant-positions {
      .account-vacant-positions__result-positions {
        position: static;
        top: auto;
        left: auto;
        transform: none;
        padding: 0;
        width: 100%;
        max-width: none;
        max-height: none;
      }

      .account-vacant-positions__result-positions-cell.account-vacant-positions__result-positions-cell--show .account-vacant-positions__result-positions-underlay {
        display: none;
      }
    }
  }
}
