.checkout {
  .checkout__header {
    display: grid;
    grid-template-areas: "progression" "checkoutHeadline" "checkoutAction";
  }

  .checkout__progression {
    grid-area: progression;
  }

  .checkout__headline {
    grid-area: checkoutHeadline;
    color: @FONT-COLOR;
    font-size: 24px;
    line-height: 35px;
    font-weight: 700;
    text-align: center;
    padding: 0;
    margin-bottom: 54px;
    margin-top: 20px;
  }

  .checkout__action {
    margin-bottom: 30px;
    grid-area: checkoutAction;
    padding-bottom: 15px;
    border-bottom: 3px solid @mobile-cart-border-color;
  }

  .wli__address {
    display: table;
    width: 650px;
    padding: 0 20px;
    font-weight: 700;
  }

  .address__row{
    display: block;
    width: 100%;
    .first__name{
      display: inline-block;
      float: left;
      width: 40%;
    }
    .last__name{
      display: inline-block;
      float: left;
      width: 60%;
      padding-left: 4px;
    }
    .address__street{
      display: inline-block;
      float: left;
      width: 70%;
    }
    .address__nr{
      display: inline-block;
      float: left;
      width: 30%;
      padding-left: 4px;
    }
    .address__zip{
      display: inline-block;
      float: left;
      width: 30%;
    }
    .address__town{
      display: inline-block;
      float: left;
      width: 70%;
      padding-left: 4px;
    }
    .address__stiege{
      display: inline-block;
      float: left;
      width: 50%;
    }
    .address__top{
      display: inline-block;
      float: left;
      width: 50%;
      padding-left: 4px;
    }
    .contact{
      display: inline-block;
      float: left;
      width: 30%;
      font-size: 14px;
      margin-top: 10px;
    }
    .lbl{
      display: inline-block;
      text-transform: uppercase;
      float: left;
      width: 45%;
      font-size: 16px;
      margin-top: 10px;
      color: #168727;
    }
  }

  .contact_lbl{
      text-transform: uppercase;
      font-size: 14px;
  }

  .checkout__reference-container{
    width: 100%;
    padding: 0 20px;
    .checkout__reference {
      margin-left: 0;
      width: 100%;
      height: 32px;
      margin-bottom: 20px;
      padding: 5px 12px 6px 10px;
    }
  }


  .checkout__row {
    padding: 30px 0;

    .checkout__title {
      display: block;
      padding-bottom: 10px;
      font-size: 15px;
      font-weight: 700;
    }

    .checkout__block--closed {
      display: none;
    }

    .checkout__edit-delivery {
      display: inline-block;

      margin-left: 60px;
      font-size: 20px;


      &.checkout__edit-delivery--open {
        &:after {
          &:extend(.fas);
          content: @fa-var-pencil-alt;
        }
      }

      &.checkout__edit-delivery--close {
        display: none;
        &:after {
          &:extend(.fas);
          content: @fa-var-times-circle;
        }
      }

      &:hover {
        cursor: pointer;
      }


    }

    .checkout__datepicker-icon {
      display: inline-block;

      &:after {
        &:extend(.fas);
        content: @fa-var-pencil-alt;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .checkout_datepicker{
      display: none;
      margin-top: 10px;
    }

    .checkout__block {
      position: relative;

      &.checkout__block--delivery {

        .delivery__town,
        .delivery__postal {
          display: inline-block;
        }

        .address__input {
          width: 200px;
          display: block;
          margin-bottom: 2px;
          padding-left: 4px;
          border: none;
          border-bottom: 1px solid @addres-border-color;

          &.address__input--description {
            display: none;
          }
        }

        .btn  {
          float: left;
          width: 215px;
          margin-top: 10px;
          margin-right: 5px;
        }
      }

      span {
        display: block;
      }

      .checkout__date {
        margin-top: 10px;
      }
    }

    .checkout__select {
      height: 32px;
    }
  }

  .place-order-form {
    &.place-order-form--different-address{
      .place-order-form__diff-delivery-text {
        display: block;
      }
    }

    label {
      text-transform: inherit !important;
    }

    input {
      display: none;
    }

    .place-order-form__diff-delivery-text {
      display: none;
    }

    .place-order-form__diff-delivery-text-header {
      font-size: 18px;
      font-weight: 700;
      margin-top: 25px;
    }

    .place-order-form__diff-delivery-text-content {
      margin-top: 28px;
      font-size: 14px;
    }
  }

  .checkout__subtotal {

    width: 50%;
    display: inline-block;

    .subtotal__block {
      padding: 5px 0;
    }

    .subtotal__block--big {
      display: inline-block;
      width: 100%;
      padding: 10px 0;
    }

    .subtotal__text {
      font-weight: 700;
    }

    .subtotal__price {
      float: right;
    }

    .subtotal__price--big,
    .subtotal__text--big {
      font-size: 18px;
      font-weight: bold;
      
      .total-before-discount {
        font-size: 14px;
        text-align: right;
      }
    }
  }

  .checkout__subtotal,
  .checkout__disclaimer {
    width: 100%;
  }

  .checkout__disclaimer {
    padding-right: 0;
    font-size: 12px;
    display: inline-block;
    float: left;
  }

  .checkout__bottom {
    white-space: nowrap;
    margin-top: 25px;
    min-height: 150px;

    .checkout__buttons,
    .checkout__policy {
      margin-left: 0;
      width: 100%;
      margin-bottom: 25px;
    }

    .checkout__policy {
        display: block;
        margin-left: 50%;
        width: 50%;
        white-space: normal;
        float: right;
     }
    .checkout__buttons {
      display: block;
      margin-top: 25px;
      white-space:normal;
      vertical-align: top;
      text-align: right;
      float: right;

      .checkoutSummaryButton {
        position: relative;
        margin-right: 4px;
        padding: 10px 35px;

        .spinner__wrapper  {
          position: absolute;
          top: 7px;
          right: 0;
          padding-right: 5px;
        }
      }

      button, a {
        margin-bottom: 5px;
        width: 100%;
      }
    }
  }
}

.checkout__list {

  .item__list__cart {

    .item__list--header {
      .item__product {
        width: 52.95%;
      }
    }

    .item__list--item {
      padding-top: 10px;
      display: grid;
      grid-template-areas: "productInfo productInfo productInfo"
      "productAcquisition productQuantitiy productPrice"
      "productCheckbox productCheckbox productTotal"
      "productDelivery productDelivery productDelivery"
      "productConditions productConditions productConditions";

      .product__image {
        width: auto;
      }

      .item__stock{
        text-align: left;
      }

      .item__price,
      .item__stock,
      .item__quantity,
      .item__total {
        padding-top: 6px;
      }

      .item__acquisition {
        padding-top: 0;
        .product__icons.partial {
          .icon__address--text {
            display: block;
          }
        }
        .acquisition__label{
          padding-top: 5px;
        }
      }

      .item__product {
        padding-left:0;
        width: 100%;

        .item__delivery {
          text-align: left;
        }

        .item__name-link {
          margin-top: 6px;

          .product__info-color,
          .product__info-name {
            font-size: 1.1em;
            white-space: normal;
          }
        }

        .product__name {
          margin-top: 4px;
        }

        .item__conditions--desktop {
          position: static;
          clear: left;
          padding-left: 36px;
        }
      }
    }

    .item__conditions {
      grid-area: productConditions;
      position: inherit;
      margin-top: 15px;
      bottom: 32px;
      width: 100%;
      text-align: left;
      left: 100%;
    }
  }

}

.checkout-success {
  text-align: center;

  .row {
    margin-bottom: 30px;
  }

  .kef_address {
    display: table;
    text-align: left;
    width: 100%;
    padding: 0 20px;
    font-weight: normal;
    border: 2px solid #0b4514;
    .address__row {
      display: block;
      width: 100%;
      .description{
        display: inline-block;
        float: left;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #0b4514;
      }
      .lbl {
        display: inline-block;
        float: left;
        width: 40%;
      }
      .value {
        display: list-item;
        overflow-x: clip;
        float: left;
        width: 60%;
        padding-left: 4px;
      }
    }
  }

  .kef_order {
    display: table;
    text-align: left;
    width: 100%;
    padding: 0 20px;
    font-weight: normal;
    border: 2px solid #0b4514;
    .order__row {
      display: flex;
      width: 100%;

      .description {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #0b4514;
        flex: 3;
      }

      .quantity {
        text-align: right;
        width: 10%;
      }

      .nr {
        text-align: right;
        width: 20%;
      }

      .product {
        text-align: left;
        width: 60%;
        padding-left: 24px;
      }
    }
  }

  .checkout-success-text-left {
    text-align: left;

    .checkout-success__link {
      margin-top: 15px;
      text-decoration: underline;
    }
  }

  .checkout-success__icon {
    margin-top: 30px;
    &:after {
      &:extend(.fas);
      content: @fa-var-check-circle;
      font-size: 45px;
      color: @checkout-success-icon-color;
    }
  }

  .checkout-success__order-code {
    font-weight: 700;
  }

  .confirmation__text {
    max-width: 600px;
    margin: 30px auto;

    p {
        margin: 10px 0 10px;
    }
  }

  .confirmation__buton {
    max-width: 270px;
    margin: 0 auto;
  }

  .checkout-success__print-button {
    max-width: 270px;
    margin: 0 auto;

    &.checkout-success__print-button--loading  {
      .checkout-success__print-button-spinner  {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .checkout-success__print-button-spinner {
    display: none;
  }
}

@media (min-width: 510px) {
  .item__list__cart {
    .item__list--item {
      .item__acquisition {
        .product__icons.partial {
          .icon__address--text {
            display: inline-block;
          }
        }
      }
    }
  }
}

@media (min-width: 649px) {
  .checkout .checkout__bottom  {
    .checkout__buttons {
      button, a {
        width: auto;
        display: inline-block;
      }
    }
  }
}

@media (min-width: 961px) {
  .checkout {
    .checkout__bottom {
      .checkout__buttons {
        width: 63%;
        margin-left: 39%;
      }
    }
  }
}

@media (min-width: 992px) {
  .checkout {
    .checkout__action {
      padding-bottom: 0;
      border-bottom: none;
    }

    .checkout__reference-container {
      .checkout__reference {
        width: 300px;
        margin-left: 20px;
      }
    }

    .checkout__subtotal,
    .checkout__disclaimer {
      width: 50%;
    }


    .checkout__disclaimer {
      padding-right: 50px;
    }

    .checkout__list {
      .item__list--item{
        display: table;

        .icon__address--text,
        .item__stock{
          text-align: center;
        }
        .item__product {
          padding-left: 32px;
          padding-top: 0;
          width: 53%;

          .item__name-link {
            margin-top: 0;

            .product__info-color,
            .product__info-name {
              font-size: 1.28em;
            }
          }
        }
      }

      .item__conditions {
        position: static;
        clear: left;
        margin-left: 0;
        padding-left: 36px;
        padding-right: 20%;
      }
    }

    .item__acquisition {
      .product__icons.partial {
        .icon__address--text {
          display: inline-block;
        }
      }

      .acquisition__label{
        padding-top: 0;
      }
    }
  }
}

@media (min-width: 1181px) {
  .checkout {
    .checkout__bottom {
      margin-top: 0;

      .checkout__policy {
        display: inline-block;
        margin-left: 0;
      }

      .checkout__buttons {
        width: 51%;
        margin-left: 0;
        display: inline-block;
        margin-top: 0;
      }
    }
  }
}


