.overlay-widget{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #000;
	background: rgba(0, 0, 0, 0.7);
	overflow: hidden;
	z-index: 9999;
	
	&.overlay-widget--visible{
		display: block;
	}
	
	&.overlay-widget--closeable{
		cursor: pointer;
		
		.overlay-widget__overlay{
			cursor: default;
		}
		
		.overlay-widget__closer{
			display: block;
		}
	}
	
	&.overlay-widget--loading{
		.overlay-widget__overlay{
			overflow: hidden;
			min-height: 300px;
		}
		
		.overlay-widget__loading {
			display: block;
		}
		
		.overlay-widget__titlebar,
		.overlay-widget__content{
			opacity: 0.5;
		}
	}

	.overlay-widget__overlay{
		position: absolute;
		max-width: 90vw;
		max-height: 90vh;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		background: #fff;
		overflow: auto;
	}

	.overlay-widget__loading {
		position: absolute;
		display: none;
		left: 0;
		bottom: 0;
		top: 0;
		right: 0;
		text-align: center;
		overflow: hidden;
		
		&:before{
			&:extend(.fas);
			content: @fa-var-spinner;
			&:extend(.fa-spin);
			line-height: 300px !important;
			font-size: 40px;
		}
	}
	
	.overlay-widget__titlebar{
		font-size: 18px;
		font-weight: 700;
		padding: 0 15px;
	}
	
	.overlay-widget__closer{
		display: none;
		position: absolute;
		right: 15px;
		top: 0px;
		color: #004b07;
		padding: 0 5px;
		font-size: 25px;
		z-index: 25;
		
		&:hover {
			color: #356c37;
			cursor: pointer;
		}
	}
	.overlay-widget__titlebar,
	.overlay-widget__closer{
		line-height: 55px;
	}
	
	.overlay-widget__content{
		padding: 15px;
	}
}