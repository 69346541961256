.quick-view-popup{
	.product-image{
		float: left;
		width: 33%;
		a{
			display: block;
		}
		img{
			width: 100%;
			height: auto;
		}
	}

	.product-details{
		margin-left: 33%;
		padding-left: (@grid-gutter-width);

		.name{
			font-size: inherit;
			font-weight: bold;
		}

		.rating{
			&:after{
				clear:none;
			}
		}

		.price{
			font-size: inherit;
			font-weight: bold;
		}
	}


	.addtocart-component{
		clear: both;
		padding-top: (@grid-gutter-width);
	}
}