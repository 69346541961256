.legal__modal {
  display: block;

  .content {
    margin: 20px 0;
  }

  .btn {
    margin: 20px auto 0 auto;
    max-width: 500px;
  }
}