.search__empty {
  margin: 50px auto;
  width: 50%;
  .empty__icon-wrapper {
    text-align: center;



    .empty__icon {
      border-radius: 50%;
      background: @empty-cart-icon-bg;
      height: 100px;
      width: 100px;
      margin: 0 auto;
      position: relative;

      i {
        position: absolute;
        top: 25%;
        left: 26%;
        font-size: 45px;
        color: @BLACK;
      }
    }
  }

  .empty__headline {
    margin-top: 30px;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
  }

  .empty__list {
    ul {
      // padding: 0 110px;

      li {
        margin: 15px 0;
      }
    }
  }

  .empty__back {
    margin: 30px auto 0;
  }
}

@media screen and  ( max-width: @screen-sm) {
  .search__empty {
    margin: 50px 0 0 0;
    width: 100%;
    .empty {

      &__icon {
          margin: 0 auto;
      }

      &__icon-wrapper {
        left: 0;

      }

      &__headline {

        font-size: 19px;

      }

      &__list {
        ul {

        }
      }

      &__back {
        margin: 0 auto;
      }
    }
  }
}