.yCmsContentSlot >.sidebar-navigation-component:first-child,
.yCmsContentSlot >.yComponentWrapper:first-child >.sidebar-navigation-component{
	padding-top: 0;
}

.sidebar-navigation-component{
	padding-top: 18px;
	font-size: 14px;
	.menu-list {
		padding: 0;
		list-style-type: none;
	}

	&.sidebar-navigation-component__mobile_opened{
		.sidebar-navigation-component__list.depth-root{
			display: block;
		}
	}
}

.sidebar-navigation-component--type-fair{
    .sidebar-navigation-component__item{
        background-color: @GRAY;
        border-bottom: 1px solid @WHITE;
        
        &.depth-0{
            .sidebarnavigationcomponententry__link:hover{
                background-color:@GRAY-DARKER;
            }

            >.sidebar-navigation-component__list{
                border-top-color: @FONT-COLOR;
            }
        }

        &.depth-1{
            background-color: @GRAY-LIGHTER;
            border-bottom-color: @FONT-COLOR;
            color: @GRAY-LIGHT;

            .sidebarnavigationcomponententry__link{
                color: @GRAY-DARKER;
                &:hover{
                    background-color: @GRAY-LIGHT;
                    color: @GRAY-DARKER;
                }
            }

            >.sidebar-navigation-component__list{
                border-top-color: @FONT-COLOR;
            }
        }

        &.depth-2{
            background-color: @WHITE;
            border-bottom-color: @FONT-COLOR;

            >.sidebarnavigationcomponententry__link{
                &,
                &:active,
                &:hover{
                    color: @FONT-COLOR;
                }
            }
        }
    }
}

.sidebar-navigation-component__mobile-btn{
	position: relative;
	display: block;
	border-top: 1px solid @navigation-bar-border;
	border-right: 0;
	border-bottom: 1px solid @navigation-bar-border;
	border-left: 0;
	width: 100%;
	background-color: transparent;
	color: inherit;
	cursor: pointer;
	text-align: left;
	padding: 5px 25px 5px 10px;
	
	&:after{
		&:extend(.fa);
		content: @fa-var-bars;
		position: absolute;
		right: 10px;
	}
}
.sidebar-navigation-component__list{
	list-style-type: none;
	padding: 0;
	
	display: none;
}

.sidebar-navigation-component__list{
	border-top: 1px solid @WHITE;
}

.sidebar-navigation-component__item{
	background-color: @SECONDARY-GREEN;
	border-bottom: 1px solid @WHITE;
	
	&.has-children{
		&.sidebar-navigation-component__opened{
			>.sidebarnavigationcomponententry__link > .sidebarnavigationcomponententry__children_toggle{
				&:after{
					content: @fa-var-chevron-up;
				}
			}

			>.sidebar-navigation-component__list{
				display: block;
			}
		}
	
		>.sidebarnavigationcomponententry__link{
			position: relative;
			padding-right: 30px;
			
			> .sidebarnavigationcomponententry__children_toggle{
				display: block;
			}
		}
	}
	
	&:last-child{
		border-bottom: 0;
	}
	
	&.depth-0{
		
		>.sidebar-navigation-component__list{
			border-top-color: @FONT-COLOR;
		}
	}
	
	&.depth-1{
		background-color: @GRAY-LIGHTER;
		border-bottom-color: @FONT-COLOR;
		
		>.sidebarnavigationcomponententry__link{
			&,
			&:focus,
			&:active,
			&:hover{
				color: @LINK;
			}
		}
		
		>.sidebar-navigation-component__list{
			border-top-color: @FONT-COLOR;
		}
	}
	
	&.depth-2{
		background-color: @WHITE;
		border-bottom-color: @FONT-COLOR;
		
		>.sidebarnavigationcomponententry__link{
			&,
			&:active,
			&:hover{
				color: @LINK;
			}
		}
	}
}

.sidebarnavigationcomponententry__link{
	display: block;
	padding: 5px 10px;
	word-break: break-word;
	
	&,
	&:active,
	&:hover{
		color: @WHITE;
		text-decoration: none;
	}
}

.sidebarnavigationcomponententry__children_toggle{
	display: none;
	position: absolute;
	border: 0;
	background-color: transparent;
	right: 5px;
	top: 50%;
	line-height: 30px;
	margin-top: -15px;
	color: inherit;
	&:after{
		&:extend(.fa);
		color: inherit;
		content: @fa-var-chevron-down;
	}
}

.sidebar-navigation-component__item.active,
.sidebar-navigation-component__item.current,
.sidebarnavigationcomponententry__link:hover,
.sidebarnavigationcomponententry__link:active{
	background-color: darken(@SECONDARY-GREEN, 7%);
}

.sidebar-navigation-component__item.depth-1.active,
.sidebar-navigation-component__item.depth-1.current,
.sidebar-navigation-component__item.depth-1 >.sidebarnavigationcomponententry__link:hover,
.sidebar-navigation-component__item.depth-1 >.sidebarnavigationcomponententry__link:active{
	background-color: darken(@GRAY-LIGHTER, 17%);
}

.sidebar-navigation-component__item.depth-2.active,
.sidebar-navigation-component__item.depth-2.current,
.sidebar-navigation-component__item.depth-2 >.sidebarnavigationcomponententry__link:hover,
.sidebar-navigation-component__item.depth-2 >.sidebarnavigationcomponententry__link:active{
	background-color: darken(@WHITE, 10%);
}

@media(min-width: 961px){
	.sidebar-navigation-component__mobile-bar{
		display: none;
	}
	
	.sidebar-navigation-component__list.depth-root{
		display: block;
	}
}